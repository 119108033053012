.column.main {

	@if ($ts-loadmore-btn-view == 'circle') {
		.ias-trigger,
		// .ias-noneleft,
		.ias-spinner {
			color: $anchor-color;
			font-size: rem-calc(22);
			@include header-font-family($ts-loadmore-btn-font-family);
			margin: 1rem auto;
			position: relative;
			line-height: 1;
			text-align: center;
			text-transform: uppercase;
			width: $ts-loadmore-btn-width;
			height: $ts-loadmore-btn-width;
			clear: both;

			span,
			a,
			em {
				display: table-cell;
				text-decoration: none;
				height: $ts-loadmore-btn-height;
				line-height: 1.1;
				vertical-align: middle;
				width: $ts-loadmore-btn-width;
			}

			em {
				font-size: rem-calc(13);
				padding-left: .25rem;
				padding-right: .25rem;
				line-height: 1.2;
			}

			&:before {
				border: $ts-loadmore-btn-border-size $ts-loadmore-btn-border-style $anchor-color;
				border-radius: 100%;
				content: "";
				height: rem-calc(120);
				left: 0;
				position: absolute;
				width: $ts-loadmore-btn-width;
			}

			&:hover {
				color: rgba(black,0.9);
				cursor: pointer;

				&:before {
					border-color: rgba(black,0.9);
				}
			}
		}// .ias-spinner

		.ias-noneleft {
			margin-top: 2rem;
		}

		.ias-spinner {
			&:after {
				$ts-loadmore-btn-spinner-origin-x : ($ts-loadmore-btn-width - $ts-loadmore-btn-spinner-size)/2;

				display: block;
				content: "";
				position: absolute;
				margin: 0 auto;
				width: $ts-loadmore-btn-spinner-size;
				height: $ts-loadmore-btn-spinner-size;
				background-color: white;
				-webkit-animation: spin-loading-more 1s linear infinite;
				animation: spin-loading-more 1s linear infinite;
				-webkit-transform-origin: -$ts-loadmore-btn-spinner-origin-x $ts-loadmore-btn-spinner-size/2;
				-ms-transform-origin: -$ts-loadmore-btn-spinner-origin-x $ts-loadmore-btn-spinner-size/2;
				transform-origin: -$ts-loadmore-btn-spinner-origin-x $ts-loadmore-btn-spinner-size/2;
				border-radius: 50%;
				top:  $ts-loadmore-btn-spinner-origin-x;
				left: $ts-loadmore-btn-width - $ts-loadmore-btn-spinner-size/2;
			}

			img {
				display: none !important;
			}
		}// .ias-spinner
	} @else {
		.ias-trigger,
		.ias-spinner {
			@include button-extended;
			@include button-style($primary-color);
			@include button-hollow;
			@include button-hollow-style-extend(lighten($primary-color, 45), $primary-color);
			display: block;
			max-width: rem-calc(250);
			margin-left: auto;
			margin-right: auto;
			clear: both;

			a {
				text-decoration: none;
				color: $primary-color;
			}
		}

		.ias-spinner {
			span,
			a,
			em {
				&:before {
					display: inline-block;
					vertical-align: middle;
					content: "";
					width: 18px;
					height: 18px;
					margin-right: 2px;
					margin-top: -5px;
					background: $ts-loader-icon-background-image no-repeat 50%;
					background-size: 100% auto;

					@if $ts-loading-fallback {
						.ie11 &,
						.no-smil & {
							background-image: $ts-loader-icon-background-image-fallback;
						}
					}
				}

				span,
				a,
				em {
					&:before {
						display: none;
					}
				}
			}

			img {
				display: none !important;
			}
		}// .ias-spinner
	}
}// ajax-spinner
