// 00.9 Product Photo settings
$ts-product-photo-overlay                                    : true;
$ts-product-photo-overlay-opacity                            : 1;
$ts-product-photo-overlay-color                              : rgba(0, 0, 0, 0.03);

// 00.10 Stock label
$ts-stock-label-font-size                                    : rem-calc(14);
$ts-stock-label-font-family                                  : $body-font-family;
$ts-stock-label-font-weight                                  : 400;
$ts-stock-label-font-color                                   : $gray-dark;

// 01. Footer settings
$ts-footer-view                                              : full-width;
$ts-footer-bg-has-image                                      : false;
$ts-footer-bg-color                                          : $secondary-color;
$ts-footer-font-color                                        : rgba($white, .7);
$ts-footer-indents-vt                                        : ( small : .75, medium: 1, xxlarge : 1.5);
$ts-footer-block-indents-vt                                  : ( small : .5, medium: .5, xxlarge : .5);
$ts-footer-bottom-block-indents-vt                           : ( small : 0, medium: 1.5, xxlarge : .5, xxlarge-top : .5);
$ts-footer-font-size                                         : 15px;

$ts-footer-bottom-border                                     : false;

$ts-footer-header-font-size                                  : rem-calc(22);
$ts-footer-header-font-color                                 : $white;
$ts-footer-header-font-weight                                : 500;
$ts-footer-header-text-transform                             : none;
$ts-footer-header-margin-bottom								 : 1.25rem;

$ts-footer-border-width 									 : 0;

// 01.1 Footer Navigation settings
$ts-footer-nav-anchor-color                                  : rgba($white, .7);
$ts-footer-nav-anchor-color-hover                            : $white;
$ts-footer-nav-font-weight                                   : 500;
$ts-footer-nav-anchor-padding                                : (0 0 1.25rem);


// 01.2 Footer Cookies settings
$ts-footer-cookies-color                                     : rgba($white, .5);
$ts-footer-cookies-anchor-color                              : rgba($white, .5);
$ts-footer-cookies-font-size                                 : rem-calc(12);
$ts-footer-cookies-anchor-text-decoration                    : none;
$ts-footer-cookies-anchor-text-decoration-hover              : none;

// 01.5 Footer Social settings
$ts-footer-social-icon-networks                              : (linkedin);

// 01.7 Footer Copyright settings
$ts-footer-copyright-color                                   : rgba($black, .5s);
$ts-footer-copyright-font-size                               : rem-calc(12);

// 02. Header settings
$ts-headbar-top-border                                       : false;
$ts-headbar-height                                           : ( small : rem-calc(70),
                                                                 large : rem-calc(150),
                                                                 xlarge : rem-calc(150),
                                                                 xxlarge : rem-calc(150),
                                                                 xxxlarge : rem-calc(150) );
$ts-headbar-bg-color                                         : $white;

$ts-headbar-logo-width                                       : 170px;

// 02.0 Sticky Header
$ts-sticky-headbar-height                                    : rem-calc(60);
$ts-sticky-headbar-box-shadow                                : none;
$ts-sticky-scroll-headbar-box-shadow                         : none;

// 02.2 Header Search
$ts-header-search-font-family                                : $body-font-family;
$ts-header-search-font-size                                  : rem-calc(16);
$ts-header-search-font-weight                                : 500;
$ts-header-search-input-background-color                     : $box-color;
$ts-header-search-input-height                               : rem-calc(45);
$ts-header-search-input-color                                : rgba($body-font-color, .6);
$ts-header-search-input-text-transform                       : none;

// 02.3 Header Cart
// 02.3.1 Header Cart Button
$ts-header-cart-dropdown-showcart-type                       : image-only;
$ts-header-cart-dropdown-showcart-icon                       : 'icon-cart';
$ts-header-cart-dropdown-showcart-icon-active                : $ts-header-cart-dropdown-showcart-icon;
$ts-header-cart-dropdown-showcart-icon-hover                 : $ts-header-cart-dropdown-showcart-icon;
$ts-header-cart-dropdown-showcart-icon-width                 : rem-calc(45);
$ts-header-cart-dropdown-showcart-icon-height                : $ts-header-cart-dropdown-showcart-icon-width;
$ts-header-cart-dropdown-showcart-icon-spacing               : 0;

$ts-header-cart-button-count-font-family                     : $header-font-family;
$ts-header-cart-button-count-size                            : rem-calc(20);
$ts-header-cart-button-count-font-size                       : rem-calc(14);
$ts-header-cart-button-count-font-weight                     : $header-font-weight;
$ts-header-cart-button-count-box-shadow                      : none;
$ts-header-cart-button-count-color                           : $white;
$ts-header-cart-button-count-bg-color                        : $primary-color;
$ts-header-cart-dropdown-showcart-icon-image-sticky-bottom   : unset;

// 02.4 Top Bar Navigation
$ts-headbar-main-menu-font-family                            : $header-font-family;
$ts-headbar-main-menu-font-size                              : ( medium : rem-calc(14), xlarge : rem-calc(16), xxlarge : rem-calc(17) );
$ts-headbar-main-menu-font-weight                            : $header-font-weight;
$ts-headbar-main-menu-text-transform                         : none;

$ts-primary-nav-dd                                           : false;

// 02.4.2 Dropdown
$ts-header-menu-dropdown-box-shadow                          : 0 5px 15px rgba($black, .1);
$ts-header-menu-dropdown-link-font-size                      : rem-calc(16);
$ts-header-menu-dropdown-link-font-weight                    : 500;
$ts-header-menu-dropdown-link-color-hover                    : $black;

$ts-header-menu-dropdown-subcategory-link-font-size-title    : rem-calc(22);
$ts-header-menu-dropdown-subcategory-link-font-weight-title  : 500;
$ts-header-menu-dropdown-subcategory-link-font-family-title  : $header-font-family;
$ts-header-menu-dropdown-subcategory-link-transform-title    : none;
$ts-header-menu-dropdown-subcategory-link-outer-indent-title : 1rem;
$ts-header-menu-dropdown-subcategory-link-font-size          : rem-calc(15);
$ts-header-menu-dropdown-subcategory-link-font-weight        : $header-font-weight;
$ts-header-menu-dropdown-subcategory-link-font-color         : $body-font-color;
$ts-header-menu-dropdown-subcategory-link-font-color-hover   : $body-font-color;
$ts-header-menu-dropdown-subcategory-link-inner-indent       : .5rem 0 .5rem 0;
$ts-header-menu-dropdown-subcategory-has-arrow               : false;

// 03.1 Off-canvas Mobile Navigation
$ts-offcanvas-spacing-top                                    : rem-calc(20);
$ts-offcanvas-spacing-side                                   : rem-calc(25);

// 03.1.1 Offcanvas Main Title
$ts-offcanvas-title-font-size                                : rem-calc(22);
$ts-offcanvas-title-font-family                              : $header-font-family;
$ts-offcanvas-title-font-weight                              : $header-font-weight;
$ts-offcanvas-title-bottom-indent                            : rem-calc(25);

// 03.1.2 Offcanvas Main Categories Menu
$ts-offcanvas-main-menu-item-border                          : 1px solid rgba($black, .15);
$ts-offcanvas-main-menu-item-border-first                    : true;
$ts-offcanvas-main-menu-item-border-last                     : true;
$ts-offcanvas-main-menu-item-font-family                     : $body-font-family;
$ts-offcanvas-main-menu-item-font-weight                     : 400;
$ts-offcanvas-main-menu-item-link-color                      : $gray-dark;
$ts-offcanvas-main-menu-item-link-spacing                    : rem-calc(17) $ts-offcanvas-spacing-side;

// 03.1.3 Offcanvas Submenu of Main Categories Menu
$ts-offcanvas-main-submenu-item-font-size                    : rem-calc(15);
$ts-offcanvas-main-submenu-item-font-weight                  : 400;
$ts-offcanvas-main-submenu-item-link-spacing                 : rem-calc(8 10 8 20);
$ts-offcanvas-main-submenu-item-link-underline               : none;

$ts-offcanvas-main-submenu-item-bold-font-family             : $body-font-family;
$ts-offcanvas-main-submenu-item-bold-font-size               : rem-calc(15);

// 03.1.4 Offcanvas Secondary Menu
$ts-offcanvas-secondary-menu-item-font-size                  : rem-calc(15);
$ts-offcanvas-secondary-menu-item-font-weight                : 400;
$ts-offcanvas-secondary-menu-item-link-color                 : $gray-dark;
$ts-offcanvas-secondary-menu-title-font-size                 : rem-calc(18);
$ts-offcanvas-secondary-menu-title-font-weight               : 400;
$ts-offcanvas-secondary-menu-title-link-color                : $gray-dark;

$ts-offcanvas-close-color                                    : $black;


// 03.2 Mobile search
// 03.2.1 Mobile search bar
$ts-mobile-search-bar-box-shadow                             : 0 5px 5px rgba($black, 0.04);
$ts-mobile-search-bar-bg-color                               : $white;
$ts-mobile-search-field-border-color                         : transparent;

// 04. Magento Modal
// 04.1 Magento Modal Basic
$ts-modal-popup-width-mobile                                 : 100%;

// 05. Category Page
// 05.0 Category Title
$ts-category-main-title-align                                : left;
$ts-category-main-title-border                               : false;
$ts-sidebar-main-filter-border-width                         : 0;

// 05.1 Category Sidebar
$ts-sidebar-main-filter-title-font-size                      : rem-calc(26);
$ts-sidebar-main-filter-title-font-weight                    : 500;
$ts-sidebar-main-filter-title-indent                         : 1.25rem;
$ts-sidebar-main-filter-font-family                          : $body-font-family;

// 05.2 Category Mobile Filter
$ts-sidebar-mobile-category-font-size                        : rem-calc(17);

// 05.3 Category Sorting Toolbar
$ts-category-sorting-toolbar-label-width                     : 0;
$ts-category-sorting-toolbar-select-width                    : 100%;
$ts-category-sorting-toolbar-select-spacing                  : 0.25rem;
$ts-category-sorting-toolbar-height                          : rem-calc(45);
$ts-category-sorting-toolbar-font-size                       : rem-calc(16);

// // 05.6 Category Price slider
$ts-sidebar-main-price-slider-point-width 					 : 30px;
$ts-sidebar-main-price-slider-point-height 					 : 30px;
$ts-sidebar-main-price-slider-margin 						 : rem-calc(0 17 15 0);
$ts-sidebar-main-price-slider-point-bg 						 : #54565B;
$ts-sidebar-main-price-slider-line-width                     : 7px;
$ts-sidebar-main-price-slider-line-color 					 : #54565B;
$ts-sidebar-main-price-slider-font-size 					 : rem-calc(16);
$ts-sidebar-main-price-slider-font-weight                    : 500;
$ts-sidebar-main-price-slider-color                          : $black;

// 05.7 Pagination
$ts-pagination-item-color                                    : #4F4F4F;

// 06. Product List Item
$ts-product-item-mobile-two-inline                           : true;

$ts-product-item-inner-indent                                : ( small : rem-calc(0) rem-calc(0) rem-calc(0),
                                                                 large : rem-calc(0) rem-calc(0) rem-calc(0),
                                                                 xlarge : rem-calc(0) rem-calc(0) rem-calc(0),
																 xxlarge : rem-calc(0) rem-calc(0) rem-calc(0));

$ts-product-item-hide-btn                                    : false;

$ts-product-item-hover-shadow                                : 0 0 10px rgba($black, 0.1);

$ts-product-item-product-name-font-size                      : ( small : rem-calc(15), large : rem-calc(16), xlarge : rem-calc(16), xxlarge : rem-calc(16));
$ts-product-item-product-name-font-family                    : $body-font-family;
$ts-product-item-product-name-font-weight                    : ( small : 400, large : 400, xlarge : 400);
$ts-product-item-product-name-line-height                    : ( small : 1.27, large : 1.27, xlarge : 1.24, xxlarge : 1.24);
$ts-product-item-product-name-text-transform                 : none;
$ts-product-item-product-name-margin                         : 0 0 5px 0;

$ts-product-item-product-price-font-size                     : ( small : rem-calc(18), xlarge : rem-calc(18), xxlarge : rem-calc(21));
$ts-product-item-product-price-font-weight                   : 500;
$ts-product-item-product-price-font-color                    : $primary-color;

$ts-product-item-product-price-special-color                 : $primary-color;

$ts-product-item-product-price-old-font-size                 : rem-calc(14);

$ts-product-item-nameprice-align                             : block;
$ts-product-item-article-number-color                        : rgba($gray-dark, 0.6);
$ts-product-list-link-color: #d8282f;

// 07. Product Detail
// 07.1 Product Detail Fotorama Gallery
$ts-fotorama-mobile-dots-size                                : 12px;
$ts-fotorama-mobile-dots-spacing                             : 0 7px;
$ts-fotorama-mobile-dots-bg                                  : rgba($black, .2);
$ts-fotorama-mobile-dots-bg-active                           : rgba($black, .6);

// 07.2 Product Detail Top Panel
$ts-product-detail-title-font-size                           : ( small : rem-calc(28), large : rem-calc(32), xxlarge : rem-calc(32));
$ts-product-detail-title-font-weight                         : 500;
$ts-product-detail-title-text-transform                      : none;
$ts-product-detail-title-text-align                          : left;
$ts-product-detail-title-bottom-indent                       : 0;

$ts-product-detail-price-font-size                           : rem-calc(28);
$ts-product-detail-price-font-weight                         : 500;
$ts-product-detail-price-font-weight-mobile                  : 500;
$ts-product-detail-price-color                               : $primary-color;
$ts-product-detail-price-line-height                         : 1.25;
$ts-product-detail-price-line-height-mobile                  : 1.25;

$ts-product-detail-old-price-font-size                       : rem-calc(16);
$ts-product-detail-old-price-font-weight                     : 500;

// 07.3 Product Detail Info Right Sidebar
$ts-product-info-sidebar-indent                              : (small: 0 0, large: 0 2rem, xlarge: 0 2.1rem 2.1rem, xxlarge: 0 2.1rem 2.1rem);
$ts-product-info-sidebar-bottom-indent                       : (small: 2rem, large: 2.5rem, xlarge: 0, xxlarge: 0);

// 07.6 Product Detail AddtoCart Button
$ts-product-addtocart-qty__show                              : true;
$ts-product-addtocart-btn-font-size                          : rem-calc(15);
$ts-product-addtocart-btn-font-weight                        : 500;
$ts-product-addtocart-text-transform                         : none;
$ts-product-addtocart-btn-padding                            : 1rem 3rem;

// 07.10 Product stock
$ts-product-detail-stock__font-size                          : $ts-stock-label-font-size;
$ts-product-detail-stock__line-height                        : $ts-product-detail-price-font-size;

// 08. Customer Section
$ts-customer-sidebar-box-shadow                              : none;
$ts-customer-sidebar-font-size                               : rem-calc(15);
$ts-customer-sidebar-active-bg                               : $primary-color;
$ts-customer-sidebar-item-spacing-top                        : rem-calc(10);
$ts-customer-sidebar-item-active-color                       : $white;
$ts-customer-sidebar-item-border-color                       : rgba($black, .2);

// 08.1 Login Page
$ts-login-section-box-shadow                                 : none;

// Password Strong
$ts-password-font-size                                       : rem-calc(13);

// 11. Checkout Cart
$ts-checkout-sidebar-shadow                                  : none;

$ts-cart-summary-title-font-size                             : rem-calc(32);
$ts-cart-summary-title-font-weight                           : $header-font-weight;
$ts-cart-summary-title-font-family                           : $header-font-family;
$ts-cart-summary-title-spacing                               : .65rem 0 .55rem;
$ts-cart-methods-title-font-size                             : rem-calc(22);

$ts-cart-summary-table-font-size                             : rem-calc(15);

$ts-cart-summary-table-line-height                           : 1.3;
$ts-cart-summary-table-font-weight                           : $header-font-weight;

$ts-cart-summary-table-total-font-size                       : rem-calc(15);
$ts-cart-summary-table-grandtotal-font-size                  : rem-calc(22);
$ts-cart-summary-table-total-font-family                     : $body-font-family;
$ts-cart-summary-table-total-amount-font-size                : rem-calc(15);
$ts-cart-summary-table-grandtotal-amount-font-size           : rem-calc(22);
$ts-cart-summary-table-total-text-transform                  : none;
$ts-cart-summary-table-grandtotal-text-transform             : none;

$ts-cart-table-border-color                                  : rgba($black, .15);
$ts-cart-table-cell-indent                                   : 0 1rem;

$ts-cart-table-product-price-font-size                       : rem-calc(21);

$ts-cart-table-product-title-font-size                       : ( small    : rem-calc(16),
                                                                 large   : rem-calc(16));
$ts-cart-table-product-title-font-family                     : $body-font-family;
$ts-cart-table-product-title-text-transform                  : none;

// 11.1 Checkout
$ts-checkout-header-border-bottom                            : none;
$ts-checkout-box-shadow                                      : none;
$ts-checkout-back-to-cart-font-size                          : rem-calc(17);
$ts-checkout-back-to-cart-icon-border                        : 2px solid $black;
$ts-checkout-total-products-font-size                        : rem-calc(17);
$ts-checkout-products-number-padding                         : 0 1.25rem;
