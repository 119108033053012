//  Search Icon
//  _____________________________________________
.action.search {
	display: inline-block;
	vertical-align: middle;
	width: $ts-header-search-input-icon-width;
	height: $ts-header-search-input-height;
	background: url("#{$ts-path-to-images}/#{$ts-header-search-input-icon}.#{$ts-header-search-input-icon-type}") no-repeat;
	background-position: $ts-header-search-input-icon-btn-bg-position;
	background-size: $ts-header-search-input-icon-width auto;
	opacity: $ts-header-search-input-icon-opacity;
	transition: opacity .5s ease;

	&:hover,
	&:active {
		opacity: 1;
	}

	> span {
		@include at;
	}

	+ .action.showcart {
		margin-left: .75rem;
	}
}

//  Map Icon
//  _____________________________________________
.icn-map {
	display: inline-block;
	vertical-align: middle;
	width: rem-calc(18);
	height: rem-calc(27);
	background: url("#{$ts-path-to-images}/icn-map.svg") no-repeat;
	background-size: cover;
}

//  Account Icon
//  _____________________________________________
.mobile-account-icon {
	display: inline-block;
	vertical-align: middle;
	width: rem-calc(22);
	height: rem-calc(18);
	background: url("#{$ts-path-to-images}/icon-user.svg") no-repeat 50%;
	font-size: 0;
	background-size: 100% auto;
}

//  Question Icon
//  _____________________________________________
.icon-q {
	display: inline-block;
	vertical-align: middle;
	width: rem-calc(17);
	height: rem-calc(17);
	font-size: rem-calc(11);
	line-height: rem-calc(14);
	font-weight: 700;
	border:1px solid rgba($black,.25);
	font-style: normal;
	@include header-font-family($button-font-family);
	text-align: center;
	border-radius: 50%;
	background: $white;
}

//  Clear Cross Icon
//  _____________________________________________
.clear-cross {
    display: inline-block;
    position: absolute;
    width: 1rem;
    height: 1rem;
    background: url("#{$ts-path-to-images}/icon-deselect.svg") no-repeat 50%;
    background-size: 100% auto;
    cursor: pointer;
}// .clear-cross

//  Basket Cart Icon with Items Qty
//  _____________________________________________
.action.showcart {
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	position: relative;
	transition: background-color .3s ease;

	.is-stuck,
	.tab-bar & {
		margin-top: 0;
	}

	@if ($ts-header-cart-dropdown-showcart-type == image-only) {
		width: $ts-header-cart-dropdown-showcart-icon-width;
		height: $ts-header-cart-dropdown-showcart-icon-height;
		background: url("#{$ts-path-to-images}/#{$ts-header-cart-dropdown-showcart-icon}.#{$ts-header-cart-dropdown-showcart-icon-type}") 0 50% no-repeat;
		background-size: 100% auto;

		&:hover,
		&:active,
		&.has-item {
			background-image: url("#{$ts-path-to-images}/#{$ts-header-cart-dropdown-showcart-icon-hover}.#{$ts-header-cart-dropdown-showcart-icon-type}");
		}

		&.has-item {
			background-image: url("#{$ts-path-to-images}/#{$ts-header-cart-dropdown-showcart-icon-active}.#{$ts-header-cart-dropdown-showcart-icon-type}");
		}

		> .text {
			@include at;
		}
		.counter {
			bottom: -15%;
			right: -15%;
		}

	} @elseif ($ts-header-cart-dropdown-showcart-type == image-text) {
		text-decoration: none;
		color: $ts-header-cart-dropdown-showcart-text-color;
		text-align: center;
		font-family: $ts-header-cart-dropdown-showcart-text-family;
		font-weight: $ts-header-cart-dropdown-showcart-text-weight;
		height: $ts-header-cart-dropdown-showcart-text-line-height;
		line-height: $ts-header-cart-dropdown-showcart-text-line-height;
		text-transform: $ts-header-cart-dropdown-showcart-text-transform;
		padding-left: ($ts-header-cart-dropdown-showcart-text-indent-cart-left + $ts-header-cart-dropdown-showcart-icon-width + $ts-header-cart-dropdown-showcart-text-indent-cart-right);
		padding-right: $ts-header-cart-dropdown-showcart-text-padding-right;
		background:$ts-header-cart-dropdown-showcart-bg-color url("#{$ts-path-to-images}/#{$ts-header-cart-dropdown-showcart-icon}.#{$ts-header-cart-dropdown-showcart-icon-type}") $ts-header-cart-dropdown-showcart-text-indent-cart-left 50% no-repeat;
		background-size: $ts-header-cart-dropdown-showcart-icon-width auto;

		@if $ts-header-cart-dropdown-showcart-has-border {
			border: $ts-header-cart-dropdown-showcart-border;
		}

		.text {
			color: $ts-header-cart-dropdown-showcart-text-color;
			font-size: $ts-header-cart-dropdown-showcart-text-size;
		}

		.counter {
			bottom: ($ts-header-cart-dropdown-showcart-text-line-height/4) - ($ts-header-cart-button-count-size/2);
			right: calc(100% - #{$ts-header-cart-dropdown-showcart-text-indent-cart-left + $ts-header-cart-dropdown-showcart-icon-width + ($ts-header-cart-button-count-size/2)});
		}

		.tab-bar &,
		.is-stuck &,
		body.is-sticky & {
			display: inline-block;
			padding: 0;
			width: $ts-header-cart-dropdown-showcart-icon-width;
			height: $ts-header-cart-dropdown-showcart-icon-height;
			background-position: 0 0;
			background-color: $ts-header-cart-dropdown-showcart-stuck-bg-color;
			@if $ts-header-cart-dropdown-showcart-has-border {
				border:0;
			}

			> .text {
				@include at;
			}
			.counter {
				bottom: -15%;
				right: -15%;
				left: auto;
			}
			&:hover,
			&:active,
			&.has-item {
				background-color: transparent;
				background-image: url("#{$ts-path-to-images}/#{$ts-header-cart-dropdown-showcart-icon}.#{$ts-header-cart-dropdown-showcart-icon-type}");
				background-color: $ts-header-cart-dropdown-showcart-stuck-bg-color-hover;
			}
		}

		@include breakpoint(large) {
			margin-left: .75rem;

			&:hover,
			&:active {
				color: $ts-header-cart-dropdown-showcart-text-hover-color;
				background-color: $ts-header-cart-dropdown-showcart-bg-color-hover;
				background-image: url("#{$ts-path-to-images}/#{$ts-header-cart-dropdown-showcart-icon-hover}.#{$ts-header-cart-dropdown-showcart-icon-type}");

				@if $ts-header-cart-dropdown-showcart-has-border {
					border: $ts-header-cart-dropdown-showcart-border-hover;
				}

				.text {
					color: $ts-header-cart-dropdown-showcart-text-hover-color;
				}
			}


			.is-stuck & {
				margin-left: .75rem;
			}
		}

	}

	.counter {
		position: absolute;
		min-width: $ts-header-cart-button-count-size;
		height: $ts-header-cart-button-count-size;
		line-height: $ts-header-cart-button-count-size;
		display: inline-block;
		padding-left: 0.25rem;
		padding-right: 0.25rem;
		color: $ts-header-cart-button-count-color;
		background-color: $ts-header-cart-button-count-bg-color;
		box-shadow: $ts-header-cart-button-count-box-shadow;
		@if ( $ts-header-cart-button-count-shape == rounded ) {
			border-radius: $ts-header-cart-button-count-size;
		}
		@if ( $ts-header-cart-button-count-shape == round ) {
			border-radius: 50%;
		}
		font-size: $ts-header-cart-button-count-font-size;
		@if ( $ts-header-cart-button-count-font-family != default ) {
			font-family: $ts-header-cart-button-count-font-family;
		}
		text-align: center;
		font-weight: $ts-header-cart-button-count-font-weight;

		body.is-sticky & {
			bottom: $ts-header-cart-dropdown-showcart-icon-image-sticky-bottom;
		}

		.counter-label {
			@include at;
		}

		&.empty {
			display: none;
		}
	}// .counter
}

//  Delete Action
//  _____________________________________________
.action.action-delete {
	display: inline-block;
	vertical-align: middle;
	// @include disable-mouse-outline;
	width: $ts-delete-icon-width;
	height: $ts-delete-icon-height;
	opacity: .8;
	background: url("#{$ts-path-to-images}/#{$ts-delete-icon}.#{$ts-delete-icon-type}") no-repeat;
	background-size: contain;
	cursor: pointer;
	span {
		@include at;
	}

	&:hover,
	&:active {
		opacity: 1;
	}
}

//  Add To Wishlist Icon with Popup Label
//  _____________________________________________
.action.towishlist,
.action.wishlist-remove-product {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	// @include disable-mouse-outline;
	width: $ts-addtowishlist-icon-width;
	height: $ts-addtowishlist-icon-height;
	background: url("#{$ts-path-to-images}/#{$ts-addtowishlist-icon}.#{$ts-addtowishlist-icon-type}") no-repeat;
	background-size: 100% auto;
	cursor: pointer !important;

	&:hover,
	&:active,
	&.has-item {
		background-image: url("#{$ts-path-to-images}/#{$ts-addtowishlist-icon-active}.#{$ts-addtowishlist-icon-type}");

		span {
			opacity: 1;
		}
	}

	&.active {
		background-image: url("#{$ts-path-to-images}/#{$ts-addtowishlist-multiple-icon-active}.#{$ts-addtowishlist-multiple-icon-type}");
	}

	> span {
		opacity: 0;
		position: absolute;
		color: $white;
		top: 100%;
		right: 0;
		margin-top: rem-calc(5);
		padding: rem-calc(5 10);
		font-size: $ts-addtowishlist-text-size;
		font-family: $ts-addtowishlist-text-family;
		font-weight: $ts-addtowishlist-text-weight;
		line-height: 1.2;
		white-space: nowrap;
		background: $black;
		transition: opacity .3s ease;

		@include breakpoint(large) {
			right: auto;
			left: 50%;
			transform: translateX(-50%);
		}

		&:after {
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 6px 6px 6px;
			border-color: transparent transparent $black transparent;
			position: absolute;
			bottom: 100%;
			right: ($ts-addtowishlist-icon-width / 2);
			transform: translateX(50%);

			@include breakpoint(large) {
				right: auto;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
}// .action.towishlist

.action.wishlist-remove-product {
	background-image: url("#{$ts-path-to-images}/#{$ts-addtowishlist-remove-icon}.#{$ts-addtowishlist-icon-type}");
	&:hover,
	&:active,
	&.has-item {
		background-image: url("#{$ts-path-to-images}/#{$ts-addtowishlist-remove-icon}.#{$ts-addtowishlist-icon-type}");
	}
}
