//
// Variables
// _____________________________________________
$mp-ul-list__max-width: 255px;

//
// Common
// _____________________________________________

.catalog-product-view {
    .product-info-main {
        .hide-link {
            display: none;
        }
        .mp-ul-list {
            display: flex;
            flex-direction: column;

            button,
            .button {
                max-width: $mp-ul-list__max-width;
            }

            .action {
                padding-bottom: 10px;
            }
        }
    }
}
