// /**
//  * Copyright © Convert, Inc. All rights reserved.
//  *
//  */

//
//  Default appearance styles
//  _____________________________________________

div[data-content-type="text"] {
    word-wrap: break-word;
}

.cms-content-important {
	@if $pb-text--intro__background-color {
    	background-color: $pb-text--intro__background-color;
	}
	@if $pb-text--intro__color {
	    color: $pb-text--intro__color;
	}
    font-family: $pb-text--intro__font-family;
    font-size: $pb-text--intro__font-size ;
    font-style: normal;
    font-weight: $pb-text--intro__font-weight;
    line-height: $pb-text--intro__line-height;
    @if $pb-text--intro__margin {
    	margin: $pb-text--intro__margin;
	}
    @if $pb-text--intro__padding {
    	padding: $pb-text--intro__padding;
    }
}