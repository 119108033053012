// /**
//  * Copyright © Convert, Inc. All rights reserved.
//  *
//  */

//
//  Default appearance styles
//  _____________________________________________

[data-content-type="map"] {
    box-sizing: border-box;
    height: $pb-map__height;
}