// /**
//  * Copyright © Convert, Inc. All rights reserved.
//  *
//  */

//
//  Collage right appearance styles
//  _____________________________________________

[data-appearance="collage-right"] {
    .pagebuilder-slide-wrapper {
        .pagebuilder-overlay {
            margin-left: auto;
        }
    }
}