@if ($ts-headbar-style == logo-left) {

	.page-header {
		@if $ts-headbar-top-border {
			height: $ts-headbar-top-border-height-mobile + map-get($ts-headbar-height, small);
			padding-top: $ts-headbar-top-border-height-mobile;
		} @else {
			height: map-get($ts-headbar-height, small);
		}

		@include breakpoint(large) {
			@if $ts-headbar-top-border {
				height: $ts-headbar-top-border-height + map-get($ts-headbar-height, large);
				padding-top: $ts-headbar-top-border-height;
			} @else {
				height: map-get($ts-headbar-height, large);
			}
		}
		@include breakpoint(xlarge) {
			@if $ts-headbar-top-border {
				height: $ts-headbar-top-border-height + map-get($ts-headbar-height, xlarge);
				padding-top: $ts-headbar-top-border-height;
			} @else {
				height: map-get($ts-headbar-height, xlarge);
			}
		}
		@include breakpoint(xxlarge) {
			@if $ts-headbar-top-border {
				height: $ts-headbar-top-border-height + map-get($ts-headbar-height, xxlarge);
				padding-top: $ts-headbar-top-border-height;
			} @else {
				height: map-get($ts-headbar-height, xxlarge);
			}
		}
		@include breakpoint(xxxlarge) {
			@if $ts-headbar-top-border {
				height: $ts-headbar-top-border-height + map-get($ts-headbar-height, xxxlarge);
				padding-top: $ts-headbar-top-border-height;
			} @else {
				height: map-get($ts-headbar-height, xxxlarge);
			}
		}
	}// .page-header

	.header {
		@include grid-row;

		.header-row {
			@include clearfix;

			@include breakpoint(xxxlarge) {
				.top-nav-search-sticky-row {
					@include grid-row;
					@include grid-row-nest;
				}
			}

			// ----- STUCK ----- //
			.is-sticky & {

				.nav-sections a,
				a.action.showcart {
					color: $ts-headbar-main-menu-anchor-color;
				}

				.navigation {
					padding-right: 0;
					> .ui-menu {
						> li {
							> a {
								@each $tmp-mquery, $tmp-properties in $menu-item-padding-hz {
									@include breakpoint($tmp-mquery) {
										@each $tmp-property, $tmp-value in $tmp-properties {
											#{$tmp-property} : #{$tmp-value};
										} // @each
									} // @media
								} // @each
								@each $tmp-mquery, $tmp-properties in $menu-item-padding-vt {
									@include breakpoint($tmp-mquery) {
										@each $tmp-property, $tmp-value in $tmp-properties {
											#{$tmp-property} : #{$tmp-value};
										} // @each
									} // @media
								} // @each
							}
						}
					}

					@include breakpoint(large) {
						margin-top: .05rem;
					}

					@include breakpoint(large only) {
						margin-left: 0;
					}
					@include breakpoint(xxlarge) {
						margin-left: 0;
					}
				}// .navigation

				.sticky-header-row {
					@include breakpoint(xxxlarge) {
						@include grid-row;
						position: relative;
					}
				}// .sticky-header-row

				.logo {
					&:before {
						display: none;
					}

					img {
						max-width: 100%;
						margin-top: 0;
						width: auto;
					}

					@include breakpoint(large) {
						width: 80px + (map-get($grid-column-gutter, medium)/4);
						padding: 0 (map-get($grid-column-gutter, medium)/4) 0 0;
						position: absolute;
						top: $ts-sticky-headbar-height-spacing;
						height: ($ts-sticky-headbar-height - ($ts-sticky-headbar-height-spacing * 2));
						line-height: ($ts-sticky-headbar-height - ($ts-sticky-headbar-height-spacing * 2));
						left: map-get($ts-global-space-indent, large) + (map-get($grid-column-gutter, medium)/2);

						@include breakpoint(xxxlarge) {
							left: (map-get($grid-column-gutter, medium)/2);
							top: 50%;
							transform: translateY(-50%);
						}

						img {
							max-height: 100%;
						}
					}
				}// .logo

				.nav-search-wrapper {
					height: ($ts-sticky-headbar-height - ($ts-sticky-headbar-height-spacing * 2));
					padding-top: 0;

					.panel.wrapper {
						@include at;
					}

					@include breakpoint(large) {
						@include grid-col-size(12);
						padding-left: (map-get($grid-column-gutter, medium)*.75) + 80px;

						.ie11 & {
							padding-left: (map-get($grid-column-gutter, medium)*.75) + 100px;
						}
					}

				}// .nav-search-wrapper

				.top-nav-search-sticky-row {

					@include breakpoint(xxlarge) {
						@include get-gutter(-.5, 'margin-right');
						margin-left: 0;
					}

					.nav-sections {
						@include grid-col;
						@include grid-col-size(9);

						@media screen and (min-width: (map-get($breakpoints, large) + 1)) {
							@include grid-col-size(8);
						}

						@include breakpoint(medium) {
							@include grid-column-collapse;
						}

						@include breakpoint(xlarge) {
							@include grid-col-size(8.5);
						}

						@include breakpoint(xxlarge) {
							@include grid-col-size(8);
							@include get-gutter(0, 'padding-right');
							@include get-gutter(0, 'padding-left');
						}

						@include breakpoint(xxxlarge) {
							@include grid-col-size(8);
							// @include get-gutter(.5, 'padding-right');
							// @include get-gutter(.5, 'padding-left');
						}
					}

					.top-search-grid-wrapper {
						@include grid-col-size(3);
						margin-top: 0;

						@media screen and (min-width: map-get($breakpoints, large) + 1) {
							.top-search-wrapper {
								margin-bottom: 0;
								display: table;
								width: 100%;

								.block-search,
								.minicart-wrapper {
									margin: 0;
									white-space: nowrap;
									display: table-cell;
									vertical-align: middle;
								}

								.minicart-wrapper {
									width: 1%;
									height: 100%;
									padding: $ts-header-cart-dropdown-showcart-icon-spacing;
									margin-top: 0;
									padding-left: 0;
								}
							} // .top-search-wrapper
						}

						@include breakpoint(xlarge) {
							@include grid-col-size(3.5);

							.top-search-wrapper {
								.minicart-wrapper {
									padding-right: 0;
								}
							}

						}

						@include breakpoint(xxlarge) {
							@include grid-col-size(4);
							@include get-gutter(.5, 'padding-right');
						}

					}
				}// .top-nav-search-sticky-row

				@media screen and (max-width: map-get($breakpoints, large)) {
					.top-search-grid-wrapper {
						text-align: right;
					}
					.top-search-wrapper {
						display: inline-block;
						line-height: ($ts-sticky-headbar-height - ($ts-sticky-headbar-height-spacing * 2));
						// @include get-gutter(.75, 'padding-right');

						.sticky-search {
							display: inline-block !important;
							position: relative;

							&.active {
								.action {
									opacity: .5;
								}
							}
						}

						.block-search {
							display: none;
							position: absolute;
							width: $ts-sticky-headbar-tablet-search-width;
							height: auto;
							top: 100%;
							right: 0;
							padding: $ts-sticky-headbar-tablet-search-spacing;
							background-color: $ts-sticky-headbar-tablet-search-bg;
							box-shadow: $ts-mobile-search-bar-box-shadow;

							&.is-opened {
								display: block;
							}

							#algolia-searchbox {
								.algolia-search-input {
									height: $ts-mobile-search-field-height;
									color: $input-color;
									font-size: $ts-mobile-search-field-font-size;
									border-color: $ts-mobile-search-field-border-color;
									background-color: $ts-mobile-search-field-bg;
									@include placeholder($ts-mobile-search-field-placeholder-color);

									&:hover,
									&:active,
									&:focus,
									&:focus:not([value=""]),
									&.focus {
										border-color: darken($ts-mobile-search-field-border-color, 30);
										background-color: $ts-mobile-search-field-bg !important;
									}
								}// .algolia-search-input

								#algolia-glass {
									height: $ts-mobile-search-field-height;
									background: url("#{$ts-path-to-images}/#{$ts-tablet-search-toggle-button-image}.#{$ts-tablet-search-toggle-button-type}") 50% 50% no-repeat;
									background-size: $ts-tablet-search-toggle-button-image-size auto;
								}
							}// #algolia-searchbox

							.minisearch {
								.input-text {
									height: $ts-mobile-search-field-height;
									color: $input-color;
									font-size: $ts-mobile-search-field-font-size;
									border: $ts-mobile-search-field-border-width $ts-mobile-search-field-border-style $ts-mobile-search-field-border-color;
									margin: $ts-mobile-search-field-margin;
									padding: $ts-mobile-search-field-padding;
									background-color: $ts-mobile-search-field-bg;
									@include placeholder($ts-mobile-search-field-placeholder-color);

									&:hover,
									&:active,
									&:focus,
									&:focus:not([value=""]),
									&.focus {
										border-color: darken($ts-mobile-search-field-border-color, 30);
										background-color: $ts-mobile-search-field-bg !important;
									}

								} // .input-text

								.action.search {
									height: $ts-mobile-search-field-height;
									min-width: ($ts-mobile-search-button-width * 2);
									line-height: $ts-mobile-search-field-height;
									padding: $ts-header-search-input-icon-padding-indent ($ts-header-search-input-icon-padding-indent * 2);
									background: url("#{$ts-path-to-images}/#{$ts-mobile-search-toggle-button-image}.#{$ts-mobile-search-toggle-button-type}") 50% 50% no-repeat;
								} // .action.search
							} // .minisearch
						}

						.minicart-wrapper {
							display: inline-block;
							vertical-align: middle;
							width: auto;
							text-align: right;
							padding-right: 0;
							padding-left: 0;
							margin-left: 1.25rem;
							margin-top: 0;

							.action.showcart {
								margin-left: 0;

							}// .action.showcart

						}// .minicart-wrapper
					}// .top-search-wrapper
				}// max-width: map-get($breakpoints, large)

				@include breakpoint(large only) {
					.top-search-wrapper {
						.minicart-wrapper {
							.action.showcart {
								margin-left: .75rem;
							}// .action.showcart
						}// .minicart-wrapper
					}// .top-search-wrapper
				}// breakpoint(large only)

				@include breakpoint(large down) {
					.nav-extra {
						@include at;
					}
				}// breakpoint(large down)
			}// &.is-stuck

		}// .header-row

		.logo {
			@include grid-col;

			@include breakpoint(medium down) {
				display: none;
			}

			@include breakpoint(large) {
				height: auto;
				line-height: map-get($ts-headbar-height, large);
				text-align: inherit;
				@include grid-col-size(1.5);
				@include border-line('right', 0, $ts-menu-extra-border-color);

				&:before {
					right: 0;
					top: 15%;
					bottom: 15%;
				}
			}

			@include breakpoint(xlarge) {
				text-align: left;
				@include grid-col-size(2.5);
				line-height: map-get($ts-headbar-height, xlarge);

				&:before {
					top: auto;
					bottom: rem-calc(30);
					height: rem-calc(47);
				}
			}

			@include breakpoint(xxlarge) {
				@include grid-col-size(2);
				line-height: map-get($ts-headbar-height, xxlarge);
			}

			@include breakpoint(xxxlarge) {
				@include grid-col-size(2);
				line-height: map-get($ts-headbar-height, xxxlarge);
			}
		} // .logo

		.nav-search-wrapper {
			@include grid-col;
			float: right;
			@include show-for(large);
			padding-top: map-get($ts-header-search-form-padding-top, large);
			height: map-get($ts-headbar-height, large);

			@include breakpoint(large) {
				@include grid-col-size(10.5);
			}

			@include breakpoint(xlarge) {
				height: map-get($ts-headbar-height, xlarge);
				@include grid-col-size(9.5);
				padding-top: map-get($ts-header-search-form-padding-top, xlarge);
			}

			@include breakpoint(xxlarge) {
				height: map-get($ts-headbar-height, xxlarge);
				@include grid-col-size(10);
				padding-top: map-get($ts-header-search-form-padding-top, xxlarge);
			}

			@include breakpoint(xxxlarge) {
				height: map-get($ts-headbar-height, xxxlarge);
				padding-top: map-get($ts-header-search-form-padding-top, xxxlarge);
			}

			.panel.wrapper {
				@include grid-col;
				@include grid-col-size(9.5);
				height: $ts-header-search-input-height;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				line-height: 1;
				margin-bottom: map_get($ts-top-links-bottom-indent, large);

				@if ($ts-top-links-placement == 'normal') {
					justify-content: space-between;
				} @else {
					@include breakpoint(xxxlarge) {
						justify-content: flex-end;

						.top-links {
							@include get-gutter(.5, 'padding-left');
							@include get-gutter(.5, 'padding-right');
						}
					}
				}

				@include breakpoint(large) {
					@include get-gutter(0.25, 'padding-left');
					@include get-gutter(0.25, 'padding-right');
				}

				@include breakpoint(xlarge) {
					@include get-gutter(0.5, 'padding-left');
					@include grid-col-size(7.5);
					margin-bottom: map_get($ts-top-links-bottom-indent, xlarge);
				}
				@include breakpoint(xxlarge) {
					margin-bottom: map_get($ts-top-links-bottom-indent, xxlarge);
				}
				@include breakpoint(xxxlarge) {
					@include grid-row;
					@include grid-row-nest;
					width: auto;
					float: none;
					// padding-left: 0;
					padding-right: 0;
					margin-bottom: map_get($ts-top-links-bottom-indent, xxxlarge);
				}

				.panel.header {
					display: inline-block;
					vertical-align: middle;
					margin-left: 0;
					margin-right: 0;

					@include breakpoint(xxxlarge) {
						@include grid-col;
						@include grid-col-size(5);
						text-align: right;
					}
				}
				.top-links {
					display: inline-block;
					vertical-align: middle;

					@include breakpoint(xxlarge) {
						text-align: $ts-top-links-placement;
					}
				}
			}// .panel.wrapper
		} // .nav-search-wrapper

		.top-nav-wrapper {
			@include grid-row;
			clear: both;
		}// .top-nav-wrapper

		.nav-sections {
			@include grid-col;

			@include breakpoint(medium) {
				@include grid-column-collapse;
			}
			@include breakpoint(xxxlarge) {
				@include get-gutter(0.5, 'padding-left');
				@include get-gutter(0.5, 'padding-right');
				@include grid-col-size(7.75);
			}
		}// .nav-sections

		.top-search-grid-wrapper {
			@include grid-col;

			@include breakpoint(large) {
				@include grid-col-size(4.25);
				@include grid-col-collapse;
			}

			@include breakpoint(large only) {
				margin-top: map_get($ts-header-search-form-medium-margin-top, large);
			}
			@include breakpoint(xlarge only) {
				margin-top: map_get($ts-header-search-form-medium-margin-top, xlarge);
			}
			@include breakpoint(xxlarge only) {
				margin-top: map_get($ts-header-search-form-medium-margin-top, xxlarge);
			}

			@include breakpoint(xlarge) {
				@include grid-col-size(4.25);
			}
			@include breakpoint(xxlarge) {
				padding-left: map-get($grid-column-gutter, medium);
				padding-right: 0;
				@include grid-col-size(5);
			}
			@include breakpoint(xxxlarge) {
				padding-right: 0;
				@include grid-col-size(4.25);
				margin-top: map_get($ts-header-search-form-medium-margin-top, xxxlarge);
			}
		}// .top-search-grid-wrapper

		.navigation {
			padding-right: ($ts-header-cart-dropdown-showcart-icon-width*1.5);

			@include breakpoint(xxlarge) {
				padding-right: 0;
			}

			@include breakpoint(xxxlarge) {
				margin-top: .2rem;
			}

			> ul,
			> .ui-menu {
				@if ($ts-primary-nav-home-url-icon-replace == show) {
					margin-left: -1*(map-deep-get($menu-item-padding-hz, medium, padding-left) - $ts-primary-nav-home-url-icon-width/2);
				} @else {
					// margin-left: -1*(map-deep-get($menu-item-padding-hz, medium, padding-left));
					margin-left: 0;
				}
				@include breakpoint(xlarge) {
					@if ($ts-primary-nav-home-url-icon-replace == show) {
						margin-left: -1*(map-deep-get($menu-item-padding-hz, xlarge, padding-left) - $ts-primary-nav-home-url-icon-width/2);
					} @else {
						// margin-left: -1*(map-deep-get($menu-item-padding-hz, xlarge, padding-left));
						margin-left: 0;
					}
				}
				@include breakpoint(xxlarge) {
					@if ($ts-primary-nav-home-url-icon-replace == show) {
						margin-left: -1*(map-deep-get($menu-item-padding-hz, xxlarge, padding-left) - $ts-primary-nav-home-url-icon-width/2);
					} @else {
						// margin-left: -1*(map-deep-get($menu-item-padding-hz, xxlarge, padding-left));
						margin-left: 0;
						// margin-left: 0;
					}
				}
				@include breakpoint(xxxlarge) {
					@if ($ts-primary-nav-home-url-icon-replace == show) {
						margin-left: -1*(map-deep-get($menu-item-padding-hz, xxxlarge, padding-left) - $ts-primary-nav-home-url-icon-width/2);
					} @else {
						margin-left: 0;
					}
				}
			} // .menu
		} // navigation

	} // .header

}