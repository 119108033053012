// /**
//  * Copyright © Convert, Inc. All rights reserved.
//  *
//  */

//
//  Default appearance styles
//  _____________________________________________

[data-content-type="banner"] > [data-element="link"],
[data-content-type="banner"] > [data-element="empty_link"] {
    color: inherit;
    text-decoration: inherit;

    &:hover {
        color: inherit;
        text-decoration: inherit;
    }
}

.pagebuilder-banner-wrapper {
    background-clip: padding-box;
    border-radius: inherit;
    box-sizing: border-box;
    overflow-wrap: break-word;
    word-wrap: break-word;

    .pagebuilder-overlay {
        box-sizing: border-box;
        padding: $pb-banner-overlay__padding;
        transition: background-color 500ms ease;

        &.pagebuilder-poster-overlay {
            align-items: center;
            display: flex;
            justify-content: center;
        }

        &:not(.pagebuilder-poster-overlay) {
            max-width: 540px;
        }
    }

    [data-element="content"] {
        min-height: 50px;
    }

    .pagebuilder-banner-button {
        margin: $pb-banner-button__margin;
        max-width: 100%;
        text-align: inherit;
        transition: opacity 500ms ease;
        word-break: break-word;
    }
}

//
//  Responsive Classes
//  _____________________________________________

@include breakpoint ($pb-responsive-stacking) {
    .pagebuilder-banner-wrapper {
        background-attachment: scroll !important;

        .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
            max-width: none;
        }
    }
}