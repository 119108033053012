%swatch-option-color {
	.swatch-option {
		display: block;
		width: $ts-color-swatch-width;
		height: $ts-color-swatch-height;
		display: inline-block;
		vertical-align: middle;
		position: relative;
		text-align: center;
		background-color: #fff;
		cursor: pointer;
		border-radius: $ts-color-swatch-radius;

		@if $ts-color-swatch-border {
			box-shadow: inset 0 0 0 1px $ts-color-swatch-border-color;
		}

		&:hover,
		&:active {
			&:before {
				.touchevents & {
					display: block;
				}
			}
		}

		&:hover,
		&:active,
		&.selected,
		&.checked {
			&:after {
				display: block;
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate3d(-50%, -50%, 0);
				width: 100%;
				height: 100%;
				background: url("#{$ts-path-to-images}/#{$ts-color-swatch-active-image}.#{$ts-color-swatch-active-image-type}") 50% no-repeat;
				background-size: $ts-color-swatch-active-image-width auto;
			}
		}

		.product-items & {
			width: $ts-color-swatch-product-list-width;
			height: $ts-color-swatch-product-list-height;
			border-radius: $ts-color-swatch-product-list-radius;
			vertical-align: top;
			margin: $ts-color-swatch-product-list-indent;
			margin-left: 0;

			&.selected {
				height: $ts-color-swatch-product-list-height;
				box-shadow: $ts-color-swatch-product-list-selected-shadow;

				&:after {
					display: none;
				}
			}
		}

		&.disabled {
			opacity: .25;
		}

		&[option-label="White"],
		&[option-label="Hvit"] {
			box-shadow: inset 0 0 0 1px rgba($black,.1);
		}
	}// .swatch-option
}// %swatch-option-color

.filter-options-content {

	ol,
	ul {
		@include lsn;
	}

	&.has-labels {
		ol,
		ul {
			font-size: $ts-sidebar-main-filter-font-size;
			border-top: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;

			&.am-filter-items-attr_price {
				border-top: 0;
			}

			.item {
				border-bottom: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;

				&:last-child {
					border-bottom: 0;
				}

				a {
					display: block;
					text-transform: $ts-sidebar-main-filter-text-transform;
					font-family: $ts-sidebar-main-filter-font-family;
					font-size: ($ts-sidebar-main-filter-font-size*0.87);
					font-weight: $ts-sidebar-main-filter-font-weight;
					color: $ts-sidebar-main-filter-anchor-color;
					padding: $ts-sidebar-main-filter-anchor-padding;
					cursor: pointer;

					@include breakpoint(xlarge) {
						font-size: $ts-sidebar-main-filter-font-size;
					}

					.label {
						cursor: inherit;
						text-decoration: $ts-sidebar-main-filter-anchor-underline;
					}

					&:hover {
						color: $ts-sidebar-main-filter-anchor-color-hover;
						background-color: $ts-sidebar-main-filter-anchor-bg-hover;
						text-decoration: none;

						.label {
							text-decoration: $ts-sidebar-main-filter-anchor-underline-hover;
						}
					}

					&.am_shopby_link_selected {
						color: $ts-sidebar-main-filter-anchor-color-active;
						background-color: $ts-sidebar-main-filter-anchor-bg-active;
						text-decoration: none;

						.label {
							padding-left: $ts-text-swatch-active-image-padding-left;
							font-weight: $ts-sidebar-main-filter-anchor-font-weight-active;
							text-decoration: $ts-sidebar-main-filter-anchor-underline-active;
							background: url("#{$ts-path-to-images}/#{$ts-text-swatch-active-image}.#{$ts-text-swatch-active-image-type}") $ts-text-swatch-active-image-position no-repeat;
							background-size: $ts-text-swatch-active-image-size;
						}
					}
				}// a

				> .label {
					text-transform: $ts-sidebar-main-filter-text-transform;
					font-family: $ts-sidebar-main-filter-font-family;
					font-size: ($ts-sidebar-main-filter-font-size*0.87);
					font-weight: $ts-sidebar-main-filter-font-weight;
					color: $ts-sidebar-main-filter-anchor-color;
					padding: $ts-sidebar-main-filter-anchor-padding;

					@include breakpoint(xlarge) {
						font-size: $ts-sidebar-main-filter-font-size;
					}
				}

				.count {
					display: inline-block;
					vertical-align: middle;
					color: $ts-sidebar-main-filter-count-color;
					font-size: $ts-sidebar-main-filter-count-font-size;

					&:before {
						content: '(';
					}

					&:after {
						content: ')';
					}

					.filter-count-label {
						@include at;
					}
				}// .count
			}// li
		}// ol,ul

		@if $ts-sidebar-category-has-checkbox {
			.am-filter-items-attr_category_ids {
				.item a {
					position: relative;
					display: block;
					text-transform: $ts-sidebar-main-filter-text-transform;
					font-family: $ts-sidebar-main-filter-font-family;
					font-size: ($ts-sidebar-main-filter-font-size*0.87);
					font-weight: $ts-sidebar-main-filter-font-weight;
					color: $ts-sidebar-main-filter-anchor-color;
					padding: $ts-sidebar-main-filter-anchor-padding;
					cursor: pointer;

					@include breakpoint(xlarge) {
						font-size: $ts-sidebar-main-filter-font-size;
					}

					&:before {
						display: block;
						content: '';
						width: $ts-color-label-swatch-width;
						height: $ts-color-label-swatch-height;
						position: absolute;
						top: 9px;
						left: 0;
						text-align: center;
						background-color: #fff;
						cursor: pointer;
						border-radius: $ts-color-swatch-radius;

						@if $ts-color-swatch-border {
							box-shadow: inset 0 0 0 1px $ts-color-swatch-border-color;
						}
					}

					.label {
						cursor: inherit;
						text-decoration: $ts-sidebar-main-filter-anchor-underline;
						margin-left: .25rem;
					}

					&:hover,
					&:active {
						.label {
							text-decoration: $ts-sidebar-main-filter-anchor-underline-hover;
						}
					}

					&:hover,
					&:active,
					&.am_shopby_link_selected {
						color: $ts-sidebar-main-filter-anchor-color-hover;
						background-color: $ts-sidebar-main-filter-anchor-bg-hover;
						text-decoration: none;

						.label {
							padding-left: 0;
							background: none;
							font-weight: inherit;
						}

						&:after {
							display: block;
							content: '';
							position: absolute;
							top: 9px;
							left: 0;
							width: $ts-color-label-swatch-width;
							height: $ts-color-label-swatch-height;
							background: url("#{$ts-path-to-images}/icon-check.svg") 50% no-repeat;
							background-size: 12px auto;
						}
					}
				}
			}
		}
	}// &.has-labels


	&.has-images {
		padding-top: $ts-color-swatch-category-top-indent;
		padding-bottom: $ts-color-swatch-category-bottom-indent;

		@if $ts-color-swatch-category-top-border {
			border-top: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;
		}

		.swatch-option-link-layered,
		.ais-refinement-list--item {
			display: inline-block;
			vertical-align: top;
			border-radius: $ts-color-swatch-radius;
			margin: $ts-color-swatch-outer-indent;
			padding: $ts-color-swatch-inner-indent;
			position: relative;
			font-size: 0;

			&.checked,
			&.ais-refinement-list--item__active {
				box-shadow: 0 0 0 1px lighten($ts-color-swatch-border-shadows-basic-color, 67.0588);
				background:$white url("#{$ts-path-to-images}/#{$ts-color-swatch-active-image}.#{$ts-color-swatch-active-image-type}") 50% calc(100% - 3px) no-repeat;
				background-size: $ts-color-swatch-active-image-width auto;
			}
		}// .swatch-option-link-layered

		@extend %swatch-option-color;

	}// &.has-images-labels


	&.has-images-labels {
		.am-swatch-options,
		.swatch-attribute-options {
			font-size: $ts-sidebar-main-filter-font-size;
			border-top: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;

			.item {
				display: block;
				position: relative;
				border-bottom: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;

				&:last-child {
					border-bottom: 0;
				}

				.am-swatch-link {
					display: inline-block;
				}

				@extend %swatch-option-color;

				.swatch-option {
					width: $ts-color-label-swatch-width;
					height: $ts-color-label-swatch-height;
				}

				.am_shopby_swatch_label,
				.am-shopby-swatch-label {
					display: block;
					text-transform: $ts-sidebar-main-filter-text-transform;
					font-family: $ts-sidebar-main-filter-font-family;
					font-size: ($ts-sidebar-main-filter-font-size*0.87);
					font-weight: $ts-sidebar-main-filter-font-weight;
					color: $ts-sidebar-main-filter-anchor-color;
					padding: $ts-sidebar-main-filter-anchor-padding;
					cursor: pointer;

					@include breakpoint(xlarge) {
						font-size: $ts-sidebar-main-filter-font-size;
					}

					.label {
						cursor: inherit;
						text-decoration: $ts-sidebar-main-filter-anchor-underline;
						margin-left: .25rem;
					}

					&:hover {
						color: $ts-sidebar-main-filter-anchor-color-hover;
						background-color: $ts-sidebar-main-filter-anchor-bg-hover;
						text-decoration: none;

						.label {
							text-decoration: $ts-sidebar-main-filter-anchor-underline-hover;
						}
					}
				}// a

				.count {
					display: inline-block;
					vertical-align: middle;
					color: $ts-sidebar-main-filter-count-color;
					font-size: $ts-sidebar-main-filter-count-font-size;

					&:before {
						content: '(';
					}

					&:after {
						content: ')';
					}

				}// .count
				.filter-count-label {
					@include at;
				}
			}// .item
		}
	}// &.has-images-labels


	&.has-text-swatch {
		padding-top: $ts-size-swatch-category-top-indent;
		padding-bottom: $ts-size-swatch-category-bottom-indent;

		@if $ts-size-swatch-category-top-border {
			border-top: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;
		}

		.swatch-option-link-layered,
		.ais-refinement-list--item {
			display: inline-block;
			vertical-align: top;
			min-width: $ts-size-swatch-width;
			margin: $ts-size-swatch-outer-indent;
			cursor: pointer;
			font-size: 0;

			&[href="javascript:void();"] {
				display: none;
			}
		}

		.swatch-option {
			width: auto;
			min-width: $ts-size-swatch-width;
			height: $ts-size-swatch-height;
			padding: $ts-size-swatch-text-inner-indent;
			line-height: $ts-size-swatch-height;
			position: relative;
			text-align: center;
			cursor: pointer;
			color: $ts-size-swatch-color-text;
			font-size: $ts-size-swatch-font-size;
			font-weight: $ts-size-swatch-font-weight;
			font-family: $ts-size-swatch-font-family;
			box-shadow: $ts-size-swatch-box-shadow;
			background-color: $ts-size-swatch-bg-color;
			cursor: pointer;
			letter-spacing: $ts-size-swatch-letter-spacing;
			word-spacing: $ts-size-swatch-word-spacing;

			&:before,
			&:after {
				display: none;
			}
			&.disabled {
				&:before,
				&:after {
					display: none;
				}
			}

			&:hover,
			&:active,
			&.selected,
			&.checked {
				color: $ts-size-swatch-color-text-active;
				background-color: $primary-color;
			}
		}
	}// &.has-text-swatch


	&.has-ui-slider {
		.am_shopby_slider_container {
			position: relative;
		}

		.ui-slider {
			position: relative;
			// width: 100%;
			height: 0;
			border-top: $ts-sidebar-main-price-slider-line-width $ts-sidebar-main-price-slider-line-type $ts-sidebar-main-price-slider-line-color;
			border-radius: 0;
			margin: $ts-sidebar-main-price-slider-margin;
			text-align: left;

			&:after {
				display: block;
				content:'';
				position: absolute;
				width: $ts-sidebar-main-price-slider-point-width - 3px;
				left: 100%;
				top: 50%;
				height: $ts-sidebar-main-price-slider-line-width;
				margin-top: (-1*$ts-sidebar-main-price-slider-line-width);
				background: $ts-sidebar-main-price-slider-line-color;
			}

			.ui-slider-handle {
				position: absolute;
				z-index: 2;
				width: $ts-sidebar-main-price-slider-point-width;
				height: $ts-sidebar-main-price-slider-point-height;
				border-radius: 50%;
				background: $ts-sidebar-main-price-slider-point-bg;
				border-radius: 100%;
				border: $ts-sidebar-main-price-slider-point-border;
				top: -($ts-sidebar-main-price-slider-point-height/2);
				margin-left: -1px;
				margin-top: 0;
				cursor: pointer;
				-ms-touch-action: none;
				touch-action: none;

				&:focus {
					outline: none;
				}
			}

			.ui-slider-range {
				position: absolute;
				z-index: 1;
				font-size: .7em;
				display: block;
				border: 0;
				background: lighten(black,62.7%);
				background-position: 0 0;
				height: 100%;
			}

			/* support: IE8 - See #6727 */
			&.ui-state-disabled .ui-slider-handle,
			&.ui-state-disabled .ui-slider-range {
				filter: inherit;
			}

		}// .ui-slider

		.min-value-container,
		.max-value-container {
			font-size: $ts-sidebar-main-price-slider-font-size;
			font-weight: $ts-sidebar-main-price-slider-font-weight;
			color: $ts-sidebar-main-price-slider-color;
			position: absolute;
			padding-top: rem-calc(3);
		}

		.min-value-container {
			left: 0;
		}

		.max-value-container {
			right: 0;
		}
	}

	&.has-dropdown {
		&.has-multiselect {
			border-top: 0;

			.chosen-container {
				.chosen-choices {
					height: $ts-category-sorting-toolbar-height;
					background-repeat: no-repeat;
					background-size: $input-state-icon-size;
					background-position: $input-state-icon-position;
					padding: $input-padding;
					padding-top: 6px;
					padding-bottom: 6px;
					border: $input-border;

					@if $select-triangle-color = transparent {
						padding-right: $select-triangle-right-space;
						background:$select-background url("#{$ts-path-to-images}/#{$select-triangle-icon}.#{$select-triangle-icon-type}") $select-triangle-icon-position no-repeat;
						background-size: $select-triangle-icon-size auto;
					}

					.search-field {
						input {
							margin: 0;
							color: $ts-category-sorting-toolbar-color;
							font-weight: $ts-category-sorting-toolbar-font-weight;
							font-family: $input-font-family;
							font-size: $ts-category-sorting-toolbar-font-size;
							text-transform: $ts-category-sorting-toolbar-text-transform;
						}
					}
					li.search-choice {
						box-shadow: none;
						border-color: rgba($primary-color,.1);
						border-radius: $global-radius;
						background: rgba($primary-color,.1);
					}
				}
				.chosen-drop {
					border: $input-border;
					border-top: 0;
				}
				&.chosen-container-active {
					.chosen-choices {
						border: $input-border;
						box-shadow: none;
					}
				}
				.chosen-results {
					.active-result {
						&.highlighted {
							background: $primary-color;
						}
					}
				}
			}// .chosen-container

		}
	}
    .am-show-more {
        display: none;
    }
    .am-show-more::before {
        margin-top: 10px;
    }
    .am-show-more::after {
        margin-top: 10px;
    }
}// .filter-options-content


.swatch-option-tooltip {
	display: none;
	width: $ts-color-swatch-tooltip-width;
	min-height: 20px;
	min-width: 20px;
	position: absolute;
	padding: 6px;
	text-align: center;
	line-height: 1.3;
	background: $ts-color-swatch-tooltip-bg;
	box-shadow: 0 0 0 1px rgba($ts-color-swatch-border-shadows-basic-color, .02), 2px 0 10px rgba($ts-color-swatch-border-shadows-basic-color, .1);
	z-index: $ts-color-swatch-tooltip-zindex;

	.touchevents &,
	body.catalog-product-view & {
		display: none !important;
	}

	.title {
		height: $ts-color-swatch-tooltip-title-height;
		overflow: hidden;
	}

	.image {
		height: $ts-color-swatch-tooltip-image-height;
		display: block;
		margin-bottom: 6px;
	}

	.corner {
		width: $ts-color-swatch-tooltip-corner-width;
		height: ($ts-color-swatch-tooltip-corner-width/2);
		border-style: solid;
		border-width: ($ts-color-swatch-tooltip-corner-width/2) ($ts-color-swatch-tooltip-corner-width/2) 0 ($ts-color-swatch-tooltip-corner-width/2);
		position: absolute;
		top: 100%;
		border-color: $ts-color-swatch-tooltip-bg transparent transparent transparent;
	}
}// .swatch-option-tooltip

@if $ts-hide-all-swatch-tooltips {
	.swatch-option-tooltip {
		display: none !important;
	}
}

.swatch-attribute-label {
	display: inline-block;
	vertical-align: middle;
	font-weight: bold;
	@include label-colon;
	margin-bottom: .25rem;

	.product-info-main & {
		font-size: $ts-color-swatch-title-font-size;
		font-weight: $ts-color-swatch-title-font-weight;
		font-family: $ts-color-swatch-title-font-family;
		font-style: $ts-color-swatch-title-font-style;
		text-transform: $ts-color-swatch-title-text-transform;
	}
}// .swatch-attribute-label

.swatch-attribute-selected-option {
	margin-left: .5rem;
	display: inline-block;
	vertical-align: middle;
}// .swatch-attribute-selected-option

.product-info-main {
	.swatch-attribute-options,
	.ais-refinement-list--list {
		width: 100%;
		display: block;

		.ais-refinement-list--item {
			display: inline-block;
			vertical-align: top;
			min-width: $ts-size-product-swatch-width;
			width: auto;
			border:0;
		}

		.swatch-option {
			display: inline-block;
			vertical-align: middle;
			min-width: $ts-size-product-swatch-width;
			width: auto;
			height: $ts-size-product-swatch-height;
			padding: $ts-size-product-swatch-text-inner-indent;
			text-align: center;
			color: $ts-size-product-swatch-color;
			line-height: $ts-size-product-swatch-height;
			border-radius: $ts-size-swatch-radius;
			font-size: $ts-size-product-swatch-font-size;
			font-weight: $ts-size-product-swatch-font-weight;
			font-family: $ts-size-product-swatch-font-family;
			margin: $ts-size-product-swatch-margin;
			white-space: nowrap;
			box-shadow: none;
			background-color: $ts-size-product-swatch-bg;
			cursor: pointer;

			@if not($ts-size-product-swatch-border == transparent) {
				border-left: 1px solid $ts-size-product-swatch-border;
			}


			&:first-child {
				border-left: 0;
			}

			&:hover,
			&:active,
			&.selected {
				color: $ts-size-product-swatch-color-active;
				background-color: $ts-size-product-swatch-bg-active;

				@if not($ts-size-product-swatch-border == transparent) {
					border-left-color: $ts-size-product-swatch-bg-active;
				}

				@if $ts-size-product-swatch-has-full-border {
					box-shadow: $ts-size-product-swatch-full-border;

				} @else {
					box-shadow: none;
				}

				&:after {
					display: none;
				}

				@if not($ts-size-product-swatch-border == transparent) {
					+ .swatch-option {
						border-left-color: $ts-size-product-swatch-bg-active;

						&:hover {
							border-left-color: $ts-size-product-swatch-border-hover;
						}
					}
				}
			}

			&.disabled {
				opacity: 1;
				position: relative;
				color: $ts-size-product-swatch-disabled-color;

				&:hover,
				&:active {
					cursor: help;
					color: $ts-size-product-swatch-disabled-color;
					background: transparent;
					@if not($ts-size-product-swatch-border == transparent) {
						border-left-color: $ts-size-product-swatch-border;
						+ .swatch-option {
							border-left-color: $ts-size-product-swatch-border;

							&:hover {
								border-left-color: $ts-size-product-swatch-border;
							}
						}
					}


					&:before,
					&:after {
						opacity: 1;
					}
				}

				&:before {
					opacity: 0;
					display: block;
					content: attr(option-label);
					position: absolute;
					top: 100%;
					left: 50%;
					transform: translateX(-50%);
					padding: rem-calc(10);
					font-size: rem-calc(11);
					line-height: 0;
					margin: 0;
					color: $white;
					white-space: nowrap;
					background: $black;
					transition: opacity .3s ease;
					z-index: 1;
				}
				&:after {
					opacity: 0;
					content: '';
					width: 0;
					height: 0;
					display: block;
					border-style: solid;
					border-width: 0 6px 6px 6px;
					border-color: transparent transparent $black transparent;
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
					transition: opacity .3s ease;
				}
			}
		}
	}// .swatch-attribute-options
}// .product-detail-swatch

.filter-options[role="tablist"] {
	border-top: 0;
	ol,
	ul {
		border-top: 0;
		.item{
			&:last-child {
				border-bottom: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;
			}
			a {
				padding: rem-calc(8 4 8 26);
			}
		}
	}

	.filter-options-content {
		&:last-child {
			border-top: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;
		}

		&.has-images-labels {
			.swatch-attribute {
				padding-top: 0;
			}
			.swatch-attribute-options {
				border-top: 0;
				padding-left: 0;

				.item {
					padding: 0;

					&:last-child {
						border-bottom: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;
					}
					.am_shopby_swatch_label {
						padding: rem-calc(8 4 8 26);
					}
				}
			}
		}
	}
	.filter-options-title {
		cursor: pointer;
		@extend .no-outline;

		&:nth-last-child(2) {
			border-bottom: 0;
		}
	}
	.filter-options-title {
		padding: rem-calc(9 9 9 26);
		position: relative;
		border-bottom: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;

		&:before {
			display: block;
			content: '+';
			line-height: 1;
			position: absolute;
			top: 50%;
			left: 2px;
			font-size: 1.25em;
			transform: translateY(-50%);
		}

		&.active {
			&:before {
				content: '—';
				font-size: .75em;
			}
		}
	}

	.swatch-attribute-options {
		padding-left: rem-calc(26);
	}
	.swatch-attribute {
		padding: rem-calc(15 0 0);
	}
}


