$color-green: #4b980c;
$color-orange: #f37200;
$color-silver: #ebebeb;

.custom-stock-modal {
    .modal-footer,
    .modal-header {
        display: none;
    }

    .modal-content {
        padding: 0 15px;
    }

    .modal-inner-wrap {
        border-radius: 5px;
        max-width: 800px;
        width: 100%;
    }

    .stock-modal-wrapper {

        .stock-modal-bottom {
            text-align: right;
            padding: 20px;
            border-top: 1px solid $color-silver;
        }

        .stock-modal-content {
            border-top: 1px solid $color-silver;
            padding: 20px 0;
            margin: 30px 20px 0 20px;
        }
    }
}

.catalog-product-view ,
.page-product-configurable {
    .stock {
        float: none !important;

        span {
            cursor: pointer;
            padding: 0;
            background: none;
        }

        &.available {
            span {
                color: $color-green;
            }
        }

        &.backorderable {
            span {
                color: $color-orange;
            }
        }

        &.unavailable {
            span {
                color: $black;
            }
        }
    }
}

.page-product-configurable {
    .product-info-stock-sku {
        display: none !important;
    }
}
