//  Skala | Foundation for Sites Settings
//  -----------------------------

// 1. Global
// ---------
$body-font-size : rem-calc(16);

$foundation-palette: (
	primary: #d8282f,
  secondary: #222222,
	success: #0C634C,
	warning: #ffae00,
	alert: #D90904,
);
$black: #000000;
$gray-dark: #222222;
$tertiary-color: #e87307;
$box-color: #f5f5f5 ;

$global-weight-normal: 400;
$global-weight-bold: 500;
$global-radius: 0;

$body-background: $white;
$body-font-family: ("urw-din",sans-serif);

@include add-foundation-colors;

// 4. Base Typography
// ------------------
$header-font-family: ("urw-din",sans-serif);

$header-styles: (
  small: (
    'h1': ('font-size': 28, 'line-height': 36),
    'h2': ('font-size': 24, 'line-height': 31),
    'h3': ('font-size': 22, 'line-height': 28),
    'h4': ('font-size': 22, 'line-height': 28),
    'h5': ('font-size': 20, 'line-height': 26),
    'h6': ('font-size': 17, 'line-height': 22),
  ),
  medium: (
    'h1': ('font-size': 50, 'line-height': 64),
    'h2': ('font-size': 36, 'line-height': 46),
    'h3': ('font-size': 32, 'line-height': 41),
    'h4': ('font-size': 26, 'line-height': 33),
    'h5': ('font-size': 22, 'line-height': 28),
    'h6': ('font-size': 22, 'line-height': 28),
  ),
  xlarge: (
    'h1': ('font-size': 50, 'line-height': 64),
    'h2': ('font-size': 36, 'line-height': 46),
    'h3': ('font-size': 32, 'line-height': 41),
    'h4': ('font-size': 26, 'line-height': 33),
    'h5': ('font-size': 22, 'line-height': 28),
    'h6': ('font-size': 22, 'line-height': 28),
  ),
);

$header-font-weight: 500;
$header-text-transform: none;
$paragraph-lineheight: 1.4;
$anchor-color: $primary-color;
$anchor-color-hover: $primary-color;

// 10. Breadcrumbs
// ---------------

$breadcrumbs-item-font-size: rem-calc(14);
$breadcrumbs-item-color: $black;
$breadcrumbs-item-color-current: $black;
$breadcrumbs-item-uppercase: false;
$breadcrumbs-item-slash: true;
$breadcrumbs-item-slash-color: rgba($black, .5);

// 11. Button
// ----------
$button-font-family: $header-font-family;
$button-padding: .82em 2em 0.87em;
$button-weight: 500;
$button-background: $primary-color;
$button-color: $white;
$button-background-hover: lighten($primary-color, 7%);
$button-background-active: darken($primary-color, 7%);
$button-background-secondary: $secondary-color;
$button-background-secondary-hover: lighten($secondary-color, 7%);
$button-background-secondary-active: darken($secondary-color, 7%);

$button-sizes: (
	tiny: rem-calc(15),
	small: rem-calc(15),
	default: rem-calc(15),
	xlarge: rem-calc(15),
);

$button-text-transform: none;

// 19. Forms
// ---------
$input-border: 1px solid rgba($black, .3);
$input-border-focus: 1px solid rgba($black, .5);
$input-color: $black;
$input-font-family: $body-font-family;
$input-font-size: rem-calc(16);
$input-radius: $global-radius;

$select-radius: $global-radius;
$select-triangle-icon: 'icon-select';
$select-triangle-icon-size: 16px;

$form-label-font-size: rem-calc(16);

// 22. Menu
// --------
$menu-item-padding-hz  : ( medium : ( padding-left: rem-calc(10), padding-right: rem-calc(10)),
                           xlarge  : ( padding-left: rem-calc(10), padding-right: rem-calc(10)),
                           xxlarge : ( padding-left: rem-calc(10), padding-right: rem-calc(10)),
                           xxxlarge : ( padding-left: rem-calc(15), padding-right: rem-calc(15)));

$menu-item-padding-vt  : ( medium : ( padding-top: rem-calc(11), padding-bottom: rem-calc(12)),
                           xlarge  : ( padding-top: rem-calc(11), padding-bottom: rem-calc(12)),
                           xxlarge : ( padding-top: rem-calc(21), padding-bottom: rem-calc(22)),
                           xxxlarge : ( padding-top: rem-calc(21), padding-bottom: rem-calc(22)));
$menu-item-color-active: $white;
$menu-item-background-active: $primary-color;

// 31. Table
// ---------

$table-background: $white;
$table-color-scale: 20%;
$table-border: 1px solid smart-scale($table-background, $table-color-scale);
$table-margin: 2.5rem;
$table-padding: rem-calc(20 10 20 0);
$table-head-padding: rem-calc(10 10 10 0);
$table-hover-scale: 5%;
$table-row-hover: darken($table-background, $table-hover-scale);
$table-row-stripe-hover: darken($table-background, $table-color-scale + $table-hover-scale);
$table-striped-background: smart-scale($table-background, $table-color-scale);
$table-stripe: none;
$table-head-background: $white;
$table-foot-background: $box-color;
$table-head-font-color: $body-font-color;
$table-head-font-family: $header-font-family;
$table-head-font-weight: inherit;
$table-head-font-size: rem-calc(17);
$table-body-font-size: rem-calc(15);
$show-header-for-stacked: false;