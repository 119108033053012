// /**
//  * Copyright © Convert, Inc. All rights reserved.
//  *
//  */

$darker-gray: #282a36;
$light-blue: #8be9fd;
$light-red: #ff79c6;
$light-gray: #f8f8f2;
$light-green: #50fa7b;
$color-yellow: #f1fa8c;
$dark-purple: #6272a4;
$light-purple: #bd93f9;

.hljs {
    background: $darker-gray;
    display: block;
    overflow-x: auto;
    padding: .5em;
}

.hljs-built_in,
.hljs-selector-tag,
.hljs-section,
.hljs-link {
    color: $light-blue;
}

.hljs-keyword {
    color: $light-red;
}

.hljs,
.hljs-subst {
    color: $light-gray;
}

.hljs-title {
    color: $light-green;
}

.hljs-string,
.hljs-meta,
.hljs-name,
.hljs-type,
.hljs-attr,
.hljs-symbol,
.hljs-bullet,
.hljs-addition,
.hljs-variable,
.hljs-template-tag,
.hljs-template-variable {
    color: $color-yellow;
}

.hljs-comment,
.hljs-quote,
.hljs-deletion {
    color: $dark-purple;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-literal,
.hljs-title,
.hljs-section,
.hljs-doctag,
.hljs-type,
.hljs-name,
.hljs-strong {
    font-weight: bold;
}

.hljs-literal,
.hljs-number {
    color: $light-purple;
}

.hljs-emphasis {
    font-style: italic;
}
