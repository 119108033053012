// /**
//  * Copyright © Convert, Inc. All rights reserved.
//  *
//  */

@mixin pb-icons (
	$use         : $pb-icons__use,
	$font-family : $pb-icons__font-family,
	$path        : $pb-icons__font-path,
	$arrow--up   : $pb-icons-arrow--up__icon,
	$arrow--down : $pb-icons-arrow--down__icon,
	$arrow--next : $pb-icons-arrow--next__icon,
	$arrow--prev : $pb-icons-arrow--prev__icon
	) {
	@if $use {
		@font-face {
		    font-family: $font-family;
		    font-style: normal;
		    font-weight: normal;
		    src: url('#{$path}.eot');
		    src: url('#{$path}.eot?#iefix') format('embedded-opentype'),
		         url('#{$path}.woff') format('woff'),
		         url('#{$path}.ttf') format('truetype');
		}

		.pagebuilder-icon {
		    font-family: $font-family;
		    -webkit-font-smoothing: antialiased;
		    font-style: normal;
		    font-weight: normal;
		    line-height: 1;
		    speak: none;
		}

		[class^="pagebuilder-icon-"]:before,
		[class*=" pagebuilder-icon-"]:before {
		    display: inline-block;
		    font-family: $font-family;
		    -webkit-font-smoothing: antialiased;
		    -moz-osx-font-smoothing: grayscale;
		    font-style: normal;
		    font-weight: normal;
		    line-height: 1;
		    speak: none;
		    text-decoration: inherit;
		    text-rendering: auto;
		    text-transform: none;
		    vertical-align: middle;
		}

		.pagebuilder-icon-down:before {
		    content: $arrow--down;
		}

		.pagebuilder-icon-next:before {
		    content: $arrow--next;
		}

		.pagebuilder-icon-prev:before {
		    content: $arrow--prev;
		}

		.pagebuilder-icon-up:before {
		    content: $arrow--up;
		}
	}
}

@include pb-icons;