.header {

	.minicart-wrapper {
		border: 0;
		display: inline-block;
		line-height: 1;
		position: relative;
		background: none;

		@include breakpoint(xlarge down) {
			margin-top: .5rem;
		}


		@if ($ts-header-cart-dropdown-showcart-type == image-only) {
			&:after {
				display: block;
				content: '';
				width: $ts-header-cart-dropdown-showcart-icon-width;
				height: $ts-header-cart-dropdown-showcart-icon-height;
			}
			.action.showcart {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);

				@include breakpoint(medium down) {
					position: relative;
					left: auto;
					top: auto;
					transform: scale(.85) translateY(0);
					margin: 0;
				}

				@include breakpoint(xxlarge) {
					left: 0;
					right: auto;
					margin: $ts-header-cart-dropdown-showcart-icon-spacing;
				}

			}// .action.showcart
		}

		.mage-dropdown-dialog {
			display: block;
			position: absolute;
			right: $ts-header-cart-dropdown-position-right;
			top: $ts-header-cart-dropdown-position-top;
			margin-top: $ts-header-cart-dropdown-position-top-indent;
			width: $ts-header-cart-dropdown-width;
			@include get-gutter(0.5,'padding');
			z-index: $ts-header-cart-dropdown-z-index;
			background-color: $ts-header-cart-dropdown-bg-color;
			@if ( $ts-header-cart-dropdown-box-shadow != none ) {
				box-shadow: $ts-header-cart-dropdown-box-shadow;
			}

			.block-title,
			.subtitle:not(.empty) {
				@include at;
			}

			.subtitle {
				&.empty {
					font-size: $ts-header-cart-dropdown-subtitle-empty-size;
					color: $ts-header-cart-dropdown-subtitle-empty-color;
					font-weight: $ts-header-cart-dropdown-subtitle-empty-weight;
				}
			}// .subtitle

			.action.close {
				display: inline-block;
				position: absolute;
				right: $ts-header-cart-dropdown-close-position-right;
				top: $ts-header-cart-dropdown-close-position-top;
				font-size: $ts-header-cart-dropdown-close-font-size;
				color:$ts-header-cart-dropdown-close-color;

				span {
					@include at;
				}
			}// .action.close

		}// .mage-dropdown-dialog
	}  // .minicart-wrapper

	.block-minicart {
		.subtotal {
			text-align: left;
			padding-top: 1rem;
			margin-bottom: 1rem;

			.label {
				@include no-label;
				span {
					@include label-colon;
				}
			}

			.amount.price-container {
				display: inline-block;
				vertical-align: middle;
				font-weight: 700;
			}
		}// .subtotal

		button.checkout {
			margin-bottom: 0;
			@include button-expand;
			font-size: map_get($button-sizes,xlarge);
		}// button.checkout
	}// .block-minicart

} // .header

.content.minicart-items {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	padding-top: 1rem;
	z-index: 1;

	.minicart-items-wrapper {
		width: rem-calc(380);
		text-align: left;
		padding: 1.5rem;
		box-shadow: (0 0 25px rgba($black,0.15));
		position: relative;
		background-color: $white;

		&:after {
		    content: '';
		    width: 0;
		    height: 0;
		    border-style: solid;
		    border-width: 0 1rem 1rem 1rem;
		    border-color: transparent transparent $white transparent;
		    position: absolute;
		    bottom: 100%;
		    left: 4rem;
		}
	}


	.minicart-items {
		@include lsn;

		.product-item {
			padding: .5rem 0;
			border-bottom: 1px solid $ts-checkout-minicart-border-color;

			&:first-child {
				padding-top: 0;
			}


			&:last-child {
				padding-bottom: 0;
				border-bottom: 0;
			}
		}

		.product-item-details {
			@include clearfix;
			line-height: 1.1;
			font-size: 0;
		}

		.col {
			display: inline-block;
			vertical-align: middle;
			font-size: $ts-checkout-minicart-font-size;
		}

		.product-image {
			width: 15%;
			text-align: center;
		}
		.product-title {
			width: 55%;
			padding: 0 1rem;
			text-transform: $ts-checkout-minicart-title-transform;
			@include header-font-family($ts-checkout-minicart-title-font-family);

			.product-item-name {
				font-weight: $ts-checkout-minicart-title-font-weight;
			}

			.item-options {
				font-size: 90%;
			}

			.label {
				@include no-label;
				margin-right: 2px;
			}
		}

		.product-qty {
			width: 10%;
			text-align: center;
			padding: 0 .25rem;
			color: $ts-checkout-minicart-qty-color;
			@include header-font-family($ts-checkout-minicart-qty-font-family);
		}
		.product-subtotal {
			width: 20%;
			padding: 0 .25rem;
			font-weight: $ts-checkout-minicart-subtotal-font-weight;
			@include header-font-family($ts-checkout-minicart-subtotal-font-family);
		}
	}
}// .content.minicart-items