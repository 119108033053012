// /**
//  * Copyright © Convert, Inc. All rights reserved.
//  *
//  */

//
//  Product -- Full Width
//  _____________________________________________

.product-full-width-section {
    padding: .5em 0;

    .block.review-add {
        margin-top: 2.7rem;
    }
}

.page-layout-product-full-width {
    .block.related {
        margin-top: 2.7rem;
    }
}

.page-main-details .product-section-title {
    border-bottom: 1px solid #c6c6c6;
    margin-bottom: 15px;
    padding-bottom: 12px;
}

.additional-attributes-wrapper .additional-attributes {
    border: none;
    width: auto;

    > tbody > tr {
        > th {
            border: none;
            padding: 5.5px 30px 10px 0;
        }
        > td {
            border: none;
            padding: 5.5px 5px 10px;
        }
    }
}

.pagebuilder-content-type {
    box-sizing: border-box;
    margin-bottom: 20px;
}

//
//  Page -- Full Width
//  _____________________________________________

.pagebuilder-full-width {
    float: left;
    width: 100%;
}

body.page-layout-cms-full-width {
	.nav-sections {
    	margin-bottom: 0;
    }

    .page-container {
    	padding-left: 0;
    	padding-right: 0;
    }

    .column.main,
    .main.columns {
    	padding-left: 0;
    	padding-right: 0;
    }
}