// /**
//  * Copyright © Convert, Inc. All rights reserved.
//  *
//  */

//
//  Poster appearance styles
//  _____________________________________________

.pagebuilder-slide-wrapper {
    .pagebuilder-poster-content {
        width: 100%;
    }
}
