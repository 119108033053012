// Fonts
@font-face {
    font-family: 'Convert Sharp';
    src: url('../fonts/convert-skala-regular.eot');
    src: local('Convert Sharp'), local('Convert Sharp'),
        url('../fonts/convert-skala-regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/convert-skala-regular.woff') format('woff'),
        url('../fonts/convert-skala-regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Luma-Icons';
    src: url('../fonts/Luma-Icons.eot');
    src: local('Luma-Icons'), local('Luma-Icons'),
    url('../fonts/Luma-Icons.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Luma-Icons.woff') format('woff'),
    url('../fonts/Luma-Icons.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

// Header
.company-warning {
    display: none;
}

.page-header {

    @include breakpoint(medium down) {
        margin-bottom: 60px;
    }

    box-shadow: 2px 2px 10px rgba($black, 0.1);
    .header {

        // SKAL-252 fix for logo position
        .sticky-header-row {
            position: relative;
        }

        .tab-bar .right-side .minicart-wrapper {
            opacity: 1;
        }
        .action.showcart {
            width: 45px;
            height: 45px;
            background: transparent;

            &:after {
                font-family: 'Convert Sharp';
                content: '\74';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                font-size: 32px;
                color: $black;
                width: 100%;
                line-height: 45px;
                text-align: center;
            }

            .counter {
                top: 0;
                right: -5px;
                bottom: unset;
                line-height: 1.3em;
                z-index: 1;
                border-radius: 10px

            }
        }
        .minisearch .actions button.action {
            width: 45px;
            height: 45px;
            background: $primary-color;
            position: relative;
            margin-right: -4px;

            &:after {
                font-family: 'Convert Sharp';
                content: '\73';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                font-size: 28px;
                color: $white;
                width: 100%;
                line-height: 45px;
                text-align: center;
            }

        }
        .header-row.is-stuck {
            box-shadow: 2px 2px 10px rgba($black, 0.1);
            display: none;
            .logo {
                width: 120px;
                margin-top: -4px;
            }
            .nav-search-wrapper {
                padding-left: 210px;

                .nav-sections {
                    margin-top: -7px;
                }
            }
            .action.showcart {
                margin-left: 12px;
            }
        }

        .header-row:not(.is-stuck) {
            .logo {
                position: absolute;
                top: 0;
                left: 20px;
                width: $ts-headbar-logo-width;
                line-height: normal;
                padding: 0;

                &:before {
                    display: none;
                }

                img {
                    margin-top: 1.75rem;
                }
            }
            .nav-search-wrapper {
                @include grid-col-size(12);
                padding: 30px 20px 0 20px;

                > .panel.wrapper {
                    display: none;
                }
                .top-nav-search-sticky-row {
                    margin: 0 !important;

                    &:after {
                        display: block;
                        content: '';
                        clear: both;
                    }
                    .top-search-grid-wrapper {
                        width: 50%;
                        float: right;
                        margin-top: 0 !important;

                        .top-search-wrapper {
                            display: table;
                            width: 100%;

                            .block-search {
                                display: table-cell;
                                width: 100%;
                                vertical-align: top;
                            }
                            .panel.wrapper {
                                display: table-cell;
                                padding: 0 !important;
                                margin: 0 !important;

                                @include breakpoint (xlarge) {
                                    display: inherit;
                                }

                                .top-links {
                                    padding: 0 !important;
                                    margin: 0 0 0 20px !important;
                                    width: 115px;

                                    li {
                                        display: inline-block;

                                        .item {
                                            display: block;
                                            width: 45px;
                                            height: 45px;
                                            margin-right: 12px;
                                            padding-left: 0;
                                            font-size: 0;
                                            overflow: hidden;

                                            &:after {
                                                font-family: 'Convert Sharp';
                                                content: '\75';
                                                display: block;
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                font-size: 30px;
                                                color: $black;
                                                width: 100%;
                                                line-height: 45px;
                                                text-align: center;
                                            }
                                            &.towishlist {
                                                &:after {
                                                    content: '\71';
                                                }
                                            }

                                            &[href*="logout"] {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                            .minicart-wrapper {
                                display: table-cell;
                            }
                        }
                    }
                }
                .nav-sections {
                    @include grid-col-size(12);
                    float: none;
                    padding-left: 0;
                    position: absolute;
                    top: 90px;

                    @include breakpoint(medium) {
                        margin-left: -10px;
                    }
                    @include breakpoint(xlarge) {
                        margin-left: -16px;
                    }
                    @include breakpoint(xxlarge) {
                        margin-left: -20px;
                    }

                    .navigation {
                        margin-top: 0;

                        > ul > .level0 {
                            &:hover {
                                background-color: $primary-color;
                            }

                            > a {
                                white-space: normal;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
}

.header  .sticky-mm {
    .tab-bar {
        .left-side {
            @include grid-col-size(3);
        }
        .middle-side {
            @include grid-col-size(6);

            .logo img {
                max-height: 30px;
                width: auto;
            }
        }
        .right-side {
            @include grid-col-size(3);
        }
        .mobile-search-icon {
            display: none;
        }
        .mobile-account-icon {
            display: none;
        }
        .nav-toggle {
            background: none;
            width: 40px;
            height: 40px;
            margin-top: 15px;

            &:after {
                font-family: 'Convert Sharp';
                content: '\6d';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                font-size: 24px;
                color: $black;
                width: 100%;
                line-height: 45px;
                text-align: center;
            }
        }
    }
    .mobile-search-bar {
        padding-top: 0;
        display: block !important;

        .minisearch {
            .field.search {
                margin-bottom: 0;
            }
            .control .input-text {
                height: 40px;
            }
            .actions button.action {
                height: 40px;
                width: 45px;
                min-width: 45px;

                &:after {
                    font-size: 24px;
                    line-height: 40px;
                }
            }
        }
    }
}

.header .navigation > .ui-menu > .ui-menu-item.menu-type-complex-vertical {
    &:last-child, &:nth-last-child(2) {
        .submenu .submenu.level1 {
            right: 100%;
            left: auto !important
        }
    }
    position: relative;

    .submenu {
        padding: 0;
        @include breakpoint(xlarge) {
            width: rem-calc(220);
        }

        .ui-menu-item {
            > a {
                font-weight: $header-font-weight;
                font-size: rem-calc(14);
                line-height: 46px;
                height: 50px;

                &:before {
                    left: 0;
                    right: 0;
                }
                &:after {
                    display: none;
                }
            }
            &:hover > a {
                background: $box-color;
                color: $black;

                &:before {
                    border-right-color: $box-color !important;
                }
            }
            .subcategories-over > .parent {
                position: relative;
                &:after {
                    font-family: 'Convert Sharp';
                    content: '\32';
                    display: block;
                    position: absolute;
                    top: 5px;
                    right: 0;
                    left: auto;
                    z-index: 2;
                    font-size: rem-calc(12);
                    color: $gray-dark;
                    width: 45px;
                    line-height: 45px;
                    text-align: center;
                }
            }
        }
    }
}
// Header

// OffCanvas


// SKAL-260

.off-canvas-wrapper {
    overflow: inherit;
}


.off-canvas {
    .close-menu {
        position: absolute;
        top: 15px;
        right: 30px;

        span {
            display: none;
        }

        &:after {
            font-family: 'Convert Sharp';
            content: '\78';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            font-size: rem-calc(19);
            color: $black;
            width: 45px;
            line-height: 45px;
            text-align: center;
        }

        .txt {
            display: none;
        }
    }
    .offcanvas-title {
        text-align: center;
    }
    .navigation > ul.menu {
        > li > a:after {
            font-family: 'Convert Sharp';
            content: '\32';
            top: 50%;
            right: 5px;
            font-size: rem-calc(12);
            color: rgba($black, .75);
            width: 45px;
            height: 45px;
            line-height: 45px;
            text-align: center;
            background: none;
        }
        ul li a {
            background: none;

            &:before {
                font-family: 'Convert Sharp';
                content: '\32';
                position: relative;
                transform: translateY(-50%);
                left: -12px;
                font-size: rem-calc(12);
                color: rgba($black, .75);
                width: 12px;
                height: 12px;
                line-height: 12px;
                text-align: center;
            }
        }
    }
}

//Page title

.page-title-wrapper:before {
    display: none !important;
    padding-bottom: 0 !important;
}

//Breadcrumbs

.breadcrumbs .items li:not(:last-child)::after {
    color: rgba($black, .25);
}

//Product page


.page-product-configurable {
    .table-wrapper {
        overflow-x: auto;
        overflow-y: hidden;
    }
}

.product-info-main {
    .page-title-wrapper {
        &.product{
            .unit-storage {
                @include grid-col;
                color:$ts-product-detail-price-color;
                font-size: rem-calc(14);
                .value{
                    display: inline-block;
                }
            }
        }
        .attribute.sku {
            @include element-invisible-off;
            font-size: rem-calc(14);
            color: $ts-product-item-article-number-color;
            margin-bottom: 1rem;
            padding-right: 1.25rem;
            padding-left: 1.25rem;

            @include breakpoint(large down) {
                margin-top: -3.5rem;
            }

            strong {
                font-weight: 400;
            }
            .value {
                display: inline-block;
            }
        }
    }
    .page-title {
        margin-bottom: .5rem !important;

        @include breakpoint(large down) {
            margin-bottom: 4rem !important;
        }
    }

    .product-info-stock-sku {
        float: none !important;
        padding: 0 !important;
        width: 100% !important;
        margin-bottom: 20px !important;

        .stock {
            width: 100% !important;
            padding-right: 0;
            float: none !important;

            span {
                padding: 0;
            }

            &.available span {
                background: transparent;
                position: relative;
            }
        }
    }
    .old-price {
        display: none;
        font-size: rem-calc(16) !important;
        .price-label {
            display: none;
        }
    }
    .product.info.detailed .product-data .product_info_overview {
        background: transparent;
        border-bottom: 1px solid $ts-main-border-color;
        font-family: $body-font-family;

        p {
            font-family: $body-font-family;
        }
    }
    .product.overview {
        margin-bottom: 2rem;
        font-size: rem-calc(18);
/*      max-height: 110px;
        overflow: hidden;
        position: relative;

        &:after {
            content: '';
            width: 100%;
            height: 50px;
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,1) 100%);
        }
*/
        p {
            line-height: 1.6;
        }
    }
    .product.info.detailed {
        display:  none;
    }
    .product-content .description {
        font-size: rem-calc(18);
        margin-bottom: 1.25rem;

        p {
            line-height: 1.6;
        }
    }
    .product-info-form-bottom:after {
        content: '';
        clear: both;
        display: block;
    }
    .product-info-sidebar {
        .product-options-wrapper {
            @include breakpoint(large down) {
                padding-right: 1.25rem;
                padding-left: 1.25rem;
            }
            .options-table {
                tbody td {
                    padding: 10px 10px;
                }
                .table-caption {
                    text-align:  left;
                }
                .variant {
                    label {
                        font-size: rem-calc(19);
                        font-family: $header-font-family;
                        font-weight: $header-font-weight;
                    }
                }
                .price {
                    font-size: rem-calc(19);
                    color: $black;
                    text-align: right;
                    vertical-align: middle;
                    * {
                        font-size: rem-calc(19) !important;
                    }
                    @include breakpoint(large down) {
                        display: none;
                    }
                }
                .sku {
                    font-size: rem-calc(14);
                }
                .field.qty {
                    display: inline-block;
                    white-space: nowrap;
                    display: table-cell;

                    @include breakpoint(large down) {
                        display: block;
                    }

                    &:after {
                        font-size: 1.5rem;
                        color: rgba($black, .25);
                        line-height: 3rem;
                    }
                    .control.qty {
                        background-color: $box-color;
                        padding: 4px 5px;
                        border-radius: 26px;
                        height: 52px;

                        button[type=button] {
                            width: 44px;
                            height: 44px;
                            border-radius: 22px;
                            background-color: $white;
                            display: inline-block;
                            cursor: pointer;
                            position: relative;
                            color: $white;
                            vertical-align: top;

                            &:after {
                                font-family: 'Convert Sharp';
                                content: '\74';
                                display: block;
                                position: absolute;
                                top: 0;
                                left: 0;
                                font-size: 18px;
                                color: #54565b;
                                width: 100%;
                                line-height: 44px;
                                text-align: center;
                            }
                            &.decreaseQty:after {
                                content: '\2d';
                            }
                            &.increaseQty:after {
                                content: '\2b';
                            }
                        }
                    }
                    .input-text {
                        display: inline-block;
                        background-color: transparent;
                        border-color: transparent;
                        height: 2.6rem;
                        width: 3.125rem;
                        padding-left: .25rem;
                        padding-right: .25rem;
                        margin: 0;
                        text-align: center;
                        appearance: none;

                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }
                }
            }
        }
        @include breakpoint(medium) {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .field.configurable {
            width: 200px;
            float: left;
        }
        .box-tocart {
            position: static;
            float: left !important;
            @include grid-col-size(12);

            .fieldset {
                display: table;

                 .page-product-configurable & {
                     @include breakpoint(large down) {
                         display: table;
                     }
                 }

                @include breakpoint(large down) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }

                .field {
                    display: table-cell;
                    min-width: rem-calc(65);
                    padding-right: 1rem;
                    vertical-align: top;

                    input {
                        padding-left: rem-calc(5);
                        padding-right: rem-calc(5);
                        text-align: center;
                        height: rem-calc(50);

                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }
                }
                .actions {
                    display: table-cell;
                    width: 100%;
                    vertical-align: top;

                    @include breakpoint(large down) {
                        margin-top: 20px;
                    }

                    .action {
                        position: relative;

                        &:before {
                            font-family: 'Convert Sharp';
                            content: '\74';
                            display: block;
                            position: absolute;
                            top: 10px;
                            left: 14px;
                            font-size: 24px;
                            color: $white;
                            width: 24px;
                            line-height: 24px;
                            text-align: center;
                        }
                    }
                }
            }
        }

        .product-info-form-wrapper {
            padding: 0;

            .product-info-form-row {
                &:after {
                    display: block;
                    content: '';
                    clear: both;
                }
                .open-size-guide-modal {
                    display: none;
                }
            }
        }
    }
    .product-detail-accordion {
        border-bottom: $table-border;

        li {
            padding: 0;

            &:after {
                display: none;
            }
            .accordion-title {
                border: none;
                border-top: $table-border;
                color: $gray-dark;
                text-decoration: none;
                padding: 1rem 0 1.2rem 0;

                &:hover, &:focus {
                    background-color: transparent;
                }

                h3 {
                    font-size: rem-calc(17);
                    font-weight: $global-weight-normal;
                    margin-bottom: 0;
                }
                &:before {
                    font-family: 'Convert Sharp';
                    content: '\2b';
                }
            }
            &.is-active > .accordion-title::before {
                content: '\2d';
            }
            .accordion-content {
                border: none;
                font-size: rem-calc(14);
                padding: 0 2rem .5rem 2rem;

                .product-attributes-list {
                    margin-bottom: .5rem;

                    li {
                        padding: .3rem 0;
                        border-top: 1px solid rgba($black,.07);
                        display: table;
                        width: 100%;

                        > span {
                            display: table-cell;

                            &:first-child {
                                width: 40%;
                                font-weight: $header-font-weight;
                            }
                            &:last-child {
                                width: 60%;
                                opacity: .8;
                            }
                        }
                    }
                }
            }
        }
    }
}
.actions-secondary {
    @include breakpoint(large) {
        display: block !important;
    }

    .action.towishlist {
        margin-right: 12px;

        &:after {
            display: none;
        }
    }
}
.product-social-links {
    position: static !important;
    transform: none !important;
    padding-top: 0 !important;
}
.add-to-links {
    float: right;
    display: inline;
}
.cart-actions .action-towishlist {
    display: none !important;
}
.product-item-actions .actions-secondary .action.towishlist {
    display: block;
}
.action.towishlist {
    position: relative;
    width: 49px !important;
    height: 49px;
    border-radius: 50%;
    overflow: hidden;
    padding: 0;
    margin: -3px 1rem 0 0;
    background: $white !important;
    box-shadow: 0px 4px 6px rgba($black, 0.1);

    span {
        display: none;
    }

    &:before {
        font-family: 'Convert Sharp';
        content: '\71';
        display: block;
        position: absolute;
        top: 2px;
        left: 1px;
        font-size: 24px;
        color: $black;
        width: 49px;
        line-height: 49px;
        text-align: center;
    }
}

.price-revert-wrapper {
    display: inline-block;
    float: right;
    text-align: end;
}

.product-info-wrapper .product.media {
    margin-bottom: 0;

    .convert__fotorama__view-larger {
        display: none !important;
    }
    .fotorama__shadows--right:after {
        display: none;
    }
    .fotorama__nav-wrap--horizontal {
        .fotorama__thumb__arr--left,
        .fotorama__thumb__arr--right {
            .fotorama__thumb--icon {
                background-color: transparent;
                background-image: url(../images/icon-big-next-arrow.svg);
            }
        }
    }
}

// Product list
.catalog-category-view {

    .item.product.product-item {

        .product-item-details {

            .price-box {

                .old-price {
                    display: none;
                }
            }
        }
    }
}

.toolbar-sorter {
    .sorter-label {
        display: none;
    }
    select.sorter-options {
        line-height: 1.5rem;
    }
}
.convert-product-category-list {
    .product-item-details {
        .product-item-sku {
            color: $ts-product-item-article-number-color;
            margin: 5px 0 7px;
        }
    }

    @include breakpoint(small down) {
        margin: 0 -17px;
    }
}
.product-items .product-item,
.product-items .ais-Hits-item,
.product-items .ais-infinite-hits-item,
.ais-infinite-hits .product-item,
.ais-infinite-hits .ais-Hits-item,
.ais-infinite-hits .ais-infinite-hits-item {
    @include breakpoint(small down) {
        padding-left: 3px;
        padding-right: 3px;
    }

    .product-item-details {
        flex-direction: row;
        flex-wrap: wrap;

        @include breakpoint(small down) {
            padding-left: 10px;
            padding-right: 10px;
        }

        .product-item-actions button.action {
            opacity: 1 !important;
        }
        .product-item-name {
            width: 100%;
            margin-bottom: 10px;

            @include breakpoint(small down) {
                margin-top: 15px;
            }
            @include breakpoint(medium down) {
                font-weight: 400;
            }
        }
        .product-price-stock {
            width: auto;
            margin-right: auto;
            margin-top: 10px;
            align-items: flex-start;
            margin-bottom: 0;
        }
        .product-item-inner {
            align-items: flex-start;
            @include element-invisible-off;
            width: auto;
            margin-top: 0;
            margin-left: auto;

            .product-item-actions {
                @include element-invisible-off;
                display: flex;
                flex-direction: row-reverse;

                button.action {
                    position: relative;
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    overflow: hidden;
                    padding: 0;
                    float: right;
                    background-color: $white;
                    box-shadow: 0px 4px 6px rgba($black, 0.1);

                    &:before {
                        font-family: 'Convert Sharp';
                        content: '\74';
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        font-size: 24px;
                        color: $black;
                        width: 48px;
                        line-height: 48px;
                        text-align: center;
                    }
                }
            }
        }
    }
}
.convert-label-container {
    position: absolute;
    top: 10px;
    right: 10px;
    text-align: center;
    z-index: 1;

    .label-discount{
        background-color: $primary-color;
        color: $white;
        width: 68px;
        height: 68px;
        line-height: 68px;
        border-radius: 34px;
        font-size: rem-calc(22);
        font-family: $header-font-family;
        font-weight:  $header-font-weight;
    }
}
.product.media {
    .convert-label-container {
        right: 30px;
    }
}

// Filters

.has-ui-slider {
    padding-bottom: 4.5rem;

    .ui-slider {
        .ui-slider-handle {
            border: 9px solid $white !important;
            box-shadow: 2px 2px 5px rgba($black, 0.5);
            top: -20px !important;
        }
    }
}
.filter-options-content.has-dropdown {
    .items {
        .item {
            border-top: 1px solid rgba($black, .15);

            @include breakpoint(small down) {
                &:first-child {
                    border-top: none;
                }
            }

            a {
                display: block;
                font-size: rem-calc(17);
                color: $body-font-color;
                padding: 12px 10px 14px;
                background: $white;
                transition: background-color .25s ease-out;

                &:hover,
                &:focus {
                    background-color: $box-color;
                    color: $body-font-color;
                }
                &:after {
                    display: block;
                    font-family: 'Convert Sharp';
                    content: '\32';
                    color: rgba($black, .7);
                    font-size: rem-calc(14);
                    line-height: rem-calc(26);
                    float: right;
                    width: 14px;
                    height: 26px;
                }
            }
        }
    }
}

.filter-options-content.has-labels,
.filter-options-content.has-text-swatch {

    .items {
        .item {
            margin-bottom: .5rem;

            a {
                position: relative;
                padding: $ts-sidebar-main-filter-anchor-padding;
                cursor: pointer;

                &:before {
                    display: block;
                    content: '';
                    width: 18px;
                    height: 18px;
                    position: absolute;
                    top: 6px;
                    left: 0;
                    background-color: $box-color;
                    border-radius: 0;

                    @include breakpoint(large) {
                        top: 10px;
                    }
                    box-shadow: inset 0 0 0 1px rgba($black, 0.15);
                }
                .label {
                    color: $ts-sidebar-main-filter-anchor-color;
                    font-weight: 400 !important;
                    padding-left: 25px !important;
                }
                .count {
                    display: none;
                }
                &:hover,
                &:focus,
                &.am_shopby_link_selected {
                    &:after {
                        display: block;
                        font-family: 'Convert Sharp';
                        content: '\63';
                        color: $white;
                        font-size: rem-calc(14);
                        line-height: rem-calc(14);
                        text-align: center;
                        position: absolute;
                        top: 7px;
                        left: 1px;
                        width: 16px;
                        height: 16px;
                        background: #54565B;
                        border-radius: 0;

                        @include breakpoint(large) {
                            top: 11px;
                        }
                    }
                    .label {
                        text-decoration: none;
                    }
                }
            }
        }
        &.am-filter-items-attr_size {
            .item {
                display: inline-block;
                margin-right: 8px;

                a {
                    display: inline-block;
                    background-color: $box-color;
                    padding: 8px 10px 10px 10px;
                    border-radius: 25px;
                    font-size: rem-calc(15);
                    min-width: 45px;
                    min-height: 45px;
                    text-align: center;
                    transition: all .25s ease-out;

                    .label {
                        padding: 0 !important;
                        font-family: $header-font-family;
                        font-weight: $header-font-weight;
                        cursor: pointer;
                    }
                    &:hover,
                    &:focus,
                    &.am_shopby_link_selected {
                        background-color: #4f4f4f;
                        .label {
                            color: $white;
                        }
                    }

                    &:before, &:after {
                        display: none;
                    }
                }
            }
        }
    }
}

.modal-popup.filters {
    .modal-inner-wrap {
        @include breakpoint(small down) {
            margin: 0;
        }
        .modal-header {
            background-color: $white;
            text-align: center;
            margin-bottom: 2rem;
            padding: 1.25rem;
            position: relative;
            border-bottom: 1px solid rgba($black, 0.15);

            .modal-title {
                font-family: $header-font-family;
                font-weight: $header-font-weight;
                font-size: rem-calc(22);
            }
            .action-close {
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 61px;
                    height: 63px;
                    background-image: url(#{$ts-path-to-images}/icon-close.svg);
                    background-size: 15px auto;
                    background-repeat: no-repeat;
                    background-position: 17px 50%;
                    padding: 0;
                }
            }

            .modal-sub-title,
            .close-filter-modal {
                display: none;
            }
        }
        .filter-options-title {
            font-size: rem-calc(18);
            padding-bottom: .75rem;
        }
        .filter-options-content {
            padding-bottom: 1rem;

            &.has-ui-slider {
                padding-bottom: 2.5rem !important;
            }
            a {
                font-size: rem-calc(16) !important;

                .label {
                    margin-top: -7px;
                }
            }
            .am-filter-items-attr_age_category,
            .am-filter-items-attr_popular_with {
                a {
                    background-color: #fbeecc !important;
                    border-radius: 8px !important;
                    padding: 9px 10px 6px 10px !important;
                    transition: all .25s ease-out;

                    &.am_shopby_link_selected {
                        background-color: $secondary-color !important;
                        box-shadow: inset 2px 2px 3px rgba($black, 0.15);
                    }
                }

            }
        }
        .close-filter-modal {
            background-color: $primary-color !important;
        }
        .modal-footer {
            background-color: $white;
            margin-top: 10px;

            button {
                background-color: $primary-color !important;
            }
        }
    }
}
.mobile-filter-btn {
    &.open-filter-modal {
        width: 49%;
        background-color: $secondary-color;
    }
    &.open-sorting-modal {
        width: 49%;
        background-color: transparent;
        color: $secondary-color;
        border: 1px solid $secondary-color;
    }
}

//Login
.form-login .captcha .field {
    width: 100% !important;
    padding: 0 !important;

    .captcha-image .captcha-reload {
        margin-top: .5rem;
    }
}
.block-new-company {
    .block-content {
        padding: map-get($ts-login-section-padding, small);
        background: $ts-login-section-bg;
        box-shadow: $ts-login-section-box-shadow;

        @include breakpoint(xlarge) {
            padding: map-get($ts-login-section-padding, xlarge);
        }
    }
}

//Register

.form-create-account {
    max-width: 600px;
    margin: 0 auto;
    padding: map-get($ts-login-section-padding, small);
    background: $ts-login-section-bg;
    box-shadow: $ts-login-section-box-shadow;

    @include breakpoint(xlarge) {
        padding: map-get($ts-login-section-padding, xlarge);
    }
    .fieldset {
        margin-bottom: 1.5rem;

        .legend {
            font-family: $header-font-family;
            font-weight: $header-font-weight;
            font-size: rem-calc(24);
        }
        .field-over {
            margin: 0 !important;
        }
    }
}

.company-account-index {
    .column.main > .block {
        padding:  1.5rem;
        margin-bottom: 1.5rem;
        background-color: $box-color;

        .block-title {
            font-size: rem-calc(22);
            margin-bottom: 1rem;
        }
        address {
            font-style: normal;
        }
    }
}

// My account

.title-actions-links {
    margin-top: 1rem;
}
.gdpr-customer-settings {
    .block-title {
        margin-bottom: 1rem !important;
    }
    .amgdpr-block-content {
        padding-bottom: 2rem;
        border-bottom: 1px solid rgba($black, 0.2);
        margin-bottom: 1.5rem;
    }
}
.customer-address-index .actions-toolbar {
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba($black, 0.2);
    margin-bottom: 1.5rem;
}
.account .actions-toolbar .action {
    margin: 0 10px 10px 0;
}

//Checkout

.checkout-back-to-cart-link { // SKAL-261
    color: $body-font-color;
    display: inline-block;
    font-size: $ts-checkout-back-to-cart-font-size;
    margin-bottom: 15px;
    padding: 25px 33px;
    position: relative;
    text-decoration: none;

    &::before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        margin: auto;
        position: absolute;
        top: 0;
        left: 5px;
        bottom: 0;
        border-bottom: $ts-checkout-back-to-cart-icon-border;
        border-left: $ts-checkout-back-to-cart-icon-border;
        transform: rotate(45deg);

    }
}

// SKAL-260

.checkout-wrapper {
    .checkout-payment-method {
        position: sticky;
        top: 0;
    }

    .step-title {
        margin-bottom: 20px;
    }

    .total-products {
        font-size: $ts-checkout-total-products-font-size;
        display: inline-block;
        width: 100%;
    }

    .total-products-label {
        padding: $ts-checkout-products-number-padding;
    }

    .total-products-value {
        float: right;
        padding: $ts-checkout-products-number-padding;
    }

    .order-total-incl-tax {
        line-height: 1.27778;
    }

    .txt {
        margin-bottom: 25px;
    }
}

.shipping-address-items .shipping-address-item.selected-item {
    background-color:rgba($black, .05);
    padding: 20px;
    border-top: none;
}
.checkout-shipping-method {
    display: none !important;
}

.checkout-form-row.shipping-postcode-city {
    margin-bottom: 0;

    .field.field-postcode {
        @include grid-col-size(12);
        padding-right: rem-calc(20);
    }
    .field.field-city {
        @include grid-col-size(12);
    }
    &.shipping-postcode-city .mage-error {
        order: initial;
    }
}
.authentication-wrapper {
    right: 2rem;
    left: auto;
    top: 4.5rem;
    text-align: right;

    @include breakpoint(medium) {
        top: 2rem;
    }
    @include breakpoint(large) {
        left: 0;
        right: auto;
        top: -2rem;
        text-align: left;
    }
    @include breakpoint(xlarge) {
        left: 4rem;
        top: 2rem;
    }
}
.address-label {
    font-size: rem-calc(18) !important;
    font-family: $header-font-family;
    font-weight: $header-font-weight;
}
.b-page-checkout .header .tab-bar .nav-toggle {
    display: inline-block;
}

.b-page-checkout {
    &.checkout-index-index {
        .page-main {
            margin-top: 0;
        }
    }
}

//Checkout cart
.checkout-cart-index .page-title {
    font-size: rem-calc(36);
}
table.cart {
    border-top: 1px solid rgba($black, .15);
    border-bottom: 1px solid rgba($black, .15);

    tbody.cart.item {
        border-bottom: 1px solid rgba($black, .15);

        .convert-label-container {
            display: none;
        }
        .item-options {
            font-size: 1rem;
            color: $black;
        }
        .product-item-name {
            margin-bottom: 0;
        }
        .cart-item-stock-status {
            display: none;
        }
        .cart-price {
            line-height: 56px;
        }
        .field.qty {
            &:after {
                font-size: 1.5rem;
                color: rgba($black, .25);
                line-height: 3rem;
            }
            .control.qty {
                background-color: $box-color;
                padding: 4px 5px;
                border-radius: 26px;
                height: 52px;

                button[type=button] {
                    width: 44px;
                    height: 44px;
                    border-radius: 22px;
                    background-color: $white;
                    display: inline-block;
                    cursor: pointer;
                    position: relative;
                    color: $white;
                    vertical-align: top;

                    &:after {
                        font-family: 'Convert Sharp';
                        content: '\74';
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        font-size: 18px;
                        color: #54565b;
                        width: 100%;
                        line-height: 44px;
                        text-align: center;
                    }
                    &.decreaseQty:after {
                        content: '\2d';
                    }
                    &.increaseQty:after {
                        content: '\2b';
                    }
                }
            }
            .input-text {
                display: inline-block;
                background-color: transparent;
                border-color: transparent;
                height: 2.6rem;
            }
            .action.update {
                display: none !important;
            }
        }
    }
    td, th {
        padding-top: 0;
        padding-bottom: 0;

        &.item-image {
            padding-left: 0;

            .product-image-container {
                text-align: center;

                .product-image-photo {
                    width: auto;
                }
            }
        }
    }
}
.cart-summary {
    table {
        td.amount {
            width: auto;
        }
        .totals.grand {
            padding-top: 0;
            border: none;

            th, td {
                padding-top: 0;
            }
        }
    }
    .block {
        .title strong {
            text-decoration: underline;
            border: none;
        }
        .content {
            .legend {
                font-family: $header-font-family;
                font-weight: $header-font-weight;
                font-size: rem-calc(17);
            }
            .label {
                margin-bottom: .625rem;
            }
        }
    }
}

.block-requisition-list {
    display: none;
}

// Chackout - Success page

.checkout-success {
    padding: 1.5rem;
    background-color: $box-color;
    max-width: 600px;
    margin: 0 auto;

    p {
        &:first-child {
            font-size: rem-calc(22);
            font-weight: $header-font-weight;
        }
    }
}

//Related products
.block-products-list {
    .block-title {
        font-size: rem-calc(26);
        font-family: $header-font-family;
        font-weight: $header-font-weight;
        margin-bottom: 1rem;
    }
    .product-item {
        .product-item-photo {
            position: relative !important;
            width: 100%;

            img:not(.image-label) {
                position: absolute;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                top: 50%;
                transform: translateY(-50%);
                max-width: 100%;
                max-height: 100%;
            }
            .image-label {
                position: absolute;
                top: 0;
                right: 0;
            }

            &:before {
                content: "";
                display: block;
                padding-bottom: 100%;
              }

            &:after {
                display: block;
                content: '';
                z-index: 1;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 1;
                background: rgba(0, 0, 0, 0.03);
            }
        }
        .price-box {
            font-size: rem-calc(21);
            font-family: $header-font-family;
            font-weight: $header-font-weight;
            font-weight: $ts-product-item-product-price-font-weight;
            color: $primary-color;
            width: auto;
            margin-top: 10px;
            align-items: flex-start;
        }
    }
}

//Widget products carousel
.widget-product-carousel {
    .product-item {
        .product-item-photo {

            .image-label {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
        .price-box {
            font-size: rem-calc(21);
            font-family: $header-font-family;
            font-weight: $header-font-weight;
            font-weight: $ts-product-item-product-price-font-weight;
            color: $primary-color;
            width: 35%;
            margin-top: 10px;
            align-items: flex-start;
        }
        .tocompare {
            display: none;
        }
    }
}

//GDPR and cookie policy
.amgdpr-modal-container {
    h1 {
        font-size: rem-calc(22);
    }
    h4 {
        font-size: rem-calc(18);
    }
    .amgdpr-privacy-policy > div > div {
        padding: 0 !important;
    }
    .modal-footer {
        padding-top: 0;
    }
}
.amgdprcookie-groups-modal {
    .modal-footer {
        padding-top: 0;
    }
    .amgdprcookie-buttons-block {
        padding-bottom: rem-calc(40);
    }
}
@include breakpoint(small down) {
    .amgdprcookie-table {
        thead {
            th {
                font-size: rem-calc(12);

                &:nth-child(4),
                &:nth-child(5) {
                    display: none;
                }
            }
        }
        tbody {
            td {
                font-size: rem-calc(12);

                &:nth-child(4),
                &:nth-child(5) {
                    display: none;
                }
            }
        }
    }
}
.amgdprcookie-done {
    padding: 0.82em 2em 0.87em;
    border: 1px solid transparent;
    font-size: rem-calc(15);
    line-height: 1;
    text-align: center;
    cursor: pointer;
    background-color: $primary-color;
    color: $white;
    font-family: "Din Medium", Arial, sans-serif;
    font-weight: 500;
}
.amgdprcookie-form-container {
    .amgdprcookie-cookie-container {
        border-radius: 0;

        .amgdprcookie-text {
            font-size: rem-calc(15);
        }
        .amgdprcookie-options {
            background-color: $primary-color;
            border-radius: 0;
            padding-right: 35px;
            font-size: rem-calc(15);
            font-family: $header-font-family;
            font-weight: $header-font-weight;
            font-weight: 500;
            display: inline-block;

            &:hover {
                color: $white;
            }
        }
        .amgdprcookie-toggle-cookie .amgdprcookie-input+.amgdprcookie-label {
            margin-right: 0;
        }
        .amgdprcookie-toggle-cookie .amgdprcookie-input:checked:not([disabled])+.amgdprcookie-label {
            background-color: $primary-color;
            margin-right: 0;
        }
    }
}
.amgdprcookie-policy-container .amgdprcookie-buttons-block .amgdprcookie-button {
    background-color: $primary-color;
    border-radius: 0;
    font-size: rem-calc(15);
    font-family: $header-font-family;
    font-weight: $header-font-weight;
    font-weight: 500;
    text-transform: none;
    padding-top: 10px;
    padding-bottom: 10px;
    flex-basis: 48% !important;
    margin-left: 0 !important;
    padding-left: 0;
    letter-spacing: normal;
}
.amgdprcookie-groups-container {
    .amgdprcookie-cookie-container {
        .amgdprcookie-header {
            padding-right: 0;
        }
        .amgdprcookie-link {
            color: $primary-color;
            font-size: rem-calc(15);
            cursor: pointer;
            padding-top: 10px;
        }
        .amgdprcookie-caption {
            font-weight: normal;
        }
        .amgdprcookie-toggle-cookie .amgdprcookie-input+.amgdprcookie-label {
            margin-right: 0;
        }
        .amgdprcookie-toggle-cookie .amgdprcookie-input:checked:not([disabled])+.amgdprcookie-label {
            background-color: $primary-color;
            margin-right: 0;
        }
        .amgdprcookie-text {
            font-size: rem-calc(15);
        }
    }
}
.cms-privacy-policy-cookie-restriction-mode .message.info {
    margin-bottom: 40px;
}
//Footer
footer.page-footer {
    padding-bottom: 20px;
    font-size: $ts-footer-font-size;

    .footer-content {
        padding-bottom: 30px;

        .footer-list  {
            .footer-block.contact {
                clear: none;
            }
        }

        .footer-list {
            &:before {
                content: '';
                display: block;
                height: 45px;
                border: 0 solid transparent;
                border-width: 0 1.25rem 0 1.25rem;
                margin: 2rem 0 2.5rem 0;
                background: url(#{$ts-path-to-images}/logo-negative.svg) no-repeat;
                background-size: auto 45px;
                float: left;

                @include grid-col-size(12);

                @include breakpoint(xlarge) {
                    width: 20%;
                    margin: 0;
                }
            }

            .footer-block {
                @include breakpoint(xlarge) {
                    width: 20% !important;
                }
                h3 {
                    font-size: $ts-footer-header-font-size;
                }
                &.contact {
                    div {
                        padding: 0 !important;
                        margin: 0 !important;
                    }
                    p {
                        line-height: rem-calc(25);
                        font-size: $ts-footer-font-size;

                        strong {
                            font-family: $header-font-family;
                            font-weight: $header-font-weight;
                        }
                    }
                }
            }
        }
    }
    .footer-bottom {
        .footer-block {
            &.copyright {
                @include grid-col-size(12);

                p {
                    font-size: rem-calc(13) !important;
                    color: rgba($white, .5) !important;

                    @include breakpoint(large) {
                        text-align: center;
                    }
                }
            }
        }
    }
}
//SKAL-441
.price-request {
    span {
        font-size:12px;
    }
}
.catalog-product-view {
    .box-contact-us {
        text-align:right;
    }
}

//SKAL-441 Price on request
.product-item-inner {
    .actions-contact-us {
        a {
            font-size: 15px;
            font-weight: $header-font-weight;
        }
    }
}

.price-request {
    padding-right: rem-calc(20);

    span {
        font-size: 15px;
        font-weight: $header-font-weight;
        color: $primary-color;
    }
}

.product-info-form-row {
    margin: 0 !important;

    .box-tocart.product-options-wrapper {
        padding: 0;
    }

    .box-contact-us a {
        width: 100%;
    }
}

//Log in to see prices button styling
//SKAL-495
.product-items .button.login_button,
.block-products-list .button.login_button {
    background: none;
    font-size: 14px;
    color: $secondary-color;
    padding: 0;
    white-space: nowrap;
    margin: 0;

    @include breakpoint (xxlarge) {
        font-size: $body-font-size;
    }
}

.product-info-main .login_button {
    width: 100%;
    margin-bottom: 40px;
}

.hide-price-enabled .product-info-main .price-revert-wrapper {
    @include breakpoint (large down) {
        display: none !important;
    }
}

.product-items {
    button.tocart.primary.disabled-btn,
    button.tocart.primary[disabled] {
        background: $box-color !important;
        box-shadow: none !important;
        font-size: 0;
        cursor: default;

        &:before {
            content: '\74' !important;
            color: #B2B1B1 !important;
        }
    }

    .action.towishlist.disabled {
        margin-right: 8px;
        background: $box-color !important;
        box-shadow: none;
        cursor: default !important;

        &:before {
            content: '\71';
            color: #B2B1B1;
        }
    }

    .amasty-hide-price-container {
        height: 39px;
    }
}

//Slick slider styling
.slick-slider .product-item-details {
    padding: 0 !important;

    .product-price-stock {
        margin-top: 0 !important;
    }
}
