$sales-order-view-link__color: #d8282f;

.sales-order-view {
    .action.block.block-requisition-list {
        margin-bottom: 0;
        .requisition-list-action {
            background-color: unset;
            padding: 0;
            color: $sales-order-view-link__color;
            margin: 0;

            .requisition-list-button {
                margin-bottom: 5px;
                font-weight: $ts-stock-label-font-weight;
            }

            .items[aria-hidden="true"] {
                display: none;
            }
        }
    }
}
