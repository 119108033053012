//
// Variables
//
$create-wishlist-block__width: 75%;
$create-wishlist-block__box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);

//
//  Common
//  ----------------------------------------------

#create-wishlist-block {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: $create-wishlist-block__width;
    margin: 80px auto;
    display: flex;
    flex-direction: column;
    background-color: $white;
    box-shadow: $create-wishlist-block__box-shadow;
    opacity: 1;
    pointer-events: auto;
    padding: 48px;

    .popup-header {
        padding: 20px 0 10px;
    }

    .fieldset {
        .actions-toolbar {
            display: flex;

            .primary {
                padding-right: 20px;
            }
        }
    }
}
