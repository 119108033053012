// /**
//  * Copyright © Convert, Inc. All rights reserved.
//  *
//  */

//
//  Full Bleed row appearance styles
//  ________________________________

[data-content-type="row"][data-appearance="full-bleed"] {
    box-sizing: border-box;
}

//
//  Responsive Classes
//  _____________________________________________

@include breakpoint ($pb-responsive-stacking) {
    [data-content-type="row"][data-appearance="full-bleed"] {
        background-attachment: scroll !important;
    }
}