// /**
//  * Copyright © Convert, Inc. All rights reserved.
//  *
//  */

//
//  Collage left appearance styles
//  _____________________________________________

[data-appearance="collage-left"] {
    .pagebuilder-banner-wrapper {
        .pagebuilder-overlay {
            margin-right: auto;
        }
    }
}