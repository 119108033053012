
// Mixins
@import "mixins/media-queries";

// Vendor libs
@import 'vendor/normalize/lib'; // Normalize.css
@import 'vendor/magento-ui/lib'; // Magento UI

// Theme variables
@import 'variables';

@import url("https://use.typekit.net/vcf5azm.css");
@import "../../../vendor/convert/theme-frontend-two/styles/settings.scss";
@import "settings";
@import "../../../vendor/convert/theme-frontend-two/styles/app.scss";
@import "theme-settings";
@import "../../../vendor/convert/theme-frontend-two/styles/theme-settings.scss";
@import "../../../vendor/convert/theme-frontend-two/styles/imports.scss";
@include foundation-accordion;


@import 'blocks/extends';

// Included modules with separated styles
@import "../Convert_Cookies/styles/module";
@import "../Convert_CustomMenu/styles/module";
@import "../Convert_Links/styles/module";
@import "../Convert_Social/styles/module";
@import "../Convert_ShippingBar/styles/module";
@import "../Convert_Usp/styles/module";
@import "../Convert_LayeredNavigation/styles/module";

@import '../Magento_MultipleWishlist/styles/module';

@import "../Bss_AjaxCart/styles/module";
@import "../Strategery_Infinitescroll/styles/module";

@import "../Convert_PageBuilder/styles/module";

@import "skala";
@import "algolia-search";

@import "pages/wishlist.scss";
@import "pages/catalog-category-view.scss";
@import "pages/catalog-product-view.scss";
@import "pages/checkout-cart-index.scss";
@import "pages/sales-order-view.scss";
@import "pages/page-product-configurable.scss";

@import "components/create-new-wishlist.scss";
@import "components/_split-wishlist-dropdown.scss";
@import "components/product/_product-fotorama-gallery.scss";
@import "components/custom-stock-modal";




