// Styles for Algolia quick search SKAL-256
.algolia-instant-results-wrapper {
    .ais-Hits-list {
        align-content: flex-start;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .ais-Hits-item {
        box-shadow: 0 0 10px rgba($black, 0);
        margin: 0 -0.625rem;
        transition: $thumbnail-transition;
        width: 50%;

        @include breakpoint(medium) {
            padding: 0.625rem;
        }

        .product-item-link {
            color: $black;
            text-decoration: none;
        }

        &:hover {
            box-shadow: 0 0 10px rgba($black, .1);
            transition: $thumbnail-transition
        }

        @include breakpoint(medium) {
            width: 33.333%;
        }

        .product-image-wrapper {
            background: rgba($black, .03);
            display: block;
            height: 100%;
            text-align: center;

            .product-image-photo {
                object-fit: contain;
            }
        }

        .product-item-photo {
            display: block;
        }

        .product-image-photo {
            height: 100%;
            width: auto;
        }

        .product-item-details {
            align-content: space-between;
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .tocart {
            background-color: $white;
            border-radius: 50%;
            box-shadow: 0px 4px 6px rgba($black, .1);
            float: right;
            font-size: 0;
            height: 48px;
            overflow: hidden;
            padding: 0;
            position: relative;
            width: 48px;

            &:hover,
            &:focus,
            &:active {
                background-color: $white;
            }

            &::before {
                content: '\74';
                color: $black;
                display: block;
                font-family: 'Convert Sharp';
                font-size: 24px;
                left: 0;
                line-height: 48px;
                position: absolute;
                text-align: center;
                top: 0;
                width: 48px;
            }
        }
    }

    mark {
        background: none;
    }

    .product-item-info {
        display: flex;
        flex-direction: column;
        text-align: left;
    }



    .product-price-actions-wrapper {
        align-items: center;
        justify-content: space-between;
        display: flex;

        .product-price-stock {
            display: none;
        }

        .price-title {
            color: #000000;
        }

        .price {
            color: #000000;
            font-size: rem-calc(21);
        }
    }

    .ais-Pagination {
        .ais-Pagination-list {
            display: block;
            float: left;
            padding-bottom: 60px;
            position: relative;
            text-align: center;
            width: 100%;

            @include breakpoint(xxlarge) {
                padding-bottom: 0;
            }

            .ais-Pagination-item:not(.ais-Pagination-item--nextPage):not(.ais-Pagination-item--previousPage) {
                display: inline-block;
                padding: 5px;

                .ais-Pagination-link {
                    background: $box-color;
                    border-radius: 50%;
                    color: $black;
                    display: block;
                    height: 48px;
                    line-height: 48px;
                    text-align: center;
                    text-decoration: none;
                    width: 48px;
                }

                &.ais-Pagination-item--selected {
                    .ais-Pagination-link {
                        background-color: $ts-pagination-item-color;
                        color: $box-color;
                    }
                }
            }

            .ais-Pagination-item--nextPage {
                float: right;
                right: 0;
                .ais-Pagination-link {
                    &::before {
                        right: 15px;
                        transform: translateY(-50%) rotate(315deg);
                    }

                }
            }

            .ais-Pagination-item--previousPage {
                float: left;
                left: 0;
                .ais-Pagination-link {
                    &:before {
                        left: 15px;
                        transform: translateY(-50%) rotate(135deg);
                    }

                }
            }

            .ais-Pagination-item--nextPage,
            .ais-Pagination-item--previousPage {
                bottom: 0;
                padding-left: 0;
                padding-right: 0;
                position: absolute;

                @include breakpoint(xxlarge) {
                    position: static;
                }

                .ais-Pagination-link {
                    background-color: $primary-color;
                    border: 1px solid transparent;
                    color: $white;
                    cursor: pointer;
                    font-size: 0.9375rem;
                    line-height: 1;
                    padding: rem-calc(10) rem-calc(60);
                    position: relative;
                    text-align: center;
                    text-decoration: none;
                    transition: background-color 0.25s ease-out;

                    &::before {
                        border-bottom: 1px solid $white;
                        border-right: 1px solid $white;
                        content: "";
                        display: block;
                        height: 15px;
                        position: absolute;
                        top: 50%;
                        width: 15px;
                    }

                    &:hover,
                    &:focus,
                    &:active {
                        background-color: rgba($primary-color, .95);
                    }
                }
            }

            .ais-Pagination-item {
                &:after {
                    display: none;
                }
            }
        }
    }

    // filters
    .algolia-left-container {
        .ais-ClearRefinements-button {
            background-color: $gray-dark;
            margin-bottom: 20px;

            &:hover,
            &:focus,
            &:active {
                background-color: rgba($gray-dark, .9);
            }
        }

        .algolia-current-refinements {
            display: none;
        }

        .ais-Panel {
            border: none;
        }

        .ais-Panel-body {
            padding: 0;
        }

        .ais-Panel-header {
            background: none;
            margin-bottom: 20px;
            padding: 0;

            .name {
                font-size: rem-calc(26);
                line-height: 1.2;
            }
        }

        .ais-RangeSlider {
            margin: 0 15px;

            .rheostat-horizontal {
                margin: 30px 0;
            }

            .rheostat-handle {
                background: $ts-sidebar-main-price-slider-line-color;
                border: 9px solid $white;
                border-radius: 50%;
                box-shadow: 2px 2px 5px rgba($black, .5);
                cursor: pointer;
                height: 30px;
                width: 30px;
            }

            .rheostat-progress,
            .rheostat-background {
                background: $ts-sidebar-main-price-slider-line-color;
                top: 5px;
            }

            .rheostat-tooltip,
            .rheostat-marker-horizontal:not(.rheostat-marker-large) {
                display: none;
            }

            .rheostat-marker-horizontal {
                background: none;
            }

            .rheostat-value {
                font-weight: 500;
                padding-top: 30px;
            }
        }

        .ais-HierarchicalMenu-list {
            margin-bottom: 50px;

            .ais-HierarchicalMenu-item {
                border-top: 1px solid rgba($black, .15);
                padding: 0;

                &:after {
                    display: none;
                }

                .ais-HierarchicalMenu-link {
                    font-size: 1.0625rem;
                    color: $black;
                    display: block;
                    background: $white;
                    padding: 12px 10px 14px;
                    position: relative;
                    text-decoration: none;
                    transition: background-color .25s ease-out;

                    &::after {
                        border-bottom: 1px solid rgba($black, .7);
                        border-right: 1px solid rgba($black, .7);
                        content: "";
                        display: block;
                        height: 12px;
                        position: absolute;
                        right: 15px;
                        transform: translateY(-50%) rotate(315deg);
                        top: 50%;
                        width: 12px;
                    }
                }
            }

            .ais-HierarchicalMenu-list--child {
                .ais-HierarchicalMenu-list {
                    margin-bottom: 0;
                }

                .ais-HierarchicalMenu-link {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}
