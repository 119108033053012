// /**
//  * Copyright © Convert, Inc. All rights reserved.
//  *
//  */

//
//  Contained row appearance styles
//  _______________________________

[data-content-type="row"][data-appearance="contained"] {
    box-sizing: border-box;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: $pb-layout__max-width;

    [data-element="inner"] {
        box-sizing: border-box;
    }
}

//
//  Responsive Classes
//  _____________________________________________

@include breakpoint ($pb-responsive-stacking) {
    [data-content-type="row"][data-appearance="contained"] {
        [data-element="inner"] {
            background-attachment: scroll !important;
        }
    }
}