//
// Variables
// _____________________________________________
$wishlist-icon__font-family: 'luma-icons';
$wishlist-notice__icon: '\e629';
$wishlist-dropdown-toggle__font-size: 12px;
$wishlist-dropdown-toggle__color: inherit;
$wishlist-dropdown-toggle__icon: '\e622';
$wishlist-price-box__width: fit-content;
$wishlist-price-box-price__font-weight: 500;
$wishlist-product-item-checkbox__width: fit-content;
$wishlist-pager-pages__width: 100%;
$wishlist-toolbar-select-label-mobile__width: 65px;

//
//  Common
//  ----------------------------------------------

.wishlist-index-index {
    position: relative;

    .form-wishlist-items {
        .cart.table-wrapper {
            display: flex;

            .item-image {
                input.checkbox {
                    margin-right: 10px;
                }
            }
        }

        .wishlist-select {
            .wishlist-name-current {
                display: none;
            }

            .wishlist-select-items {
                .item {
                    margin-bottom: 1rem;

                    &:after {
                        display: none;
                    }

                    a {
                        text-decoration: none;
                    }
                }

                .wishlist-add {
                    padding-left: 0;

                    &:after {
                        display: none;
                    }
                }
            }
        }

        .wishlist-info {
            .wishlist-notice {
                &:before {
                    color: $color-gray64;
                    content: $wishlist-notice__icon;
                    font-family: $wishlist-icon__font-family;
                    margin: 0 5px 0 0;
                }
            }
        }

        .wishlist-title {
            a {
                text-decoration: none;
            }
        }

        .wishlist-info,
        .wishlist-toolbar-actions {
            padding-top: 15px;
        }

        .wishlist-toolbar {
            display: flex;

            .wishlist-toolbar-select {
                display: flex;
                padding-top: 6px;

                input {
                    margin: 0 10px 0 0;
                }
            }

            .wishlist-toolbar-actions {
                color: $ts-product-list-link-color;

                .wishlist-dropdown {
                    .action.toggle:after {
                        font-size: $wishlist-dropdown-toggle__font-size;
                        color: $wishlist-dropdown-toggle__color;
                        content: $wishlist-dropdown-toggle__icon;
                        font-family: $wishlist-icon__font-family;
                        margin: 0 0 0 5px;
                    }
                }

                .item {
                    padding-left: 35px;

                    &:after {
                        left: 15px;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

        .products-grid {
            .product-items {
                .product-item {
                    .product-item-inner {
                        display: none;

                        &button.action {
                            opacity: 1;
                        }

                        .price-box {
                            order: -1;
                            width: $wishlist-price-box__width;
                            margin-bottom: 0;
                            bottom: 15px;

                            .price {
                                font-weight: $wishlist-price-box-price__font-weight;
                                color: $ts-product-list-link-color;
                            }
                        }

                        .price-box,
                        .comment-box,
                        .box-tocart,
                        .product-item-actions .edit,
                        .product-item-actions .move,
                        .product-item-actions .copy,
                        .product-item-actions .delete,
                        .product-item-info {
                            position: relative;
                        }

                        .comment-box {
                            bottom: 25px;
                            margin-bottom: 0;
                        }

                        .box-tocart {
                            bottom: 45px;

                            .input-text {
                                margin-bottom: 0;
                            }
                        }

                        .product-item-actions {
                            .edit, .move {
                                bottom: 20px;
                            }

                            .copy, .delete {
                                position: relative;
                                bottom: 10px;
                            }

                            .edit {
                                padding-right: 25px;
                            }
                        }
                    }

                    &:hover {
                        .product-item-inner {
                            display: grid;
                        }
                    }

                    .product-item-info {
                        z-index: unset;

                        .product-item-checkbox {
                            float: left;
                            width: $wishlist-product-item-checkbox__width;
                            margin-bottom: 0;
                        }

                        .product-item-name {
                            position: relative;
                            bottom: 18px;
                        }
                    }
                }
            }
        }

        .actions-toolbar {
            .primary {
                display: flex;

                .update {
                    display: block;
                }
            }
        }
    }

    #create-wishlist-block {
        top: 10%;
        z-index: 1001;
    }

    .modal-popup {
        .modal-footer {
            button {
                @include button-extended;

                @if $button-gradient {
                    @include linear-gradient(transparent, $button-shadow-color);
                }
            }
        }
    }

    .wishlist-toolbar {
        .pager {
            .toolbar-amount {
                padding-bottom: 10px;
            }

            .pages {
                position: absolute;
                width: $wishlist-pager-pages__width;
                z-index: 0;
                margin-bottom: 25px;

                .pages-items {
                    .item {
                        &.current {
                            .page {
                                background: $color-gray90;
                                padding: 0 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}


//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .wishlist-index-index {
        .wishlist-toolbar {
            .pager {
                .limiter {
                    position: relative;
                    top: 55px;
                }
            }

            .wishlist-toolbar-select {
                label {
                    width: $wishlist-toolbar-select-label-mobile__width;
                    padding-top: 8px;
                }
            }

            &:last-child {
                display: none;
            }
        }

        .form-wishlist-items {
            padding-top: 90px;

            .products-grid {
                .product-items {
                    .product-item {
                        .product-item-info {
                            strong.product-item-name {
                                bottom: 0;
                                padding-top: 10px;
                                margin-left: 25px;
                            }

                            .checkbox.product-item-checkbox {
                                position: relative;
                                top: 26px;
                                left: 5px;
                            }
                        }
                    }
                }
            }

            .actions-toolbar {
                .primary {
                    flex-direction: column;
                    margin: 0 auto;
                }
            }
        }
    }
}
