// Foundation by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

// a. Base
// - - - - - - - - - - - - - - - - - - - - - - - - -
@import 'foundation/foundation';
@include foundation-global-styles;

//  Foundation for Sites Settings
//  -----------------------------
//
//  Table of Contents:
//
//   1. Global
//   2. Breakpoints
//   3. The Grid
//   4. Base Typography
//   9. Badge
//  10. Breadcrumbs
//  11. Button
//  12. Button Group
//  14. Close Button
//  19. Forms
//  20. Label
//  22. Menu
//  24. Off-canvas
//  26. Pagination
//  27. Progress Bar
//  31. Table
//  34. Title Bar
//  35. Tooltip
//  36. Top Bar

// 1. Global
// ---------

$global-font-size: 100%;
$body-font-size : rem-calc(13);
$global-width: rem-calc(1450);
$global-lineheight: 1.4;
$foundation-palette: (
  primary: #e24283,
  secondary: #000000,
  success: #61af3a,
  warning: #ffae00,
  alert: #c23434,
);
$light-gray: #eee;
$medium-gray: #ccc;
$dark-gray: #888;
$black: #000;
$white: #fff;
$box-color: #fff;
$primary-color: #e24283;
$body-background: #f8f8f8;
$body-font-color: #000000;
$body-font-family: ('Roboto', sans-serif);
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-weight-normal: 400;
$global-weight-bold: bold;
$global-title-weight: $global-weight-bold;
$global-radius: 0;
$global-text-direction: ltr;
$global-flexbox: false;
$print-transparent-backgrounds: true;

@include add-foundation-colors;

// 2. Breakpoints
// --------------

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 768px,
  xlarge: 1024px,
  xxlarge: 1200px,
  xxxlarge: 1450px,
);
$breakpoint-classes: (small medium large xlarge xxlarge xxxlarge);

// 3. The Grid
// -----------

$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
  small: 34px,
  medium: 40px,
);
$grid-column-align-edge: true;
$block-grid-max: 8;

// 4. Base Typography
// ------------------

$header-font-family: ('Montserrat', sans-serif);
$font-family-alt: ('Montserrat', sans-serif);
$font-family-monospace: Consolas, 'Liberation Mono', Courier, monospace;
$header-styles: (
  small: (
    'h1': ('font-size': 25),
    'h2': ('font-size': 20),
    'h3': ('font-size': 19),
    'h4': ('font-size': 18),
    'h5': ('font-size': 17),
    'h6': ('font-size': 16),
  ),
  medium: (
    'h1': ('font-size': 38),
    'h2': ('font-size': 30),
    'h3': ('font-size': 21),
    'h4': ('font-size': 18),
    'h5': ('font-size': 17),
    'h6': ('font-size': 16),
  ),
  xlarge: (
    'h1': ('font-size': 45),
    'h2': ('font-size': 32),
    'h3': ('font-size': 22),
    'h4': ('font-size': 17),
    'h5': ('font-size': 15),
    'h6': ('font-size': 13),
  ),
);
$header-font-weight: 300;
$header-font-style: normal;
$header-letter-spacing : normal;
$header-color: inherit;
$header-lineheight: 1.1;
$header-margin-bottom: .5rem;
$header-text-transform: uppercase;
$small-font-size: 85%;
$header-small-font-color: scale-color($body-font-color, $alpha: -30%);
$paragraph-lineheight: 1.6;
$paragraph-margin-bottom: 1rem;
$paragraph-text-rendering: optimizeLegibility;
$paragraph-font-weight: 400;
$anchor-color: #5a8cb4;
$anchor-color-hover: scale-color($anchor-color, $lightness: -14%);
$anchor-text-decoration: underline;
$anchor-text-decoration-hover: none;
$switch-color: $body-font-color;
$switch-weight: 400;
$hr-width: $global-width;
$hr-border: 1px solid $medium-gray;
$hr-margin: rem-calc(20) auto;
$defnlist-margin-bottom: 0;
$defnlist-term-weight: $global-weight-bold;
$defnlist-term-margin-bottom: 0;

// 8. Accordion Menu
// -----------------

$accordionmenu-arrows: true;
$accordionmenu-arrow-color: $primary-color;

// 9. Badge
// --------

$badge-palette: $foundation-palette;
$badge-background: $primary-color;
$badge-color: $white;
$badge-color-alt: $black;
$badge-padding: 0.3em;
$badge-minwidth: 2.1em;
$badge-font-size: 0.6rem;

// 10. Breadcrumbs
// ---------------

$breadcrumbs-margin: 0 0 $global-margin 0;
$breadcrumbs-item-font-size: rem-calc(11);
$breadcrumbs-font-family: inherit;
$breadcrumbs-font-weight: 400;
$breadcrumbs-item-color: scale-color($black, $alpha: -50%);
$breadcrumbs-item-color-current: scale-color($black, $alpha: -50%);
$breadcrumbs-item-color-disabled: scale-color($black, $alpha: -70%);
$breadcrumbs-item-margin: 0.4rem;
$breadcrumbs-item-uppercase: true;
$breadcrumbs-item-slash: true;
$breadcrumbs-item-slash-view: '/';
$breadcrumbs-item-slash-color: scale-color($black, $alpha: -50%);

// 11. Button
// ----------

$button-font-family: $body-font-family;
$button-padding: .82em 1em 0.87em;
$button-margin: 0 0 $global-margin 0;
$button-fill: solid;
$button-weight: 400;
$button-gradient: false;
$button-shadow-color: rgba($black, .05);
$button-background: $primary-color;
$button-background-hover: darken(desaturate(adjust-hue($button-background, 6.0359), 10.5563), 6.2745);
$button-background-active: darken(desaturate(adjust-hue($button-background, 6.0359), 10.5563), 6.2745);
$button-background-secondary: $secondary-color;
$button-background-secondary-hover: darken($secondary-color, 18.8235);
$button-background-secondary-active: darken($secondary-color, 18.8235);
$button-color: $white;
$button-color-alt: $black;
$button-radius: $global-radius;
$button-sizes: (
  tiny: 0.6rem,
  small: 0.75rem,
  default: 1rem,
  xlarge: 1.25rem,
);
$button-opacity-disabled: 0.25;
$button-text-transform: none;

// 12. Button Group
// ----------------

$buttongroup-margin: 1rem;
$buttongroup-spacing: 1px;
$buttongroup-child-selector: '.button';
$buttongroup-expand-max: 6;

// 14. Close Button
// ----------------

$closebutton-position: right top;
$closebutton-offset-horizontal: rem-calc(23);
$closebutton-offset-vertical: rem-calc(25);
$closebutton-size: 3rem;
$closebutton-lineheight: 1;
$closebutton-color: $black;
$closebutton-color-hover: $primary-color;

// 19. Forms
// ---------

$fieldset-border: none;
$fieldset-padding: 0;
$fieldset-margin: 0;
$legend-padding: 0;
$form-spacing: rem-calc(28);

$helptext-color: $black;
$helptext-font-size: rem-calc(13);
$helptext-font-style: italic;

$input-prefix-color: $black;
$input-prefix-background: $light-gray;
$input-prefix-border: 1px solid $medium-gray;
$input-prefix-padding: 1rem;

$form-label-color: $black;
$form-label-font-size: rem-calc(13);
$form-label-font-weight: $global-weight-normal;
$form-label-line-height: 2.4;

$input-select-height: ($input-font-size + $form-spacing + rem-calc(3));

$select-background: $white;
$select-triangle-color: transparent;
$select-triangle-right-space: 2rem;
$select-triangle-icon: 'icon-select';
$select-triangle-icon-type: svg;
$select-triangle-icon-position: calc(100% - 15px) 50%;
$select-triangle-icon-size: 13px;
$select-radius: $global-radius;

$input-color: $black;
$input-placeholder-color: scale-color($body-font-color, $alpha: -50%);
$input-font-family: $body-font-family;
$input-font-size: rem-calc(13);
$input-padding: rem-calc(10) rem-calc(14);
$input-background: $white;
$input-background-focus: $white;
$input-background-disabled: $light-gray;
$input-border: 1px solid lighten($black,60);
$input-border-focus: 1px solid $dark-gray;
$input-shadow: none;
$input-shadow-focus: none;
$input-cursor-disabled: default;
$input-transition: border-color 0.25s ease-in-out;
$input-number-spinners: true;
$input-radius: $global-radius;

$input-state-right-space: 2.5rem;
$input-invalid-icon: 'icon-invalid';
$input-invalid-icon-type: svg;
$input-valid-icon: 'icon-valid';
$input-valid-icon-type: svg;
$input-state-icon-position: calc(100% - 17px) 50%;
$input-state-icon-size: 12px auto;
$input-state-valid-icon-size: 14px auto;
$input-state-icon-valid-position: calc(100% - 15px) 50%;


// 22. Menu
// --------

$menu-margin:  0 0 0 -.5rem;
$menu-margin-nested: 1rem;
// $menu-item-padding-stuck: rem-calc(10) rem-calc(12) rem-calc(11);
$menu-item-padding-hz  : ( medium : ( padding-left: rem-calc(10), padding-right: rem-calc(10)),
                           xlarge  : ( padding-left: rem-calc(16), padding-right: rem-calc(16)),
                           xxlarge : ( padding-left: rem-calc(20), padding-right: rem-calc(20)),
                           xxxlarge : ( padding-left: rem-calc(20), padding-right: rem-calc(20)));
$menu-item-padding-vt  : ( medium : ( padding-top: rem-calc(13), padding-bottom: rem-calc(13)),
                           xlarge  : ( padding-top: rem-calc(13), padding-bottom: rem-calc(13)),
                           xxlarge : ( padding-top: rem-calc(14), padding-bottom: rem-calc(14)),
                           xxxlarge : ( padding-top: rem-calc(14), padding-bottom: rem-calc(14)));
$menu-item-color-active: $body-font-color;
$menu-item-background-active: #f7f7f7;
$menu-icon-spacing: 0.25rem;

// 24. Off-canvas
// --------------

$offcanvas-size: 100%;
$offcanvas-background: $white;
$offcanvas-zindex: -1;
$offcanvas-transition-length: 0.25s;
$offcanvas-transition-timing: ease;
$offcanvas-fixed-reveal: true;
$offcanvas-exit-background: rgba($white, 0.25);
$maincontent-class: 'off-canvas-content';
$maincontent-shadow: 0 0 10px rgba($black, 0.5);


// 26. Pagination
// --------------

$pagination-font-size: rem-calc(14);
$pagination-margin-bottom: $global-margin;
$pagination-item-color: $black;
$pagination-item-padding: rem-calc(3 10);
$pagination-item-spacing: rem-calc(1);
$pagination-radius: $global-radius;
$pagination-item-background-hover: $light-gray;
$pagination-item-background-current: $primary-color;
$pagination-item-color-current: $pagination-item-background-current;
$pagination-item-color-disabled: $medium-gray;
$pagination-ellipsis-color: $black;
$pagination-mobile-items: false;
$pagination-arrows: true;

// 27. Progress Bar
// ----------------

$progress-height: rem-calc(6);
$progress-padding: 0;
$progress-background: $white;
$progress-border: none;
$progress-margin-bottom: $global-margin;
$progress-meter-background: $primary-color;
$progress-radius: $global-radius;


// 31. Table
// ---------

$table-background: $white;
$table-color-scale: 20%;
$table-border: 1px solid smart-scale($table-background, $table-color-scale);
$table-margin: 2.5rem;
$table-padding: rem-calc(20 10 20 0);
$table-head-padding: rem-calc(18 10 10 0);
$table-hover-scale: 5%;
$table-row-hover: darken($table-background, $table-hover-scale);
$table-row-stripe-hover: darken($table-background, $table-color-scale + $table-hover-scale);
$table-striped-background: smart-scale($table-background, $table-color-scale);
$table-stripe: none;
$table-head-background: $white;
$table-foot-background: $box-color;
$table-head-font-color: $body-font-color;
$table-head-font-family: $header-font-family;
$table-head-font-weight: inherit;
$table-head-font-size: 1rem;
$table-body-font-size: .815rem;
$show-header-for-stacked: false;


// 34. Title Bar
// -------------

$titlebar-background: $black;
$titlebar-color: $white;
$titlebar-padding: 0.5rem;
$titlebar-text-font-weight: bold;
$titlebar-icon-color: $white;
$titlebar-icon-color-hover: $medium-gray;
$titlebar-icon-spacing: 0.25rem;

// 35. Tooltip
// -----------

$has-tip-font-weight: $global-weight-bold;
$has-tip-border-bottom: dotted 1px $dark-gray;
$tooltip-background-color: $black;
$tooltip-color: $white;
$tooltip-padding: 0.75rem;
$tooltip-font-size: $small-font-size;
$tooltip-pip-width: 0.75rem;
$tooltip-pip-height: $tooltip-pip-width * 0.866;
$tooltip-radius: $global-radius;

// 36. Top Bar
// -----------

$topbar-padding: 0.75rem;
$topbar-background: $light-gray;
$topbar-submenu-background: $topbar-background;
$topbar-title-spacing: 1rem;
$topbar-input-width: 200px;
$topbar-unstack-breakpoint: medium;




