// /**
//  * Copyright © Convert, Inc. All rights reserved.
//  *
//  */

//
//  Default appearance styles
//  _____________________________________________

[data-content-type="buttons"] {
    max-width: 100%;
}

[data-content-type="buttons"][data-appearance="inline"] {
	[data-content-type="button-item"] {
		margin: $pb-buttons--inline__margin;
	}
}

@if $pb-buttons--stacked__expand {
	[data-content-type="buttons"][data-appearance="stacked"] {
		[data-content-type="button-item"] {
			a {
				@include button-expand;
			}
		}
	}
}