//
// Variables
// _____________________________________________
$field-qty-select__width: 65px;

//
// Common
// _____________________________________________
.page-product-configurable {
    .product-info-wrapper {
        .product-info-main {
            .product-info-sidebar {

                .product-add-form {
                    form#product_addtocart_form {
                        .price-revert-wrapper {
                            display: none;
                        }

                        .box-tocart {
                            position: relative;
                            z-index: 1;

                            .field.qty {
                                label {
                                    text-align: center;
                                }
                                select {
                                    width: $field-qty-select__width;
                                }
                            }

                            .actions {
                                padding-top: 37px;
                            }
                        }
                    }
                }
            }
        }
    }
}
