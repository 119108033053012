// /**
//  * Copyright © Convert, Inc. All rights reserved.
//  *
//  */

//
//  Full Width row appearance styles
//  ________________________________

[data-content-type="row"][data-appearance="full-width"] {
    box-sizing: border-box;

    > .row-full-width-inner {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: $pb-layout__max-width;
        width: 100%;
    }
}

//
//  Responsive Classes
//  _____________________________________________

@include breakpoint ($pb-responsive-stacking) {
    [data-content-type="row"][data-appearance="full-width"] {
        background-attachment: scroll !important;
    }
}