// /**
//  * Copyright © Convert, Inc. All rights reserved.
//  */

//
//  Default appearance styles
//  _____________________________________________


%lib-link-as-button {
	display: inline-block;
	text-decoration: none;
	font-weight: $pb-button__font-weight;
	box-shadow: none;
	@if $pb-button__border-radius {
    	border-radius: $pb-button__border-radius;
	}

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

[data-content-type="button-item"] {
    max-width: 100%;

    [data-element="link"],
    [data-element="empty_link"] {
        max-width: 100%;
        word-wrap: break-word;
    }

    [data-element="empty_link"] {
        cursor: default;
    }
}

a,
button,
div {
    &.pagebuilder-button-link {
        @include button-base();
        box-shadow: none;
        box-sizing: border-box;
        text-decoration: $pb-button-link__text-decoration;

        &:hover,
	    &:active,
	    &:focus {
	        text-decoration: $pb-button-link__text-decoration--hover;
	    }
    }

    &.pagebuilder-button-primary {
    	@extend %lib-link-as-button;
        @include button($background: $pb-button__background-color, $background-hover: $pb-button__background-color--hover, $color: $pb-button__color );
    }

    &.pagebuilder-button-secondary {
        @extend %lib-link-as-button;
        @include button($background: $pb-button--secondary__background-color, $background-hover: $pb-button--secondary__background-color--hover, $color: $pb-button__color );
    }
}