// /**
//  * Copyright © Convert, Inc. All rights reserved.
//  *
//  */

//
//  Default appearance styles
//  _____________________________________________

@include breakpoint ($pb-responsive-stacking) {
    .pagebuilder-column-group {
        flex-wrap: wrap;
    }
}