// /**
//  * Copyright © Convert, Inc. All rights reserved.
//  *
//  */

//
//  Default appearance styles
//  _____________________________________________

[data-content-type$="block"] {
    .block {
        p:last-child {
            margin-bottom: $pb-block--last__margin-bottom;
            margin-top: 0;
        }
    }
}