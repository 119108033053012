.page-layout-0column .usp-section,
.usp-section ul {
	padding-left: map-get($ts-global-space-indent, small);
	padding-right: map-get($ts-global-space-indent, small);

	@include breakpoint(large) {
		padding-left: map-get($ts-global-space-indent, large);
		padding-right: map-get($ts-global-space-indent, large);
	}

	@include breakpoint(xxxlarge) {
		padding-left: 0;
		padding-right: 0;
	}
}

.usp-section {
	padding: 1.6rem 0;
	position: relative;
	overflow: hidden;

	@include breakpoint(xlarge) {
		padding: 2.3rem 0;
	}

	&:before {
		content: '';
		display: block;
		visibility: visible;
		border-top: $ts-main-border-width $ts-main-border-style rgba($black, .15);
		position: absolute;
		top: 0;
		left: -50%;
		right: -50%;
	}

	h3 {
		@include at;
	}

	ul {
		@include lsn;
		@include grid-row;
		font-size: $ts-usp-font-size;
		@include header-font-family($ts-usp-font-family);
		font-weight: $ts-usp-font-weight;
		color: $ts-usp-font-color;

		li {
			@include grid-col;
			@include grid-column-end;
			line-height: 1.3;
			margin-bottom: $ts-usp-item-bottom-spacing;

			@if ($ts-usp-item-text-align != default) {
				text-align: $ts-usp-item-text-align;
			}

			&:last-child {
				margin-bottom: 0;
			}

			a,
			span {
				display: block;
				position: relative;

				@if ( $ts-usp-item-icon-type != none) {
					padding-left: ($ts-usp-item-icon-indent + $ts-usp-item-icon-width);
				}

				@if ( $ts-usp-item-icon-type != none) {
					&:before {
						content: '';
						display: block;
						position: absolute;
						width: $ts-usp-item-icon-width;
						height: $ts-usp-item-icon-height;
						top: $ts-usp-item-icon-top-indent;
						left: 0;
						background: url("#{$ts-path-to-images}/#{$ts-usp-item-icon}.#{$ts-usp-item-icon-type}") no-repeat;
						background-size: $ts-usp-item-icon-width auto;
					}
				}
			}

			a {
				color: $ts-usp-font-color;
				text-decoration: none;

				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}

			div {
				font-size: $ts-usp-item-description-font-size;
				margin-top: $ts-usp-item-description-offset;
			}

			@include breakpoint(medium) {
				@include grid-col-size(6);
				margin-bottom: ($ts-usp-item-bottom-spacing*1.5);
			}

			@include breakpoint(large) {
				@include grid-col-size(3);
				margin-bottom: 0;
			}
		}// li
	}// ul
}// .usp-section

.category-usp-section {
	+ .page-main {
		margin-top: 0 !important;
	}
}

.category-usp-section {
	.usp-section {
		@include grid-row;
		padding-top: 1rem;
		padding-bottom: 1.25rem;
		@include get-gutter(.5,'padding-left');
		padding-right: (map-get($grid-column-gutter, small)/2) !important;

		@include breakpoint(medium) {
			padding-bottom: 0;
			padding-right: (map-get($grid-column-gutter, medium)/2) !important;
		}

		@include breakpoint(large) {
			padding-top: .25rem;
			padding-bottom: .5rem;
		}

		&:before {
			display: none;
		}
		ul {
			max-width: initial;
			width: auto;
			@include get-gutter(-.25,'margin-left');
			@include get-gutter(-.25,'margin-right');
			padding-left: 0;
			padding-right: 0;

			@include breakpoint(large) {
				display: flex;
			}

			li {
				display: block;
				@include get-gutter(.25,'padding-left');
				@include get-gutter(.25,'padding-right');

				a,
				span {
					min-height: 100%;
					padding: 1rem .25rem;
					text-align: center;
					font-family: $body-font-family;
					font-weight: 400;
					font-size: rem-calc(13);
					background-color: lighten(desaturate($primary-color, 32.86), 45.88);
					&:before {
						display: inline-block;
						vertical-align: middle;
						position: relative;
						top: auto;
						left: auto;
						margin-right: 2px;
						background-size: 12px auto;
					}

					@include breakpoint(large) {
						padding: 1.25rem 1rem;
					}
					@include breakpoint(xlarge) {
						padding: 1.25rem 3rem;
					}
					@include breakpoint(xxlarge) {
						padding: 1.25rem .25rem;
						font-size: rem-calc(16);
					}
				}
			}
		}
	}
}// .category-usp-section