.page-with-filter,
.catalogsearch-result-index,
.algolia-instant-selector-results {
	.sidebar:not(.algolia-left-container) {
		display: none;

		@include breakpoint(medium) {
			display: block;
		}
	}

	@if ($ts-category-main-title-align == 'center') {
		.page-title-wrapper {
			padding-bottom: 0;
			margin-bottom: 0;

			.page-title {
				text-align: center;
				@include breakpoint(large) {
					padding-bottom: 1rem;
					margin-bottom: 1.5rem;
					line-height: 1;

					@if $ts-category-main-title-border {
						@include border-line(bottom, 0, $ts-main-border-color);
					}
				}
			}
			&:before {
				display: none;
			}
		}
	} // @if ($ts-category-main-title-align == 'center')

	@if ($ts-category-main-title-size == 'big') {
		.page-title-wrapper {
			.page-title {
				@include breakpoint(xlarge) {
					@include get-gutter(.25, 'margin-bottom');
					font-size: (map-deep-get($header-styles, xlarge, h1, font-size) * 1px) + $ts-category-main-title-size-big-qty;
				}
			}
		}
	}// @if ($ts-category-main-title-size == 'big')

}// .page-with-filter

.category-header {
	background-size: cover;
	overflow: hidden;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	padding-top: 1px;

	&:not(.has-desc) + .page-main {
		.mobile-controls {
			padding-top: .5rem;
			&:before {
				display: none;
			}
		}
	}

	@include breakpoint(medium down) {
		background: none !important;

		.category-description,
		.image-view {
			display: none;
		}
	}// @include breakpoint(medium down)

	.category-view {
		@include grid-row;

		&.toolbar {
			margin-bottom: 0;
			padding-bottom: 0;

			.page-title-wrapper {
				padding-bottom: 0;
				line-height: inherit;
				&:before {
					display: none;
				}
			}

			.page-title {
				@include get-gutter(.25, 'margin-bottom');

				@include breakpoint(xlarge) {
					font-size: (map-deep-get($header-styles, xlarge, h1, font-size) * 1px) + 10px;
				}

				@include breakpoint(xxlarge) {
					@include get-gutter(.5, 'margin-bottom');
				}
			}

			.toolbar-sorter {
				margin-top: .25rem;
				margin-bottom: .75rem;

				@include breakpoint(xlarge) {
					margin-top: .75rem;
				}

				@include breakpoint(xxlarge) {
					margin-top: 1.25rem;
				}
			}
		}
	}// .category-view

	.category-description {
		@include grid-col;
		@include grid-column-end;
		@include get-gutter(1.25, 'margin-bottom');

		p {
			line-height: 1.3;
			margin-bottom: 0;
		}

		@include breakpoint(xlarge) {
			@include get-gutter(.5, 'margin-bottom');

			p {
				line-height: $paragraph-lineheight;
				margin-bottom: $paragraph-margin-bottom;
			}
		}
	}// .category-description

	+ .category-cms {
		@include grid-row;
		@include get-gutter(.5,'padding-left');
		@include get-gutter(.5,'padding-right');
		margin-bottom: 1.5rem;

		@include breakpoint(medium down) {
			display: none;
		}
	}// .category-cms
}// .category-header


.category-view {

	@include breakpoint(medium down) {
		.page-title {
			margin-bottom: 0;
			span {
				@include underlined-title;
				margin-bottom: 0;
				padding-bottom: .75rem;
			}
		}
	}
	.category-header-mobile {
		@include get-gutter(.5,'margin-left');
		@include get-gutter(.5,'margin-right');

		.filter-options-title {
			@include at;
		}

		.filter-options-content {
			margin-bottom: 0;

			ul,
			ol {
				border-top: 0;

				li {
					font-size: $ts-sidebar-mobile-category-font-size;
					font-weight: $ts-sidebar-mobile-category-font-weight;
					font-family: $ts-sidebar-mobile-category-font-family;

					&:last-child {
						border-bottom: 0;
					}
				}

				a {
					font-size: $ts-sidebar-mobile-category-font-size;
					padding: $ts-sidebar-mobile-category-padding;
					color: $ts-sidebar-mobile-category-color;
					@if ( $ts-sidebar-mobile-category-image != false ) {
						background: url("#{$ts-path-to-images}/#{$ts-sidebar-mobile-category-image}.#{$ts-sidebar-mobile-category-image-type}") $ts-sidebar-mobile-category-image-position no-repeat;
						background-size: $ts-sidebar-mobile-category-image-size;
					}

					&:hover {
						color:$anchor-color;
					}
				}
			}
		}// .filter-options-content

	}// .category-header-mobile
}// .category-view


.category-image {
	margin-bottom: 1.5rem;
}// .category-image

.hidden-sm {
	@extend .hide-for-medium-only;
}
.hidden-xs {
	@extend .hide-for-small-only;
}


.toolbar {
	&.toolbar-products {
		@include grid-row;
		padding-bottom: .5rem;
		margin-bottom: $ts-category-sorting-toolbar-bottom-outer-indent;
		clear: both;

		@if $ts-category-sorting-toolbar-bottom-border {
			@include border-line('bottom', 0, $ts-main-dark-border-color);
		}

		@include breakpoint(large) {
			padding-bottom: 0;
		}

		.products-grid + & {
			padding-top: $form-spacing;
			margin-bottom: 0;

			@if $ts-category-sorting-bottom-toolbar-border {
				@include border-line('top', 0, $ts-main-dark-border-color);
				margin-top: 2rem;
			}
		}

		@if ($ts-category-sorting-toolbar-position == 'center-title') {
			.algolia-right-container & {
				@include get-gutter(-.5,'margin-left');
				@include get-gutter(-.5,'margin-right');
			}
			@include breakpoint(medium) {
				padding-bottom: 0;
			}
		}

		.modes,
		.limiter,
		.pages {
			display: block;
		}

		.toolbar-amount,
		.mobile-controls {
			@include grid-col;
			margin-bottom: .5rem;

			@include breakpoint(medium) {
				@include grid-col-size(6);
				margin-bottom: 1.25rem;
				line-height: $ts-category-sorting-toolbar-height;
			}
			@include breakpoint(xlarge) {
				@include grid-col-size(8);
			}
		}

		.toolbar-amount {
			color: $ts-category-sorting-toolbar-color;
			font-size: ($ts-category-sorting-toolbar-font-size*.86);
			font-weight: $ts-category-sorting-toolbar-font-weight;
			margin-top: .5rem;

			@include breakpoint(medium) {
				margin-top: 0;
				margin-bottom: 0;
			}

			@include breakpoint(large) {
				font-size: $ts-category-sorting-toolbar-font-size;
				margin-bottom: $ts-category-sorting-toolbar-bottom-indent;
			}

			a {
				color: darken(saturate(adjust-hue($anchor-color, -0.2260), 9.0598), 6.4706);
				text-decoration: $ts-category-sorting-toolbar-link-underline;

				&:hover,
				&:active {
					text-decoration: none;
				}
			}
		}// .toolbar-amount

		.mobile-controls {
			line-height: inherit;
			font-size: 0;
			.mobile-filter-btn {
				&:last-child {
					margin-right: 0;
				}
			}
		}// .mobile-controls

	}// .toolbar-products
}// .toolbar

.toolbar-sorter {
	@include grid-col;
	font-size: 0;

	@include breakpoint(medium) {
		@include grid-col-size(6);
	}
	@include breakpoint(xlarge) {
		@include grid-col-size(4);
	}
	@include breakpoint(xxlarge) {
		@include grid-col-size(3);
	}

	.sorter-action {
		display: none;
	}

	.sorter-label {
		display: inline-block;
		text-align: right;
		white-space: nowrap;
		@include label-colon;
		padding-right: 1rem;

		@if not $ts-category-sorting-toolbar-label-width {
			@include at;
		} @else {
			@include breakpoint(medium) {
				width: $ts-category-sorting-toolbar-label-width;
			}
		}

	}//.sorter-label

	select.sorter-options,
	.sorter-options select {
		display: inline-block;
		height: $ts-category-sorting-toolbar-height;
		font-size: $ts-category-sorting-toolbar-font-size;
		font-weight: $ts-category-sorting-toolbar-font-weight;
		font-family: $ts-category-sorting-toolbar-font-family;
		padding-top: $ts-category-sorting-toolbar-select-spacing;
		padding-bottom: $ts-category-sorting-toolbar-select-spacing;
		margin-bottom: 0;
		background-color: $ts-category-sorting-toolbar-select-bg;
		text-transform: $ts-category-sorting-toolbar-text-transform;

		@include breakpoint(medium) {
			width: $ts-category-sorting-toolbar-select-width;
		}
	}// select.sorter-options

	.sorter-options > div {
		display: inline-block;

		@include breakpoint(medium) {
			width: $ts-category-sorting-toolbar-select-width;
			select {
				width: 100%;
			}
		}
	}
}// .toolbar-sorter


.block.filter {
	.filter-options-title,
	.filter-options-content {
		padding: $ts-sidebar-main-padding;
		background: $ts-sidebar-main-background-color;
	}

	.filter-options-title {
		@include clearfix;
		padding-bottom: $ts-sidebar-main-filter-title-indent;
		padding-left: $ts-sidebar-main-filter-title-indent-left;
	}

	.filter-options-content {
		padding-top: 0;
		&.has-ui-slider {
			padding-top: .75rem;
			padding-bottom: 3.5rem;
		}
	}

	@include breakpoint(large down) {
		.filter-options {
			.filter-content {
				 &:last-child {
				 	.filter-options-content {
				 		padding-bottom: 0;
				 	}
				 }
			}
		}
	}
}// .block.filter

.block.filter,
.filter-content,
.nav-toolbar-sorter-mobile {

	ol.items {
		@include lsn;
	}

	&.filter-price,
	.has-ui-slider {
		ol.items,
		ul.items {
			border-top: 0;
		}
	}

	a {
		text-decoration: none;
	}

	.filter-title,
	.filter-subtitle {
		@include at;
	}

	.filter-options-title,
	.filter-current-subtitle {
		display: block;
		@include header-font-family($ts-sidebar-main-filter-title-font-family);
		font-size: $ts-sidebar-main-filter-title-font-size;
		color: $ts-sidebar-main-filter-title-font-color;
		font-weight: $ts-sidebar-main-filter-title-font-weight;
		margin-bottom: 0;
		line-height: 1.2;
		padding-bottom: $ts-sidebar-main-filter-title-indent;
		@if ( $ts-sidebar-main-filter-title-text-transform != none ) {
			text-transform: $ts-sidebar-main-filter-title-text-transform;
		}

		.clear-filter {
			font-family: $ts-sidebar-main-filter-clear-font-family;
			font-size: $ts-sidebar-main-filter-clear-font-size;
			font-weight: $ts-sidebar-main-filter-clear-font-weight;
			color: $ts-sidebar-main-filter-clear-font-color;
			border-bottom: $ts-sidebar-main-filter-clear-border;
			line-height: 1;
			margin-top: ($ts-sidebar-main-filter-title-font-size / 2.5);
			float: right;

			&:hover,
			&:active {
				border-bottom: 0;
				padding-bottom: 1px;
			}
		}
	}// .filter-options-title, .filter-current-subtitle


	.filter-options-content {
		margin-bottom: $ts-sidebar-main-filter-content-indent;
	}

	.filter-current {
		margin-bottom: ($ts-sidebar-main-filter-content-indent / 4);

		.filter-label {
			@include label-colon;
		}

		.filter-value {
			color: map-get($foundation-palette, primary);
		}

		.item {
			padding: $ts-sidebar-main-filter-anchor-padding;

			a {
				padding: 0;
			}
		}
	}// .filter-current

	.action {
		&.remove {
			display: inline-block;
			vertical-align: middle;
			color: map-get($foundation-palette, alert);
			// @extend .fi-x;

			span {
				@include at;
			}
		}
		&.clear {
			@include button-extended;
			@include button-expand;
			@include button-style($secondary-color, $button-background-secondary-hover);
			margin-bottom: 1.15rem;
		}
	}// .action

	.label {
		@include no-label;
	}
}// .block.filter, .filter-content, .nav-toolbar-sorter-mobile


.filter-content {
	.item {
		position: relative;

		label {
			margin: 0;
		}

		input[type="checkbox"],
		input[type="radio"] {
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;

			&[checked="checked"] {
				+ .label {
					font-weight: bolder;

					&:after {
						display: block;
						content: '';
						position: absolute;
						top: 50%;
						right: $ts-mobile-filter-image-position;
						transform: translateY(-50%);
						@if ( $ts-mobile-filter-image != false ) {
							width: $ts-mobile-filter-image-width;
							height: $ts-mobile-filter-image-height;
							background: url("#{$ts-path-to-images}/#{$ts-mobile-filter-image}.#{$ts-mobile-filter-image-type}") no-repeat;
							background-size: 100% auto;
						}
					}
				}
			}
		}
	}
}// .filter-content


@if ($ts-sidebar-additional-hide != true) {
	.sidebar-additional {

		.block {
			font-size: $ts-sidebar-additional-filter-font-size;
			font-weight: $ts-sidebar-additional-filter-font-weight;
			margin-bottom: 1.5rem;

			.subtitle {
				display: none;
			}
		}// .block

		.actions-toolbar {
			margin-top: .5rem;

			button.action {
				margin-bottom: .5rem;
			}
		}// .actions-toolbar

		.block-title {
			display: block;
			font-family: $ts-sidebar-additional-filter-title-font-family;
			font-size: $ts-sidebar-additional-filter-title-font-size;
			color: $ts-sidebar-additional-filter-title-font-color;
			font-weight: $ts-sidebar-additional-filter-title-font-weight;
			@if ( $ts-sidebar-additional-filter-title-indent != 0 ) {
				margin-bottom: $ts-sidebar-additional-filter-title-indent;
			}
			@if ( $ts-sidebar-additional-filter-title-text-transform != none ) {
				text-transform: $ts-sidebar-additional-filter-title-text-transform;
			}

			strong {
				font-weight: inherit;
			}

			.counter {
				@if $ts-sidebar-main-filter-count-hide {
					@include at;
				} @else {
					font-size: ($ts-sidebar-additional-filter-title-font-size * .75);
					color: $ts-sidebar-main-filter-count-color;

					&:before {
						content: '(';
					}

					&:after {
						content: ')';
					}
				}
			}// .counter
		}// .block-title
	}// .sidebar-additional
}
.filter-options-content .am-show-more,
.am-show-more {
	display: inline-block;
	padding: $ts-algolia-show-more-padding;
	margin: $ts-algolia-show-more-margin;
	font-size: $ts-algolia-show-more-font-size;
	font-weight: $ts-algolia-show-more-font-weight;
	font-family: $ts-algolia-show-more-font-family;
	color: $ts-algolia-show-more-color;
	text-decoration: $ts-algolia-show-more-text-decoration;
	cursor: pointer;
	position: relative;
	@extend .no-outline;

	@if not ($ts-algolia-show-more-icn-img == false) {
		&:after {
			display: block;
			content: '';
			width: $ts-algolia-show-more-icn-width;
			height: $ts-algolia-show-more-icn-height;
			position: absolute;
			right: $ts-algolia-show-more-icn-pos-right;
			top: 50%;
			transform: translateY(-50%);
			background-image: url("#{$ts-path-to-images}/#{$ts-algolia-show-more-icn-img}.#{$ts-algolia-show-more-icn-type}");
			background-repeat: no-repeat;
			background-size:  100% auto;
		}
	} @else {
		&:before,
		&:after {
			display: none;
		}
	}

	@if $ts-algolia-show-more-fade {
		min-height: $ts-algolia-show-more-height;
		display: flex;
		align-items: flex-end;
		line-height: 1;
		margin-top: -1*($ts-algolia-show-more-height - $ts-algolia-show-more-font-size - $ts-algolia-show-more-top-indent);
		background: linear-gradient(rgba($body-background, .26) 0%, rgba($body-background, .47) 20%, rgba($body-background, .7) 40%, rgba($body-background, .87) 60%, $body-background 100%);
	}


	&:hover,
	&:focus,
	&:active {
		text-decoration: $ts-algolia-show-more-hover-text-decoration;
	}

	&[data-is-hide="false"] {
		@if not ($ts-algolia-show-more-icn-img == false) {
			&:after {
				transform: translateY(-50%) rotate(180deg);
			}
		}
	}
}

// ASCH-138
.amshopby-search-box {
	/* clears the 'X' from Internet Explorer */
	input[type="text"],
	input[type="search"] {
		&::-ms-clear,
		&::-ms-reveal {
			display: none;
			width: 0;
			height: 0;
		}
	}
}
