@if ($ts-product-info-sidebar-view == 'ferner-view') {
	.product-info-wrapper {
		margin-bottom: 3.75rem;

		.product.media {
			margin-bottom: 1.5rem;
			._block-content-loading,
			[data-bind="blockLoader: isLoading"] {
				position: static;
			}
		}// .product.media

		.product-options-bottom {
			// display: none;
		}// .product-options-bottom

		.product-social-links {
			line-height: normal;
			text-align: right;
			@include get-gutter(0,'padding-right');

			@include breakpoint(large down) {
				position: relative;
				padding-top: 1rem;
				text-align: center;
				right: 0;
				clear: both;

				.action.towishlist,
				.action.wishlist-remove-product {
					width: auto;
					text-decoration: none;
					background-size: $ts-addtowishlist-icon-width $ts-addtowishlist-icon-height;
					> span {
						position: static;
						opacity: 1;
						margin: 0 0 0 rem-calc(38);
						padding: 0;
						color: $anchor-color;
						font-size: rem-calc(13);
						background: none;

						&:after {
							display: none;
						}
					}
				}
			}
		}// .product-social-links

		.product-info-form-wrapper {

			.product-info-form-row {
				@include grid-row;
				@include grid-row-nest;
				position: relative;

				@include breakpoint(xlarge) {
					// display: flex;
					// justify-content: flex-end;
				}

				.swatch-attribute-label {
					width: 100%;
					display: block;
					font-size: $ts-color-swatch-title-font-size;
					font-weight: $ts-color-swatch-title-font-weight;
					font-family: $ts-color-swatch-title-font-family;
					font-style: $ts-color-swatch-title-font-style;
					text-transform: $ts-color-swatch-title-text-transform;
					margin: .25rem 0 1rem;
				}
			}// .product-info-form-row

			.product-info-stock-sku {
				@include grid-col;
				position: relative;
				margin-bottom: .25rem;

				@include breakpoint(xlarge) {
					.fieldset {
						&:before {
							display: none;
						}
					}
				}
			}// .product-info-stock-sku

			.price-revert-wrapper {
				@include grid-col;
				@include grid-col-size(6);
				@include grid-col-end;
				text-align: right;
				margin: .5rem 0;
				position: absolute;
				bottom: .25rem;
				right: 0;

				.price-box {
					display: flex;
				}

				@include breakpoint(xlarge) {
					position: relative;
					bottom: auto;
					right: auto;
					margin-top: 0;

					> .price-revert-wrapper,
					> .price-over-box {
						margin: 0 0 .25rem;
						display: inline-block;
						vertical-align: bottom;
						max-width: 96%;
					}
					&:before {
						content: '';
						display: inline-block;
						vertical-align: bottom;
						height: 100%;
						margin-right: -.3em;
					}
				}
			}// .price-revert-wrapper

			.stock {
				@include grid-col;
				@include grid-col-size(6);
				@include grid-col-end;
				@include get-gutter(0,'padding-left');
				line-height: $ts-product-detail-stock__line-height;
				margin: .5rem 0;
				font-size: $ts-product-detail-stock__font-size;

				@include breakpoint(xlarge only) {
					margin: 0;
				}
			}// .stock

			.is-configurable-product & {
				margin-top: .5rem;

				@include breakpoint(xlarge) {
					margin-top: 0;
				}

				.product-options-wrapper {
					> .fieldset {
						@include clearfix;
					}

					@include breakpoint(xlarge) {
						@include grid-col;

						.product-social-links {
							.stock {
								display: inline-block;
							}
						}
					}
				}// .product-options-wrapper

				.swatch-opt {
					@include get-gutter(.5,'margin-right');
					@include get-gutter(.5,'margin-left');
					padding-bottom: 1rem;
					border-bottom: 1px solid $ts-main-border-color;

					.swatch-attribute {
						position: relative;

						@include breakpoint(xlarge) {
							.mage-error {
								position: absolute;
								bottom: 0;
								left: 0;
								margin-bottom: 0;
								line-height: 1;
							}
						}
					}// .swatch-attribute

					.swatch-attribute-label {
						margin: 0 0 .4rem;
						@include breakpoint(xlarge) {
							margin: 0 0 1.1rem;
						}
					}

					.swatch-attribute-options {
						> .swatch-option:not(.disabled):before,
						> .swatch-option:not(.disabled):after {
							display: none;
						}
					}

					@include breakpoint(xlarge) {
						display: inline-block;
						@include get-gutter(0,'margin-left');
						@include get-gutter(0,'margin-right');
						border-bottom: 0;
						padding-bottom: 0;
						max-width: 50%;
					}
				}// .swatch-opt

				.product-info-stock-sku {
					display: none;

					&.mobile {
						display: block;
					}

					@include breakpoint(xlarge) {
						display: block;
						position: absolute;
						left: 16rem;
						top: .35rem;
						line-height: 1;
						float: none;
						width: auto;
						padding-left: 0;
						padding-right: 0;
						margin-bottom: 0;

						.stock {
							line-height: 1;
							width: auto;
							margin-top: 0;
							margin-bottom: 0;
							padding-right: 0;
						}

						&.mobile {
							display: none;
						}
					}
				}// .product-info-stock-sku

				.open-size-guide-modal {
					position: absolute;
					top: 0;
					font-size: ($ts-color-swatch-title-font-size*.75);
					@include get-gutter(.5,'right');
					cursor: pointer;
					z-index: 0;

					@include breakpoint(xlarge) {
						right: auto;
						left: 9.3rem;
						top: .25rem;
						line-height: 1;
						margin-right: 0;
					}
				}// .open-size-guide-modal

				.price-revert-wrapper {
					@include breakpoint(xlarge) {
						position: absolute;
						top: 2.5rem;
						right: 0;
						bottom: auto;
					}
					// @include breakpoint(xxlarge) {
					// 	top: 2rem;
					// }
					@include breakpoint(xxlarge) {
						top: -1rem;
					}
				}// .price-revert-wrapper

				+ .product-info-form-bottom {
					@include breakpoint(xlarge) {
						margin-top: .5rem;
					}
					@include breakpoint(xxlarge) {
						margin-top: 0;
						position: relative;

						.box-tocart {
							position: absolute;
							bottom: -.5rem;
							right: 0;
						}
					}
				}
			}// .is-configurable-product &


			.is-simple-product:not(.is-configurable-product) & {
				.product-info-stock-sku {
					@include grid-col-size(6);

					.stock {
						width: auto;
					}

					@include breakpoint(xxlarge) {
						@include grid-col-size(2.5);
						margin-bottom: 0;
					}
				}
				.price-revert-wrapper {
					bottom: auto;
					top: 0;

					@include breakpoint(xxlarge) {
						@include grid-col-size(4);
						margin-bottom: 0;
					}
					@include breakpoint(xxxlarge) {
						@include grid-col-size(4.25);
					}
				}
			}//.is-simple-product

		} // .product-info-form-wrapper

		.product-info-form-bottom {
			@include grid-row;
			@include grid-row-nest;
			margin-top: rem-calc(5);

			.product-info-stock-sku {
				position: absolute;
				top: 0;
				@include get-gutter(.5,'right');

				.stock {
					display: none;
				}
				@include breakpoint(medium) {
					@include get-gutter(0,'right');
				}
				@include breakpoint(xlarge) {
					position: static;
					display: block;
					@include grid-col;
					@include grid-col-size(6);
					line-height: $input-select-height;

					&:before {
						content: '';
						display: inline-block;
						vertical-align: middle;
						height: 100%;
						margin-right: -.3em;
					}

					.stock {
						display: inline-block;
						margin-left: 0;
					}
				}
			}
		}// + .product-info-form-bottom


		.box-tocart {
			@include grid-col;
			position: relative;

			@include breakpoint(xlarge) {
				@include grid-col-size(10.5);
				float: right;
				text-align: right;
			}
			@include breakpoint(xxlarge) {
				@include grid-col-size(5);
			}
			@include breakpoint(xxxlarge) {
				@include grid-col-size(4.75);
			}

			.field {
				@if not ($ts-product-addtocart-qty__show) {
					@include at;
				}
			}
			.actions {
				width: auto;

				button.action {
					@include button-expand;
					font-size: $ts-product-addtocart-btn-font-size;
					font-weight: $ts-product-addtocart-btn-font-weight;
					font-family: $ts-product-addtocart-btn-font-family;
					padding: 0;
					text-align: center;
					text-transform: $ts-product-addtocart-text-transform;

					@if $ts-product-addtocart-btn-icon-view {
						span {
							display: block;
							padding: $ts-product-addtocart-btn-padding;
							background-image: url("#{$ts-path-to-images}/#{$ts-product-addtocart-btn-icon}.#{$ts-product-addtocart-btn-icon-type}");
							background-repeat: no-repeat;
							background-size: $ts-product-addtocart-btn-icon-size;
							background-position: $ts-product-addtocart-btn-icon-pos;
						}
					} @else {
						padding: $ts-product-addtocart-btn-padding;
					}
				}
			}

			.product-social-links {
				@include breakpoint(xlarge) {
					position: absolute;
					top: 50%;
					right: 100%;
					transform: translateY(-50%);
				}
			}
		}// .box-tocart
	}
}