// Settings for PageBuilder
//////////////////////////////////////////////////////////////////////////

// Icons
$pb-icons__use : true !default;
$pb-icons__font-family : 'pagebuilder-font' !default;
$pb-icons__font-path : '../Magento_PageBuilder/fonts/pagebuilder-icons/pagebuilder-icons' !default;
$pb-icons-arrow--up__icon   : "\f104" !default;
$pb-icons-arrow--down__icon : "\f101" !default;
$pb-icons-arrow--next__icon : "\f102" !default;
$pb-icons-arrow--prev__icon : "\f103" !default;

// Layout
$pb-layout__max-width: $global-width !default;
$pb-responsive-stacking : medium down !default;
$pb-responsive-stacking--off : large !default;


// Slick
$pb-slick-button__position : 2rem !default;
$pb-slick-button-icon__offset : 5px !default;
$pb-slick-button-icon__font-size : 38px !default;
$pb-slick-button__background-color : rgba(252, 252, 252, .6) !default;
$pb-slick-button__background-color--hover : rgba(252, 252, 252, 1) !default;
$pb-slick-button__color : #555 !default;
$pb-slick-button__color--hover : #494949 !default;
$pb-slick-button__background-color--active : rgba(252, 252, 252, .7) !default;
$pb-slick-button__size : 60px !default;
$pb-slick-button--disabled__opacity : .2 !default;
$pb-slick-dots__offset : -30px !default;
$pb-slick-dot__size : 14px !default;
$pb-slick-dot__margin : (0 5px) !default;
$pb-slick-dot__background-color : $white !default;
$pb-slick-dot__background-color--hover : false !default;
$pb-slick-dot--active__background-color : #1c1918 !default;
$pb-slick-dot__border : (1px solid $medium-gray) !default;
$pb-slick-dot__border--hover : (1px solid #b3b3b3) !default;
$pb-slick-dot__border-radius : 100% !default;

// Banner
$pb-banner-overlay__padding : 30px !default;
$pb-banner-button__margin : (20px 0 0 0) !default;


// Block
$pb-block--last__margin-bottom : 1rem !default;


// Buttons
$pb-buttons--stacked__expand : true !default;
$pb-buttons--inline__margin : (0 4px) !default;


// Button
$pb-button-link__text-decoration : $anchor-text-decoration !default;
$pb-button-link__text-decoration--hover : $anchor-text-decoration-hover !default;
$pb-button__font-weight : $button-weight !default;
$pb-button__border-radius : $button-radius !default;
$pb-button__background-color : $button-background !default;
$pb-button__background-color--hover : auto !default;
$pb-button--secondary__background-color : $button-background-secondary !default;
$pb-button--secondary__background-color--hover : $button-background-secondary-hover !default;
$pb-button__color : $button-color !default;


// Column

// Column group

// Heading

// HTML

// Image


// Map
$pb-map__height: 300px !default;


// Products

// Row


// Slide
$pb-slide-overlay__padding : 30px !default;
$pb-slide-button__margin : (20px 0 0 0) !default;


// Slider
$pb-slider__margin-bottom : (35px !important) !default;


// Tabs
$pb-tabs-title__padding : (1rem 2rem) !default;
$pb-tabs-title__font-size : 14px !default;
$pb-tabs-title__font-weight : 600 !default;
$pb-tabs__border : true !default;
$pb-tabs__border-color : $medium-gray !default;
$pb-tabs-title__background-color : #f6f6f6 !default;
$pb-tabs-title__background-color--hover : $pb-tabs-title__background-color !default;
$pb-tabs-title--active__background-color : $white !default;
$pb-tabs-title__color : $black !default;
$pb-tabs-title__color--hover : $anchor-color !default;


// Text
$pb-text--intro__background-color : false !default;
$pb-text--intro__color : false !default;
$pb-text--intro__font-family : $body-font-family !default;
$pb-text--intro__font-size : 22px !default;
$pb-text--intro__font-weight : 300 !default;
$pb-text--intro__line-height : $global-lineheight !default;
$pb-text--intro__padding : 20px !default;
$pb-text--intro__margin : (0 0 1em) !default;


// Video
$pb-video__ratio : 9/16 !default ;