// /**
//  * Copyright © Convert, Inc. All rights reserved.
//  *
//  */

//
//  Default appearance styles
//  _____________________________________________

.pagebuilder-column {
    .widget-product-grid {
        .product-item {

            .price-box {
                word-wrap: break-word;

                .price {
                    white-space: normal;
                }
            }

            .product-item-actions {
                .actions-primary {
                    display: inline-block;
                    max-width: 100%;
                }

                .actions-secondary {
                    display: inline-block;
                    width: auto;
                }

                .tocart {
                    max-width: 100%;
                    white-space: normal;
                }
            }
        }
    }
}