//
// Variables
//

$color__light-grey: #f0f0f0;
$split-wishlist-ul__width: max-content;
$split-wishlist-ul-before-after__width: 0;
$split-wishlist-ul-before-after__height: 0;

//
//  Common
//  ----------------------------------------------

div.split.wishlist {
    position: relative;
    z-index: 2;

    .action.toggle.change {
        display: none;
    }

    button.action.towishlist {
        &:before {
            content: '\71' !important;
        }
    }

    ul.items {
        display: none;
        position: absolute;
        top: 60px;
        right: -51px;
        background-color: $white;
        border: 2px solid $medium-gray;
        padding: 10px;
        z-index: 9999;
        width: $split-wishlist-ul__width;
        border-radius: 8px;

        &:before,
        &:after {
            content: "";
            position: absolute;
            bottom: 100%;
            right: 11px;
            width: $split-wishlist-ul-before-after__width;
            height: $split-wishlist-ul-before-after__height;
        }

        &:before {
            left: 50%;
            border: 12px solid transparent;
            border-bottom-color: $medium-gray;
        }

        &:after {
            left: 52%;
            border: 9px solid transparent;
            border-bottom-color: $white;
        }

        li {
            &:after {
                display: none;
            }
        }
    }

    ul.items.visible {
        display: block;
    }

    ul.items li {
        list-style: none;
        padding: 5px 10px;
        cursor: pointer;
    }

    ul.items li:hover {
        background-color: $color__light-grey;
    }
}
