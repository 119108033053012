.page-title-wrapper {
	&.product {
		margin-bottom: $ts-product-detail-title-bottom-indent;
		font-size: 0;

		@if $ts-product-detail-title-border-bottom {
			@include border-line('bottom');
		} @else {
			&:before {
				display: none;
			}
		}

		.page-title {
			font-size: map-get($ts-product-detail-title-font-size, small);
			font-weight: $ts-product-detail-title-font-weight;
			font-style: $ts-product-detail-title-font-style;
			font-family: $ts-product-detail-title-font-family;
			line-height: $ts-product-detail-title-line-height;
			color:$ts-product-detail-title-color;
			text-transform: $ts-product-detail-title-text-transform;
			display: inline-block;
			vertical-align: top;
			float: none;
			text-align: $ts-product-detail-title-text-align;

			span {
				display: inline-block;
			}

			@include breakpoint(large) {
				font-size: map-get($ts-product-detail-title-font-size, large);
				@include get-gutter($ts-product-detail-title-bottom-inner-indent-coeff, 'margin-bottom');
			}
			@include breakpoint(xxlarge) {
				font-size: map-get($ts-product-detail-title-font-size, xxlarge);
			}
		}
	}
}// .page-title-wrapper.product


@if $ts-product-detail-show-brand-title {
	.product-title-brand {
		line-height: 1;
		text-align: $ts-product-detail-title-text-align;
		font-weight: $ts-product-detail-brand-title-font-weight;
		font-family: $ts-product-detail-brand-title-font-family;
		font-size: $ts-product-detail-brand-title-font-size;
		@include grid-row;
		color: $ts-product-detail-brand-title-font-color;
		text-transform: $ts-product-detail-brand-title-uppercase;
		margin-bottom: $ts-product-detail-brand-title-indent;

		a,
		span {
			display: inline-block;
			color: $ts-product-detail-brand-title-font-color;
			text-decoration: none;
			position: relative;
			line-height: 1;

			@if $ts-product-detail-brand-title-has-border {
				padding-bottom: .4rem;

				&:after {
					display: block;
					content: '';
					position: absolute;
					top: 100%;
					left: 0;
					right: 0;
					border-bottom: $ts-product-detail-brand-title-border;
				}
			}

		}
	}
} @else {
	.product-title-brand {
		display: none;
	}
}

.product-info-wrapper {
	@include grid-row;
	@include grid-row-nest;
	max-width: initial;

	.product.media {
		@include grid-col;
		min-height: rem-calc(300);
		margin-bottom: 2.5rem;
		position: relative;

		@include breakpoint(medium) {
			@include grid-col-size(5);
			min-height: rem-calc(400);
		}

		@include breakpoint(large) {
			@include grid-col-size(6);
		}

		@include breakpoint(xxlarge) {
			margin-bottom: 4rem;
		}
	}// .product.media

	.product-info-main {
		@include grid-col;

		@include breakpoint(medium) {
			@include grid-col-size(7);
		}

		@include breakpoint(large) {
			@include grid-col-size(6);
		}

		.attribute {
			&.sku {
				@if not ($ts-product-detail-show-sku) {
					@include at;
				}
			}
			&.overview {
				p {
					+ ul {
						padding-top: .75rem;
						margin-top: .75rem;
						border-top: 1px solid $ts-main-dark-border-color;

						@include breakpoint(xlarge) {
							padding-top: 1.25rem;
							margin-top: 1.25rem;
						}
					}
				}
				+ .product-attr-list {
					padding-top: .75rem;
					margin-top: .75rem;
					border-top: 1px solid $ts-main-dark-border-color;

					@include breakpoint(xlarge) {
						padding-top: 1.25rem;
						margin-top: 1.25rem;
					}
				}
			}

		}// .attribute
	}// .product-info-main

	.product-info-sidebar {
		position: relative;
		padding: map-get($ts-product-info-sidebar-indent, small);
		padding-left: (map-get($grid-column-gutter, small)/2) + map-get($ts-global-space-indent, small);
		padding-right: (map-get($grid-column-gutter, small)/2) + map-get($ts-global-space-indent, small);
		margin-left: -1*((map-get($grid-column-gutter, small)/2) + map-get($ts-global-space-indent, small));
		margin-right: -1*((map-get($grid-column-gutter, small)/2) + map-get($ts-global-space-indent, small));
		margin-bottom: map-get($ts-product-info-sidebar-bottom-indent, small);
		background-color: $ts-product-info-sidebar-background-color;

		@include breakpoint(medium) {
			margin-left: 0;
			margin-right: 0;
		}

		@include breakpoint(medium) {
			padding: map-get($ts-product-info-sidebar-indent, large);
			margin-bottom: map-get($ts-product-info-sidebar-bottom-indent, large);
		}

		@include breakpoint(xlarge) {
			padding: map-get($ts-product-info-sidebar-indent, xlarge);
			margin-bottom: map-get($ts-product-info-sidebar-bottom-indent, xlarge);
		}
		@include breakpoint(xxlarge) {
			padding: map-get($ts-product-info-sidebar-indent, xxlarge);
			margin-bottom: map-get($ts-product-info-sidebar-bottom-indent, xxlarge);
		}

		.fieldset {
			@include no-fieldset;
			@include disable-mouse-outline;
			@extend .no-outline;
			-webkit-tap-highlight-color: transparent;
		}

		.mailto.friend {
			display: none;
		}
	}// .product-info-sidebar


	.swatch-attribute-selected-option,
	input.super-attribute-select {
		display: none;
	}

	.product-social-links {
		.action {
			&.tocompare,
			&.mailto {
				display: none;
			}
		}
	}// .product-social-links

}// .product-info-wrapper

.product-info-wrapper,
.page-title-wrapper.product {
	.price-revert-wrapper {
		display: none;
		@include get-gutter(.5, 'margin-bottom');

		@include breakpoint(xlarge) {
			@if ($ts-product-detail-title-text-align != center) {
				display: inline-block;
			}
		}

		.price-box {
			display: flex;
			align-items: center;
			flex-direction: row-reverse;
		}
	}// .price-revert-wrapper

	.price-box {
		> .price-container {
			font-weight: $ts-product-detail-price-font-weight-mobile;

			@include breakpoint(large) {
				font-weight: $ts-product-detail-price-font-weight;
			}

			@include breakpoint(xlarge) {
				margin-top: ($ts-product-detail-price-line-top-indent*.75);
			}

			@include breakpoint(xxlarge) {
				margin-top: $ts-product-detail-price-line-top-indent;
			}
		}

		> .price-container,
		.old-price,
		.special-price {
			line-height: $ts-product-detail-price-line-height-mobile;
			position: relative;

			@include breakpoint(xlarge) {
				line-height: $ts-product-detail-price-line-height;
			}
		}
		.old-price {
			font-size: ($ts-product-detail-old-price-font-size * .75);
			font-weight: $ts-product-detail-old-price-font-weight;
			@include header-font-family($ts-product-detail-old-price-font-family);
			color: $ts-product-detail-old-price-color;
			line-height: $ts-product-detail-price-line-height-mobile;
			margin-right: .75rem;
			margin-left: 0;
			margin-top: ($ts-product-detail-price-font-size * .75 / 5);
			text-decoration: $ts-product-detail-old-price-decoration;

			@include breakpoint(xlarge) {
				line-height: $ts-product-detail-price-line-height;
			}

			@include breakpoint(xxlarge) {
				font-size: $ts-product-detail-old-price-font-size;
				margin-top: ($ts-product-detail-price-font-size / 5);
			}
		}
		> .price-container,
		.special-price {
			font-size: ($ts-product-detail-price-font-size * .75);

			@include breakpoint(xxlarge) {
				font-size: $ts-product-detail-price-font-size;
			}

			.mva_label {
				color: $ts-product-detail-old-price-color;
				font-size: ($ts-product-detail-old-price-font-size * .75);
				font-weight: $ts-product-detail-old-price-font-weight;
				position: absolute;
				top: 100%;
				left: 0;
				margin-top: -.5rem;
				letter-spacing: normal;

				@include breakpoint(xlarge) {
					right: 0;
					left: auto;
					margin-top: 0;
				}
			}
		}
	}// .price-box
} //.page-title-wrapper.product, .product-info-wrapper


.product-info-wrapper {
	.price-revert-wrapper {
		display: block;
	}
}

.price-box {
	white-space: nowrap;

	.minimal-price-link {
		display: none;
	}

	@include breakpoint(large) {
		display: inline-block;
		vertical-align: top;
		clear: both;
		float: none !important;
	}

	> .price-container,
	.old-price,
	.special-price {
		display: inline-block;
		vertical-align: middle;
		line-height: $ts-product-detail-price-line-height;
	}

	.old-price {
		font-size: ($ts-product-detail-old-price-font-size * .65);
		@include header-font-family($header-font-family);
		color:rgba($body-font-color, .7);
		margin-left: .25rem;
		white-space: nowrap;
		position: relative;

		// &:after {
		// 	display: block;
		// 	content: '';
		// 	position: absolute;
		// 	top: 50%;
		// 	left: 0;
		// 	right: 0;
		// 	border-bottom:1px solid rgba($body-font-color, .5);
		// }

		.price-label {
			@include label-colon;
		}
	}

	> .price-container,
	.special-price {
		font-size: ($ts-product-detail-price-font-size * .63);
		font-weight: $ts-product-detail-price-font-weight;
		@include header-font-family($ts-product-detail-price-font-family);
		color:$ts-product-detail-price-color;
		text-transform: $ts-product-detail-price-text-transform;

		.price-label {
			@include at;
		}
	}
}// .price-box


.b-product-outofstock {
	white-space: normal;

	.title {
		font-size: rem-calc(18);
		font-weight: 700;
		margin-bottom: .75rem;

		@include breakpoint(xlarge) {
			font-size: rem-calc(24);
		}
	}
	.note-label {
		line-height: 1.2;
		@include label-colon;
		margin-bottom: 1.25rem;

		@include breakpoint(xlarge) {
			margin-bottom: 1.5rem;
		}
	}

	.back-in-stock-header {
		padding-left: 1.5rem;
		background-image: url("#{$ts-path-to-images}/#{$input-invalid-icon}.#{$input-invalid-icon-type}");
		background-position: 0 5px;
		background-repeat: no-repeat;
		background-size: 15px auto;

		@include breakpoint(xlarge) {
			background-position: 0 7px;
		}
	}

	.back-in-stock-form {
		@include breakpoint(xlarge) {
			padding-left: 1.5rem;
			padding-right: 2.5rem;
			@include grid-row;
			@include grid-row-nest;

			.b-input {
				position: relative;
				@include grid-col;
				@include grid-col-size(7);
				@include get-gutter(0, 'padding-right');

				div.mage-error {
					position: absolute;
					top:100%;
					margin-left: 0;
					margin-right: 0;
					@include get-gutter(.5, 'left');
					right: 0;
				}
			}
			.b-action {
				@include grid-col;
				@include grid-col-size(5);
				@include get-gutter(.25, 'padding-left');
			}
		}

		@include breakpoint(xxlarge) {
			padding-right: 5.25rem;
		}
	}

	.b-add-in-favorite {

		.button {
			margin-bottom: 0;
			@include button-expand;
			font-size: rem-calc(15);
		}

		@include breakpoint(xlarge) {
			padding-left: 1.5rem;
			padding-right: 2.5rem;
			@include grid-row;
			@include grid-row-nest;

			.over-button {
				@include grid-col;
			}

			.button {
				padding-left: 2rem;
				padding-right: 2rem;
			}
		}

		@include breakpoint(xxlarge) {
			padding-right: 5.25rem;

			.button {
				font-size: rem-calc(16);
				width: auto;
				display: inline-block;
			}
		}

	}

	.input-text {
		margin-bottom: .5rem;

		+ div.mage-error {
			margin-top: -.5rem;
			margin-bottom: .25rem;
		}

		@include breakpoint(xlarge) {
			margin-bottom: 0;
			+ div.mage-error {
				margin-top: 0;
			}
		}
	}

	.action {
		@include button-expand;
		min-height: $input-select-height;
	}

	+ .product-social-links {
		text-align: center;
		margin-top: 1.5rem;

		+ .product-add-form {
			display: none;
		}

		@include breakpoint(large down) {
			.action.towishlist,
			.action.wishlist-remove-product {
				width: auto;
				text-decoration: none;
				background-size: $ts-addtowishlist-icon-width $ts-addtowishlist-icon-height;
				> span {
					position: static;
					opacity: 1;
					margin: 0 0 0 rem-calc(38);
					padding: 0;
					color: $anchor-color;
					font-size: rem-calc(13);
					background: none;

					&:after {
						display: none;
					}
				}
			}
		}

		@include breakpoint(xlarge) {
			margin-top: 0;
			position: absolute;
			@include get-gutter(.5, 'right');
			bottom: (1.5rem + $ts-addtowishlist-icon-height * .5);
		}

		@include breakpoint(xxlarge) {
			margin-right: 1.25rem;
		}
	}
}// .b-product-outofstock

@if $ts-product-detail-info-text-styled {
	.product.info {
		&.detailed {
			.title {
				display: none;
				font-size: 1rem;
				text-transform: uppercase;
				font-weight: 500;
				margin-bottom: .75rem;
			}
			.product-data {
				margin-bottom: 2.25rem;
				h3, h4, h5, h6 {
					font-size: 1rem;
					line-height: rem-calc(24);
					text-transform: uppercase;
					font-weight: 500;
					margin-bottom: .75rem;
				}
				p {
					margin-bottom: .5rem;
				}

				.product_info_overview {
					@include header-font-family($header-font-family);
					font-size: rem-calc(18);
					line-height: 1.6;
					padding-bottom: 1.25rem;
					margin-bottom: 1.75rem;
					background-image: linear-gradient(to right, rgba($black, .25) 75%, rgba($black,0) 0%);
					background-position: bottom;
					background-size: 11px 1px;
					background-repeat: repeat-x;

					p {
						@include header-font-family($header-font-family);
						font-size: rem-calc(18);
						line-height: 1.6;
					}
				}

				.product_info_description {
					.content:not(:last-child) {
						padding-bottom: 1.5rem;
						margin-bottom: 1.75rem;
						background-image: linear-gradient(to right, rgba($black, .25) 75%, rgba($black,0) 0%);
						background-position: bottom;
						background-size: 11px 1px;
						background-repeat: repeat-x;
					}
				}
			}
		}
	}// .product.info.detailed
}


// Toggled Detail text
@if $ts-product-detail-info-text-toggled {
	.b-decription-toggle {
		&.has-toggle {
			.description-content {
				height: 290px;
				overflow: hidden;
				&:after {
					display: block;
				}
			}
		}
		.more-info-trigger {
			display: none;
			.to-hide{
				display: none;
			}
			&.active{
				.to-hide{
					display: inline;
				}
				.to-show{
					display: none;
				}
			}
		}
		.description-content {
			position: relative;
			transition: height .3s ease;

			p {
				line-height: inherit;
				font-size: inherit;
				color: inherit;
				font-weight: inherit;
			}

			&.open {
				&:after {
					opacity: 0;
					z-index: -1;
				}
			}

			&:after {
				display: none;
				content: '';
				height: 75px;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				opacity: 1;
				background: linear-gradient(rgba($body-background, .26) 0%, rgba($body-background, .47) 20%, rgba($body-background, .7) 40%, rgba($body-background, .87) 60%, $body-background 100%);
				transition: opacity .3s ease;
			}
		}
	}// .b-decription-toggle

}