@mixin fotorama-shadow-gradient($x, $y) {
    background-image: linear-gradient(transparent, rgba($black, .2) 25%, rgba($black, .3) 75%, transparent), radial-gradient(farthest-side at $x $y, rgba($black, .4), transparent);
}
@mixin fotorama__thumb--icon {
    @extend .fotorama-sprite;
    font-size: 0.001px;
    padding-bottom: $ts-fotorama-thumb-arrow;
}


@if $ts-size-fotorama-enable {
    .fotorama--fullscreen {
        background: $body-background;
        bottom: 0 !important;
        float: none !important;
        left: 0 !important;
        margin: 0 !important;
        position: fixed !important;
        right: 0 !important;
        top: 0 !important;
        width: 100% !important;
        z-index: $ts-fotorama-z-index-10 !important;
        @extend .fotorama-gpu;
        .fotorama__wrap {
            max-width: 100% !important;
        }

        .convert__fotorama__view-larger {
            display: none;
        }

        .fotorama__wrap {
            .fotorama__stage {
                margin-bottom: 0;
                overflow: hidden;
                background: transparent;

                @include breakpoint(xlarge) {
                    margin-left: auto;
                }
            }
        }


        .convert__fotorama__header {
            display: block;
            height: 5rem;
            position: absolute;
            top: 0;
            left: 50%;
            width: 100%;
            padding-top: 1.7rem;
            color: $ts-fotorama-header-fullscreen-text-color;
            font-size: map-get($ts-fotorama-header-fullscreen-text-font-size,small);
            @include header-font-family($ts-fotorama-header-fullscreen-text-font-family);
            max-width: map-get($breakpoints, xxxlarge);
            transform: translateX(-50%);
            @include get-gutter(.5, 'padding-left');
            @include get-gutter(.5, 'padding-right');
            z-index: 1;

            @include breakpoint(xlarge) {
                font-size: map-get($ts-fotorama-header-fullscreen-text-font-size,xlarge);
            }

            @include breakpoint(xxlarge) {
                font-size: map-get($ts-fotorama-header-fullscreen-text-font-size,xxlarge);
            }

            + .fotorama__stage {
                padding-top: 5rem;
                padding-bottom: 11.25rem;
                height: 100% !important;
                z-index: 2;
            }

            .convert__fotorama__counter {
                width: 9%;
                display: inline-block;
                vertical-align: top;
                text-align: center;
                line-height: 1.2;
                @include get-gutter(.5, 'padding-left');
                @include get-gutter(.5, 'padding-right');
            }

            .convert__fotorama__caption {
                display: inline-block;
                vertical-align: top;
                width: 45%;
                line-height: 1.2;
                font-weight: $ts-fotorama-header-fullscreen-text-weight;
            }

            &:after {
                content: '';
                display: inline-block;
                vertical-align: middle;
                width: 45%;
                height: 1px;
            }
        }

        .fotorama__stage,
        .fotorama__stage__shaft {
            max-width: map-get($breakpoints, xxxlarge);
            margin-left: auto;
            margin-right: auto;
        }

        .fotorama__fullscreen-icon {
            height: $ts-size-fotorama-block;
            width: auto;
            display: inline-block;
            right: 0;
            top: 0;
            position: absolute;
            font-size: map-get($ts-fotorama-close-fullscreen-text-font-size, small);
            font-weight: $ts-fotorama-close-fullscreen-text-font-weight;
            @include header-font-family($ts-fotorama-close-fullscreen-text-font-family);
            color: $ts-fotorama-close-fullscreen-text-color;
            line-height: $ts-size-fotorama-block;
            white-space: nowrap;
            text-align: right;
            padding-right: $ts-size-fotorama-block;
            background: url("#{$ts-path-to-images}/#{$ts-fotorama-close-fullscreen-icon}.#{$ts-fotorama-close-fullscreen-icon-type}") no-repeat;
            background-position: $ts-fotorama-close-fullscreen-icon-pos !important;
            background-size: $ts-fotorama-close-fullscreen-icon-size auto;
            z-index: 1;

            @include breakpoint(xlarge) {
                font-size: map-get($ts-fotorama-close-fullscreen-text-font-size, xlarge);
            }
            @include breakpoint(xxlarge) {
                font-size: map-get($ts-fotorama-close-fullscreen-text-font-size, xxlarge);
            }

            &:after {
                content: '';
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                position: absolute;
                z-index: 1000;
            }
        }

        .fotorama__nav-wrap--horizontal {
            position: absolute;
            bottom: 1rem;
            left: 5rem;
            right: 5rem;

            @include breakpoint(xxxlarge) {
                left: 12rem;
                right: 12rem;
            }
        }
    }

    .fotorama__fullscreen-icon {
        display: none;
    }

    .convert__fotorama__header {
        display: none;
    }

    .convert__fotorama__view-larger {
        display: none;
        position: absolute;
        color: $anchor-color;
        cursor: pointer;

        @if ($ts-fotorama-default-zoom-logo-placement == center-bottom) {
            top: 100%;
            left: 50%;
            margin-top: .5rem;
            transform: translateX(-50%);
        } @else {
            left: 2rem;
            top: 2rem;
        }

        @include breakpoint(large) {
            display: block;
        }

        span {
            border-bottom: 1px solid $ts-main-dark-border-color;

            @if ($ts-fotorama-default-zoom-logo-text != true) {
                @include at;
            }
        }

        &:before {
            display: inline-block;
            vertical-align: middle;
            margin-right: .35rem;
            content: '';
            width: $ts-fotorama-lens-icon-width;
            height: $ts-fotorama-lens-icon-height;
            background: url("#{$ts-path-to-images}/#{$ts-fotorama-lens-icon}.#{$ts-fotorama-lens-icon-type}") no-repeat;
            background-size: 100% auto;
        }

        &:hover {
            span {
                border-bottom: 0;
            }
        }
    }

    .fotorama__fullscreen {
        overflow: hidden;
        position: relative;
        .fotorama__zoom-in,
        .fotorama__zoom-out {
            cursor: pointer;
            display: block;
            height: $ts-size-fotorama-block;
            overflow: hidden;
            position: absolute;
            width: $ts-size-fotorama-block;
        }
        .fotorama__zoom-out {
            @extend .fotorama-sprite;
            background-position: 0 (-1*$ts-size-fotorama-block) !important;
            bottom: 0;
            &.fotorama__zoom-out--disabled {
                display: none;
            }
        }
        .fotorama__zoom-in {
            @extend .fotorama-sprite;
            background-position: 0 0 !important;
            bottom: 80px;
            &.fotorama__zoom-in--disabled {
                display: none;
            }
        }
        .fotorama__video-close {
            display: none;
        }
    }

    .fotorama--fullscreen .fotorama__loaded--full {
        .fotorama__img {
            display: none;
        }
        .fotorama__img--full {
            display: block;
        }
    }


    .fotorama-button-background {
        background-color: rgba($white, .3);
        transition: background-color $ts-fotorama-duration-time ease-in-out;
        &:hover {
            background-color: rgba($white, .5);
        }
        &:active {
            background-color: rgba(darken($black,83.5294), .5);
        }
    }

    .fotorama__zoom-out:focus,
    .fotorama__zoom-in:focus,
    .fotorama__arr:focus,
    .fotorama__stage__shaft:focus,
    .fotorama__nav__frame--thumb:focus .fotorama__thumb,
    .fotorama__nav__frame--dot:focus .fotorama__dot {
        box-shadow: none;
        &:after {
            border-radius: inherit;
            bottom: $ts-fotorama-inner-box-shadow;
            box-shadow: $ts-fotorama-focus__box-shadow;
            content: '';
            left: $ts-fotorama-inner-box-shadow;
            position: absolute;
            right: $ts-fotorama-inner-box-shadow;
            top: $ts-fotorama-inner-box-shadow;
            z-index: $ts-fotorama-z-index-10;
        }
    }

    .fotorama__nav__frame--thumb:focus,
    .fotorama__nav__frame--dot:focus {
        .fotorama__thumb:after,
        .fotorama__dot:after{
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
        }
        .fotorama__thumb.fotorama_vertical_ratio:after {
            left: 2px;
            right: 2px;
        }
        .fotorama__thumb {
            overflow: inherit;
        }
    }

    .fotorama__nav__frame:nth-child(2):focus {
        .fotorama__thumb:after{
            left: 1px;
        }
        .fotorama__thumb.fotorama_vertical_ratio:after{
            top: 1px;
        }
    }

    .fotorama__nav__frame:last-child:focus {
        .fotorama__thumb:after{
            right: 1px;
        }
        .fotorama__thumb.fotorama_vertical_ratio:after{
            bottom: 1px;
        }
    }

    .fotorama__thumb__arr {
        box-shadow: none;
    }

    .fotorama-stretch {
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }

    .fotorama-grab-cursor {
        cursor: move;
        cursor: -webkit-grab;
        cursor: -moz-grab;
        cursor: -o-grab;
        cursor: -ms-grab;
        cursor: grab;
    }

    .fotorama-grabbing-cursor {
        cursor: move;
        cursor: -webkit-grabbing;
        cursor: -moz-grabbing;
        cursor: -o-grabbing;
        cursor: -ms-grabbing;
        cursor: grabbing;
    }

    .fotorama-gpu {
        transform: translateZ(0);
    }

    .fotorama-focus {
        outline: 0;
    }

    .action-skip-wrapper {
        display: none;
    }

    .fotorama-focus-overlay {
        &:after {
            @extend .fotorama-stretch;
            background-color: map_get($foundation-palette,primary);
            border-radius: inherit;
            content: '';
        }
    }

    .fotorama-transform-disabled {
        transform: none !important;
    }

    .fotorama-transition-for-slide {
        transition-duration: 0ms;
        transition-property: transform, width;
        transition-timing-function: cubic-bezier(0.1, 0, .25, 1);
    }

    .fotorama-no-select {
        user-select: none;
    }

    .fotorama-select {
        user-select: text;
    }

    .fotorama-empty-bg {
        background: url(data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=);
    }

    .fotorama-auto-margin {
        margin: auto;
        padding: 0;
    }

    .fotorama-inline-block {
        -moz-box-orient: vertical;
        display: -moz-inline-box;
        display: inline-block;
        vertical-align: middle;
    }

    .fotorama-content-box {
        box-sizing: content-box;
    }

    .fotorama-hidden {
        left: -99999px;
        position: absolute;
        top: -99999px;
        z-index: -$ts-fotorama-z-index-10;
    }

    .fotorama-visible {
        left: auto;
        opacity: 1;
        position: relative;
        top: auto;
        z-index: auto;
    }

    .fotorama-no-tap {
        -webkit-tap-highlight-color: rgba($black, 0);
    }

    .transitionDuration {
        transition-duration: 333ms;
    }

    .transitionDurationZero {
        transition-duration: 0ms;
    }

    .fotorama-sprite {
        @extend .fotorama-print-background;
        background-image: url("#{$ts-path-to-images}/#{$ts-fotorama-default-sprite-icon}.#{$ts-fotorama-default-sprite-icon-type}");
        background-repeat: no-repeat;
    }

    .fotorama-print-background {
        @media print {
            background: none !important;
        }
    }

    .fotorama__zoom-in,
    .fotorama__zoom-out {
        display: none;
    }

    .fotorama {
        min-width: 1px;
        overflow: hidden;
    }

    .fotorama__wrap {
        height: 100%;
        @extend .fotorama-no-select;
        -webkit-text-size-adjust: 100%;
        direction: ltr;
        position: relative;
        z-index: 0;
    }

    .fotorama__wrap--rtl {
        .fotorama__stage__frame {
            direction: rtl;
        }
    }

    .fotorama__stage,
    .fotorama__nav {
        max-width: 100%;
        overflow: hidden;
        position: relative;
    }

    .fotorama--fullscreen {
        .product-label,
        .convert-label-container {
            display: none;
        }
    }

    .fotorama__wrap--pan-y {
        -ms-touch-action: pan-y;
    }

    .fotorama__stage__shaft {
        @extend .fotorama-stretch;
        @extend .fotorama-no-tap;
        position: relative;
    }

    .fotorama__wrap {
        .fotorama__pointer {
            cursor: pointer;
        }

        .fotorama__grab {
            @extend .fotorama-grab-cursor;
        }
    }

    .fotorama__wrap--slide {
        .fotorama__stage__frame {
            opacity: 1 !important;
        }
    }

    .fotorama__grabbing * {
        @extend .fotorama-grabbing-cursor;
    }

    .fotorama__stage__frame {
        @extend .fotorama-no-tap;
        @extend .fotorama-stretch;
        overflow: hidden;
        &.fotorama__active {
            z-index: 8;
        }

        .product-video {
            height: 100%;
        }
    }

    .fotorama__wrap--fade {
        .fotorama__stage__frame {
            display: none;
        }
        .fotorama__stage__frame.fotorama__active,
        .fotorama__fade-front,
        .fotorama__fade-rear {
            display: block;
            left: 0;
            top: 0;
        }
        .product-video {
            margin-left: $ts-fotorama-arw-size;
            margin-right: $ts-fotorama-arw-size;
        }
        .fotorama__fade-front {
            z-index: 8;
        }
        .fotorama__fade-rear {
            z-index: 7;
            &.fotorama__active {
                z-index: 9;
            }
        }
        .fotorama__stage .fotorama__shadow {
            display: none;
        }
    }

    .fotorama__img {
        border: none !important;
        max-width: inherit;
        opacity: 0;
        .fotorama__loaded &,
        .fotorama__error & {
            opacity: 1;
        }
    }

    .fotorama__img--full {
        display: none;
    }

    .fotorama__html {
        @extend .fotorama-stretch;
    }

    .fotorama__nav {
        @extend .fotorama-auto-margin;
        @extend .fotorama-no-tap;
        display: none;
        font-size: 0;
        line-height: 0;
        white-space: nowrap;
        z-index: 5;
    }

    .fotorama__nav__shaft {
        @extend .fotorama-inline-block;
        @extend .fotorama-no-tap;
        left: 0;
        position: relative;
        text-align: left;
        top: 0;
    }

    .fotorama__nav__frame {
        @extend .fotorama-auto-margin;
        @extend .fotorama-content-box;
        @extend .fotorama-focus;
        @extend .fotorama-inline-block;
        @extend .fotorama-no-tap;
        position: relative;
    }

    .fotorama__nav--dots {
        display: block;
        text-align: center;
        .fotorama__nav__frame {
            height: 30px;
            width: 18px;
        }
        .fotorama__nav__frame--thumb,
        .fotorama__thumb-border {
            display: none;
        }
    }

    .fotorama__nav--thumbs {
        display: block;
        .fotorama__nav__frame {
            padding-left: 0 !important;
            &:last-child {
                padding-right: 0 !important;
            }
        }
        .fotorama__nav__frame--dot {
            display: none;
        }
    }

    .fotorama__active {
        .fotorama__dot {
            background-color: map_get($foundation-palette,primary);
            border-color: map_get($foundation-palette,primary);
        }
    }

    .fotorama__nav__frame {
        &.fotorama__active & {
            border-width: 3px;
            height: 0;
            width: 0;
            &:after {
                left: -3px;
                padding: 3px;
                top: -3px;
            }
        }
    }

    // .fotorama__nav__frame:focus & {
    //     @extend .fotorama`-focus-overlay;
    //     box-shadow: none;
    //     &:after {
    //         left: -1px;
    //         padding: 1px;
    //         top: -1px;
    //     }
    // }

    .fotorama__dot {
        border-radius: 6px;
        border: 1px solid $medium-gray;
        display: block;
        height: 4px;
        left: 6px;
        position: relative;
        top: 12px;
        width: 4px;
    }

    .fotorama__thumb {
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 100%;
        .fotorama__nav__frame:focus & {
            @extend .fotorama-focus-overlay;
            z-index: 2;
        }
    }

    .fotorama__thumb-border {
        @extend .fotorama-print-background;
        backface-visibility: hidden;
        background: rgba($white, .5) !important;
        border: 1px solid rgba($black, .2) !important;
        left: 0;
        position: absolute;
        top: 0;
        z-index: 9;
    }

    .fotorama__caption {
        @extend .fotorama-gpu;
        bottom: 0;
        color: $black;
        font-size: 14px;
        left: 0;
        line-height: 1.5;
        position: absolute;
        right: 0;
        z-index: 12;
        a {
            border-bottom: 1px solid;
            border-color: scale-color($black, $alpha: -50%);
            color: $black;
            text-decoration: none;
            &:hover {
                border-color: scale-color($black, $alpha: -50%);
                color: $black;
            }
        }

        .fotorama__wrap--rtl & {
            left: auto;
            right: 0;
        }

        .fotorama__wrap--video &,
        .fotorama__wrap--no-captions & {
            display: none;
        }
    }

    .fotorama__caption__wrap {
        @extend .fotorama-inline-block;
        background-color: $white;
        padding: 5px 10px;
    }

    .fotorama--hidden {
        @extend .fotorama-hidden;
    }

    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .fotorama__wrap--css3 {
        .fotorama__stage__shaft,
        .fotorama__nav__shaft,
        .fotorama__thumb-border {
            @extend .fotorama-transition-for-slide;
        }
        .fotorama__stage,
        .fotorama__nav,
        .fotorama__stage__frame {
            @extend .fotorama-gpu;
        }
        .fotorama__html {
            @extend .fotorama-gpu;
            transition-duration: $ts-fotorama-duration-time;
            transition-property: opacity;
            transition-timing-function: linear;
        }

        .fotorama__stage__shaft {
            max-width: 100% !important;
        }
    }

    .fotorama__wrap--video {
        .fotorama__stage__shaft,
        .fotorama__stage,
        .fotorama__stage__frame--video {
            @extend .fotorama-transform-disabled;
        }
        .fotorama__stage__frame--video {
            .fotorama__img,
            .fotorama__html {
                @extend .fotorama-transform-disabled;
                opacity: 0;
            }
        }
    }

    .fotorama__select {
        @extend .fotorama-select;
        cursor: auto;
    }

    .fotorama__no-select {
        @extend .fotorama-no-select;
    }

    .fotorama__load {
        @extend .fotorama-hidden;
    }

    .fotorama__video {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 32px;
        z-index: 10;
        iframe {
            @extend .fotorama-stretch;
        }
    }

    @-moz-document url-prefix() {
        .fotorama__active {
            box-shadow: 0 0 0 transparent;
        }
    }

    .fotorama__arr,
    .fotorama__fullscreen-icon,
    .fotorama__video-close {
        @extend .fotorama-no-select;
        @extend .fotorama-no-tap;
        cursor: pointer;
        position: absolute;
    }

    .fotorama__arr {
        z-index: $ts-fotorama-z-index-9;

    }
    .fotorama__fullscreen-icon,
    .fotorama__zoom-out,
    .fotorama__zoom-in,
    .fotorama__video-close {
        z-index: $ts-fotorama-z-index-10;
    }

    .fotorama__arr {
        bottom: $ts-fotorama-arw-size;
        position: absolute;
        top: $ts-fotorama-arw-size;
        width: $ts-fotorama-arw-size;

        @if ($ts-fotorama-default-zoom-logo-placement == left-top) {
            height: $ts-size-fotorama-block;
            top: 50%;
            bottom: auto;
            margin-top: -($ts-size-fotorama-block/2);
        }

        .fotorama__arr__arr {
            @extend .fotorama-abs-center;
            height: $ts-size-fotorama-block;
            width: $ts-size-fotorama-block;
            background-image: url("#{$ts-path-to-images}/#{map-get($ts-fotorama-horiz-arrows-icon, xlarge)}.#{map-get($ts-fotorama-horiz-arrows-icon-type, xlarge)}");
            background-repeat: no-repeat;
            background-size: map-get($ts-fotorama-horiz-arrows-icon-size, xlarge) auto;
            opacity: $ts-fotorama-horiz-arrows-icon-opacity;

            .ie9 & {
                margin: (-1*$ts-size-fotorama-block/2) 0 0 (-1*$ts-size-fotorama-block/2);
            }
        }

        &:hover,
        &:active {
            .fotorama__arr__arr {
                opacity: 1;
            }
        }
    }

    .fotorama__arr--prev {
        left: 0;
        .fotorama__arr__arr {
            background-position: 50% 50%;
            transform: translate3d(-50%, -50%, 0) rotate(180deg) !important;
        }
    }

    .fotorama__arr--next {
        right: 0;
        .fotorama__arr__arr {
            background-position: 50% 50%;
        }
    }

    .fotorama__arr--disabled {
        cursor: default;
        opacity: 0.1;
        pointer-events: none;
    }

    .fotorama--fullscreen-icons {
        .fotorama__fullscreen-icon {
            display: none;
        }
    }

    .fotorama__fullscreen-icon,
    .fotorama__zoom-out,
    .fotorama__zoom-in {
        &:focus {
            @extend .fotorama-focus;
        }
    }

    .fotorama__video-play {
        @extend .fotorama-sprite;
        @extend .fotorama-abs-center;
        background-position: (-1*$ts-size-fotorama-block) (-1*$ts-size-fotorama-block*2);
        height: $ts-size-fotorama-block * 2;
        width: $ts-size-fotorama-block * 2;

        .fotorama__wrap--css2 &,
        .fotorama__wrap--video .fotorama__stage & {
            display: none;
        }

        .fotorama__loaded &,
        .fotorama__error &,
        .fotorama__nav__frame & {
            display: block;
            opacity: 1;
        }

        .fotorama__nav__frame & {
            background-position: 0 (-1*$ts-size-fotorama-block*3);
            height: $ts-size-fotorama-block;
            width: $ts-size-fotorama-block;
        }
    }

    .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows {
        .fotorama__arr {
            opacity: 0;
            &:focus {
                opacity: 1;
            }
        }
    }

    .fotorama__wrap--toggle-arrows {
        &:not(.fotorama__wrap--video) {
            .fotorama__video-close {
                display: none;
            }
        }
    }

    .fotorama__wrap--toggle-arrows {
        &.fotorama__wrap--video {
            .fotorama__video-close {
                opacity: 1;
                right: 93px;
                top: 97px;
            }
        }
    }

    .fotorama__wrap--toggle-arrows {
        &:not(.fotorama__wrap--video) {
            .fotorama__video-close {
                display: none;
            }
        }
    }

    .fotorama__wrap--toggle-arrows {
        &.fotorama__wrap--video {
            .fotorama__video-close {
                opacity: 1;
                right: 93px;
                top: 97px;
            }
        }
    }

    .fotorama__wrap--toggle-arrows {
        &:not(.fotorama__wrap--video) {
            .fotorama__video-close {
                display: none;
            }
        }
    }

    .fotorama__wrap--toggle-arrows {
        &.fotorama__wrap--video {
            .fotorama__video-close {
                opacity: 1;
                right: 93px;
                top: 97px;
            }
        }
    }

    .fotorama__wrap--video {
        .fotorama__arr,
        .fotorama__fullscreen-icon {
            opacity: 0 !important;
        }
    }

    .fotorama__wrap--css2 {
        &.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows {
            .fotorama__arr,
            .fotorama__fullscreen-icon {
                display: none;
                &:focus {
                    display: block;
                }
            }
        }
        &.fotorama__wrap--video {
            .fotorama__arr,
            .fotorama__fullscreen-icon {
                display: none !important;
            }
        }
    }

    .fotorama__wrap--css3 {
        &.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows {
            .fotorama__fullscreen-icon:not(:focus) {
                transform: translate3d($ts-fotorama-arw-size, -$ts-fotorama-arw-size, 0);
            }
            .fotorama__arr--prev:not(:focus) {
                transform: translate3d(-$ts-fotorama-arw-size * 1.5, 0, 0);
            }
            .fotorama__arr--next:not(:focus) {
                transform: translate3d($ts-fotorama-arw-size * 1.5, 0, 0);
            }
        }
        &.fotorama__wrap--video {
            .fotorama__fullscreen-icon {
                transform: translate3d($ts-fotorama-arw-size, -$ts-fotorama-arw-size, 0) !important;
            }
            .fotorama__arr--prev {
                transform: translate3d(-$ts-fotorama-arw-size * 1.5, 0, 0) !important;
            }
            .fotorama__arr--next {
                transform: translate3d($ts-fotorama-arw-size * 1.5, 0, 0) !important;
            }
        }
    }

    .fotorama__wrap--css3 {
        .fotorama__arr:not(:focus),
        .fotorama__fullscreen-icon:not(:focus),
        .fotorama__video-play:not(:focus),
        .fotorama__video-close:not(:focus) {
            transition-duration: $ts-fotorama-duration-time;
            transition-property: transform, opacity, background-color;
        }
    }

    .fotorama__stage,
    .fotorama__nav {
        &:before,
        &:after {
            @extend .fotorama-gpu;
            background-repeat: no-repeat;
            content: '';
            display: block;
            pointer-events: none;
            position: absolute;
            text-decoration: none;
            z-index: 10;
        }
        &:before {
            left: -10px;
            top: -10px;
        }
        &:after {
            right: -10px;
            bottom: -10px;
        }
        &.fotorama__shadows--left:before,
        &.fotorama__shadows--right:after {
            background-size: 1px 100%, 5px 100%;
            bottom: 0;
            height: auto;
            top: 0;
            width: 10px;
        }
        &.fotorama__shadows--top:before,
        &.fotorama__shadows--bottom:after {
            background-size: 100% 1px, 100% 5px ;
            height:10px;
            left: 0;
            right:0;
            width:auto;
        }
        &.fotorama__shadows--left:before {
            @include fotorama-shadow-gradient(0, 50%);
            background-position: 0 0, 0 0;
            left: 0;
        }

        &.fotorama__shadows--right:after {
            @include fotorama-shadow-gradient(100%, 50%);
            background-position: 100% 0, 100% 0;
            right: 0;
        }
        &.fotorama__shadows--top:before {
            @include fotorama-shadow-gradient(50%, 0);
            background-position: 0 0, 0 0;
            top:0;

        }
        &.fotorama__shadows--bottom:after {
            @include fotorama-shadow-gradient(50%, 100%);
            background-position: 0 100%, 0 100%;
            bottom:0;
        }
    }

    .fotorama--fullscreen .fotorama__stage,
    .fotorama--fullscreen .fotorama__nav,
    .fotorama__wrap--no-shadows .fotorama__stage,
    .fotorama__wrap--no-shadows .fotorama__nav,
    .fotorama__wrap--fade .fotorama__stage {
        &:before,
        &:after {
            display: none;
        }
    }

    @if $ts-product-photo-overlay {
        .fotorama__wrap--no-shadows .fotorama__stage {
            &:before {
                display: block;
            }
        }
    }

    .fotorama-abs-center {
        transform: translate3d(-50%, -50%, 0);
        left: 50%;
        position: absolute;
        top: 50%;
    }

    .fotorama__nav-wrap {
        .fotorama_vertical_ratio {
            .fotorama__img {
                transform: translateY(-50%);
                height: auto;
                position: absolute;
                top: 50%;
                width: 100%;
            }
        }
        .fotorama_horizontal_ratio {
            .fotorama__img {
                transform: translateX(-50%);
                height: 100%;
                left: 50%;
                position: absolute;
                width: auto;
            }
        }
    }

    .magnifier-thumb-wrapper {
        display: block;
        left: 0;
        position: relative;
        top: 0;
    }

    .magnifier-lens {
        border: solid 1px $light-gray;
        left: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: $ts-fotorama-z-index-8;
    }

    .magnify-lens {
        background: rgba($white, .5);
        border: solid 1px $light-gray;
        color: $white;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        z-index: $ts-fotorama-z-index-10;

        @include breakpoint(medium down) {
            display: none !important;
        }
    }

    .magnifier-loader-text {
        margin-top: 10px;
    }

    .magnifier-xlarge {
        position: absolute;
        width: 32%;
        z-index: $ts-fotorama-z-index-1;
    }

    .magnifier-preview {
        left: 100% !important;
        overflow: hidden;
        padding: 0;
        position: absolute;
        top: 0 !important;
        z-index: 1;
        box-shadow: 0 0 5px rgba($black, .5);

        &:not(.hidden) {
            background-color: $white;
        }
        img {
            left: 0;
            max-width: inherit;
            position: absolute;
            top: 0;
        }
    }

    .fotorama__stage__frame {
        text-align: center;
        .fotorama__img {
            height: auto;
            left: 50%;
            max-height: 100%;
            max-width: 100%;
            position: absolute;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            transition-duration: $ts-fotorama-fullscreen-zoom-time;
            transition-property: width, height, top, left;
            vertical-align: middle;
            width: auto;
        }
    }

    .magnify-opaque {
        opacity: .5;
    }

    .magnify-hidden {
        display: none;
    }

    .fotorama__nav-wrap--vertical {
        &.fotorama__nav-wrap {
            display: inline-block;
            left: 0;
            position: absolute;
            top: 0;
        }

        .fotorama__nav__shaft {
            background-color: $body-background;
            width: 100%;
            .fotorama__nav__frame--thumb {
                display: block;
                padding-bottom: inherit !important;
            }
        }
    }

    .fotorama--fullscreen {
        .fotorama__stage__frame {
            .fotorama__img {
                display: none;
            }
            .fotorama__img,
            .fotorama__img--full {
                bottom: 0;
                left: 0;
                margin: auto;
                max-height: inherit;
                max-width: inherit;
                position: absolute;
                right: 0;
                top: 0;
            }
            .fotorama__img--full {
                cursor: default;
                display: block;
                height: auto;
                left: 0;
                margin: auto;
                max-height: 100%;
                max-width: 100%;
                top: 0;
                transition: $ts-fotorama-fullscreen-zoom-time linear;
                vertical-align: middle;
                width: auto;
                @extend .fotorama-gpu;
                &.fotorama__img--zoommable {
                    cursor: pointer;
                    max-height: none;
                    max-width: none;
                    transition-property: width, height, bottom, right, top, left;
                }
                &.fotorama__img--draggable {
                    cursor: move;
                    transition-property: none;
                }
            }
            iframe {
                left: $ts-fotorama-arw-size;
                // width: calc(~'100% - ${fotorama-arw-size} * 2');
            }
        }
    }

    .fotorama__nav-wrap--horizontal {
        .fotorama--fullscreen & {
            text-align: center;
            .fotorama__nav__shaft {
                display: inline-block;
            }

            .fotorama__thumb__arr--right,
            .fotorama__thumb__arr--left {
                display: inline-block;
                vertical-align: middle;
                position: relative;
                left: auto;
                right: auto;
                height: 100%;
            }
        }

        .fotorama__thumb__arr--left,
        .fotorama__thumb__arr--right {
            bottom: 0;
            cursor: pointer;
            position: absolute;
            top: 0;
            width: $ts-fotorama-thumb-arrow;
            z-index: $ts-fotorama-z-index-10;
            .fotorama__thumb--icon {
                @extend .fotorama-abs-center;
                width: 100%;
                height: 100%;
                background:rgba($white, .75) url("#{$ts-path-to-images}/#{map-get($ts-fotorama-horiz-arrows-icon, small)}.#{map-get($ts-fotorama-horiz-arrows-icon-type, small)}") no-repeat 100% 50%;
                background-size: map-get($ts-fotorama-horiz-arrows-icon-size, small) auto;
                background-position: 50%;

                .ie9 & {
                    margin: (-$ts-fotorama-thumb-arrow/2) 0 0 (-$ts-fotorama-thumb-arrow/2);
                }
            }
        }
        .fotorama__thumb__arr--left {
            left: 0;

            .fotorama__thumb--icon {
                transform: translate3d(-50%, -50%, 0) rotate(180deg);
            }
        }
        .fotorama__thumb__arr--right {
            right: 0;
        }
    }

    .fotorama__nav-wrap--vertical {
        .fotorama__thumb__arr--left,
        .fotorama__thumb__arr--right {
            cursor: pointer;
            height: $ts-fotorama-thumb-arrow;
            left: 0;
            position: absolute;
            right: 0;
            z-index: $ts-fotorama-z-index-10;
            .fotorama__thumb--icon {
                transform: rotate(90deg);
                margin: auto;
                width: $ts-fotorama-thumb-arrow;
            }
        }
        .fotorama__thumb__arr--left {
            top: 0;
        }
        .fotorama__thumb__arr--right {
            bottom: 0;
        }
    }

    .fotorama__wrap--only-active {
        .fotorama__stage,
        .fotorama__nav {
            max-width: 99999px !important;
        }
        .fotorama__stage__frame {
            visibility: hidden;
        }
        .fotorama__stage__frame.fotorama__active {
            visibility: visible;
        }
    }

    @include breakpoint(medium down) {

        .fotorama__arr--next,
        .fotorama__arr--prev {
            width: 40px;

            .fotorama__arr__arr {
                width: 100%;
                background: url("#{$ts-path-to-images}/#{map-get($ts-fotorama-horiz-arrows-icon, small)}.#{map-get($ts-fotorama-horiz-arrows-icon-type, small)}") no-repeat 100% 50%;
                background-size: map-get($ts-fotorama-horiz-arrows-icon-size, small) auto;
            }
        }

        .fotorama__arr--prev {
            .fotorama__arr__arr {
                transform: translate3d(-50%, -50%, 0) rotate(180deg);
            }
        }

        .fotorama__nav-wrap {
            text-align: center;

            .fotorama__nav {
                height: auto !important;
                width: auto !important;
            }

            .fotorama__nav__frame {
                height: $ts-fotorama-mobile-dots-size !important;
                width: $ts-fotorama-mobile-dots-size !important;
                padding: 0 !important;
                border-radius: $ts-fotorama-mobile-dots-radius;
                margin: $ts-fotorama-mobile-dots-spacing;
                overflow: hidden;

                .fotorama__thumb {
                    background: $ts-fotorama-mobile-dots-bg;
                }

                img {
                    display: none;
                }

                &.fotorama__active {
                    .fotorama__thumb {
                        background: $ts-fotorama-mobile-dots-bg-active;
                    }
                }
            }
            .fotorama__thumb-border,
            .fotorama__thumb__arr {
                display: none !important;
            }
        }
    }

    .magnify-fullimage {
        display: none;
    }

    // .fotorama__arr,
    // .fotorama__thumb__arr {
    //     @extend .fotorama-button-background;
    // }

    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) {
        .fotorama__fullscreen-icon,
        .fotorama__zoom-out,
        .fotorama__zoom-in{
            @extend .fotorama-button-background;
        }
    }

    .fotorama__video-close {
        @extend .fotorama-sprite;
        background-position: (-$ts-fotorama_close_button) 0;
        height: $ts-fotorama_close_button;
        opacity: 0;
        right: 0;
        top: 0;
        transform: translate3d(($ts-fotorama_close_button), (-$ts-fotorama_close_button), 0);
        transition: opacity 0.3s ease-in-out;
        width: $ts-fotorama_close_button;
        @media all and (max-width: 768px) {
            background-position: -100px -20px;
            top: 10px;
            height: 40px;
            width: 40px;
        }
        &.fotorama-show-control {
            opacity: 1;
            transform: translate3d(0, -10px, 0);
        }
    }

    // While first time init
    .gallery-placeholder {
        .loading-mask {
            .loader {
                display: block;
            }
        }
    }

    // Styles for spinner in gallery.
    .fotorama__spinner {
        @include loading-overlay;
        &.fotorama__spinner--show {
            display: block;
        }
    }
    .fotorama__product-video--loaded {
        .fotorama__img, .fotorama__img--full {
            display: none !important;
        }
    }

    .fotorama__stage {
        box-shadow: none !important;
        margin-bottom: 1rem;
        overflow: visible;
        background: $ts-size-fotorama-bg;

        .amasty-label-container {
            opacity: 0;
            &:last-child {
                opacity: 1;
            }
        }

        @include breakpoint(large) {
            margin-bottom: 2rem;
        }

        .fotorama__arr--shown {
            display: block !important;
        }

        .fotorama__arr--hidden {
            display: none !important;
        }
    }

    @if not $ts-size-fotorama-vertical-thumbs {
        .fotorama-item {
            padding-bottom: 2rem;
            background: $ts-size-fotorama-bg;

            .fotorama__nav--thumbs {
                text-align: center;
            }

            .fotorama__nav-wrap--horizontal {
                padding: 1rem 2rem 0;
            }
            &.fotorama--fullscreen {
                padding-bottom: 0;
                .fotorama__nav-wrap--horizontal {
                    padding: 0;
                }
            }
        }
    }

    // Vertical Thumbs View
    @if $ts-size-fotorama-vertical-thumbs {
        .fotorama__stage,
        .fotorama__nav {
            &:before {
                left: 0;
                bottom: 0;
            }
        }

        .fotorama__stage {

            @if $ts-product-photo-overlay {
                @extend %product-layer-overlay;
                .fotorama--fullscreen & {
                    &:before {
                        display: none !important;
                    }
                }
            }

            @include breakpoint(large) {
                .fotorama__wrap & {
                    margin-left: 125px;
                    left: 0 !important;
                    position: static !important;
                    width: auto !important;
                }
                .fotorama--fullscreen & {
                    margin-left: auto;
                    position: relative !important;
                }
            }
        }// .fotorama__stage

        .fotorama__nav-wrap--vertical {

            .fotorama__nav__shaft {
                .fotorama__nav__frame--thumb {
                    position: relative;
                    @if $ts-product-photo-overlay {
                        @extend %product-layer-overlay;
                        &:before {
                            top: 10px;
                            display: block !important;
                        }
                    }
                }
            }

            .fotorama__thumb__arr--right,
            .fotorama__thumb__arr--left {
                .fotorama__thumb--icon {
                    height: 30px;
                    transform: rotate(90deg);
                    top:50%;
                    background: url("#{$ts-path-to-images}/#{map-get($ts-fotorama-horiz-arrows-icon, small)}.#{map-get($ts-fotorama-horiz-arrows-icon-type, small)}") no-repeat 50%;
                    background-size: map-get($ts-fotorama-horiz-arrows-icon-size, small) auto;
                }
            }

            .fotorama__thumb__arr--left {
                .fotorama__thumb--icon {
                    transform: rotate(-90deg);
                }
            }

            &.fotorama__nav-wrap {
                @include breakpoint(medium down) {
                    right: 0;
                    top: auto;
                    bottom: 0;

                    .fotorama__nav__shaft {
                        background: transparent;
                        width: auto;

                        .fotorama__nav__frame--thumb {
                            display: inline-block;
                            vertical-align: middle;
                        }
                    }
                }
                .fotorama_vertical_ratio {
                    .fotorama__img {
                        width: calc(100% - 10px);
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                    }
                }
            }
        }// .fotorama__nav-wrap--vertical

        .fotorama__arr--next {
            right: 0 !important;
        }// .fotorama__arr--next

        @include breakpoint(medium down) {
            .fotorama__wrap--css3 {
                .fotorama__stage {
                    margin-bottom: 1.5rem;
                }
                .fotorama__stage,
                .fotorama__stage__shaft {
                    width: 100% !important;
                    left: 0 !important;
                    max-width: inherit !important;
                }

                .fotorama__arr {
                    &.fotorama__arr--next {
                        right: 0 !important;
                    }

                    .fotorama__arr__arr {
                        background-position: 50% !important;
                    }
                }
            }
        }// @include breakpoint(medium down)
    }// @if $ts-size-fotorama-vertical-thumbs

    //HOYERCARE-390 PLAT-3 - Fixes after Magento upgrade to 2.2.6
    .catalog-product-view {
        .fotorama__caption {
            @include element-invisible;
        }
    }
    .amlabel-position-wrapper {
        z-index: 2 !important;

        .fotorama--fullscreen & {
            display: none !important;
        }
    }
}
