// /**
//  * Copyright © Convert, Inc. All rights reserved.
//  *
//  */

//
//  Default appearance styles
//  _____________________________________________

div[data-content-type="tabs"] {
    .tabs-navigation {
        display: block;
        font-size: 0;
        margin: 0;
        @if $pb-tabs__border {
        	margin-bottom: -1px;
        }
        padding: 0;

        li:first-child {
            margin-left: 0 !important;
        }

        li.tab-header {
            background: $pb-tabs-title__background-color;
            @if $pb-tabs__border {
            	border: 1px solid $pb-tabs__border-color;
	            border-bottom: 0;
	            border-bottom-left-radius: 0 !important;
	            border-bottom-right-radius: 0 !important;
        	}
            display: inline-block;
            margin: 0;
            max-width: 100%;
            overflow-wrap: break-word;
            position: relative;
            word-wrap: break-word;
            z-index: 1;

            &:not(:first-child) {
                margin-left: -1px;
            }

            a.tab-title {
                border-right: 0;
                color: $pb-tabs-title__color;
                cursor: pointer !important;
                display: block;
                font-size: $pb-tabs-title__font-size;
                font-weight: $pb-tabs-title__font-weight;
                padding: $pb-tabs-title__padding;
                position: relative;
                transition: all .3s;
                vertical-align: middle;
                white-space: normal;
                text-decoration: none;

                span.tab-title {
                    display: block;
                }

                &:hover {
                    text-decoration: none;
                    background-color: $pb-tabs-title__background-color--hover;
                    color: $pb-tabs-title__color--hover;
                }
            }

            &:last-child {
            	@if $pb-tabs__border {
                	border-right: 1px solid $pb-tabs__border-color;
                }
            }

            &.ui-state-active {
                background: $pb-tabs-title--active__background-color;
                z-index: 19;

                a.tab-title {
                    position: relative;
                    transition: all .3s;
                }
            }
        }
    }

    .tabs-content {
    	@if $pb-tabs__border {
        	border: 1px solid $pb-tabs__border-color;
        }
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        z-index: 9;

        [data-content-type="tab-item"] {
            box-sizing: border-box;
            min-height: inherit;

            &:not(:first-child) {
                display: none;
            }
        }
    }

    &.tab-align-left {
        .tabs-content {
            border-top-left-radius: 0 !important;
        }
    }
    &.tab-align-right {
        .tabs-content {
            border-top-right-radius: 0 !important;
        }
    }
}

//
//  Responsive Classes
//  _____________________________________________

@include breakpoint ($pb-responsive-stacking) {
    [data-content-type="tab-item"] {
        background-attachment: scroll !important;
    }
}
