.header {

	.top-links,
	.links {
		@include lsn;
		font-size: 0;

		li {
			display: inline-block;
			vertical-align: middle;
			font-size: $ts-headbar-anchor-size;
			color: $ts-headbar-anchor-color;
			font-weight: $ts-headbar-anchor-font-weight;

			&.customer-section {
				padding-left: ($ts-top-links-anchor-padding/2);

				@include breakpoint(xxlarge) {
					padding-left: $ts-top-links-anchor-padding;
				}

				a {
					padding-left: 1.25rem;

					&:before {
						display: block;
						content: '';
						width: $ts-top-links-user-icon-width;
						height: $ts-top-links-user-icon-height;
						position: absolute;
						top:50%;
						left: 0;
						transform: translateY(-50%);
						background: url("#{$ts-path-to-images}/#{$ts-top-links-user-icon}.#{$ts-top-links-user-icon-type}") 50% 50% no-repeat;
						background-size: 100% auto;
					}

					&:after {
						display: block;
						content: '';
						width: 1px;
						position: absolute;
						top: -.25rem;
						bottom: -.25rem;
						right: 0;
						background: rgba($white, .2);
					}
				}
			}

			a {
				display: block;
				font-size: $ts-top-links-anchor-font-size;
				color: $ts-headbar-anchor-color;
				line-height: $ts-top-links-anchor-line-height;
				text-transform: $ts-top-links-anchor-text-transform;
				padding: 0 ($ts-top-links-anchor-padding/2.25);
				position: relative;
				font-family: $ts-top-links-anchor-font-family;
				font-weight: $ts-top-links-anchor-font-weight;
				text-decoration: $ts-top-links-anchor-text-decoration;

				@if ( $ts-top-links-anchor-text-shadow != none ) {
					text-shadow: $ts-top-links-anchor-text-shadow;
				}

				// @include breakpoint(large) {
				// 	line-height: inherit;
				// }

				@include breakpoint(xlarge) {
					padding: 0 ($ts-top-links-anchor-padding/1.5);
				}

				@include breakpoint(xxlarge) {
					padding: 0 $ts-top-links-anchor-padding;
				}

				&:hover {
					text-decoration: $ts-top-links-anchor-text-decoration-hover;
				}
			} // a

			@include breakpoint(xxxlarge) {
				&:last-child {
					a {
						padding-right: 0;
					}
				}
			}
		}// li
	} // .top-links

	.top-links,
	.header.links {
		margin-left: -($ts-top-links-anchor-padding/2.25);

		@include breakpoint(xlarge) {
			margin-left: -($ts-top-links-anchor-padding/1.5);
		}

		@include breakpoint(xxlarge) {
			margin-left: -($ts-top-links-anchor-padding);
		}
	}

	.links {
		.greet,
		.counter,
		#invitation-send-link,
		.customer-welcome,
		.compare {
			display: none;
		}

		.wishlist {
			@include show-for(xlarge);
		}
	}

}// .header
