//
// Variables
// _____________________________________________
$filter-options-a__font-size: 1.0625rem;
$filter-options-a-after__font-family: 'Convert Sharp';
$filter-options-a-after__font-size: 0.875rem;
$filter-options-a-after__line-height: 1.625rem;
$filter-options-a-after__width: 14px;
$filter-options-a-after__height: 26px;
$filter-options-items-children__width: 90%;
$toolbar-products-bottom__max-width: 100%;
$toolbar-products-bottom__width: 70px;
$toolbar-products-bottom-mobile__width: 100%;


//
// Common
// _____________________________________________
.catalog-category-view {
    .item.product.product-item {
        position: relative;
        z-index: unset;

        .product-item-info {
            z-index: unset;

            .product-item-photo {
                position: relative !important;

                .product-image-container {
                    z-index: unset;

                    &:before {
                        z-index: unset;
                    }
                }
            }
        }
    }

    .sidebar-main .filter-options-content,
    .category-header-mobile.show-for-small-only .filter-options-content {
        .items {
            li.item {
                border-top: 1px solid rgba(0, 0, 0, 0.15);
                margin-bottom: 0;

                &.hide-arrow.-filter-parent {
                    a:after {
                        display: none;
                    }
                }

                &.-is-collapsible {
                    a:after {
                        display: block;
                    }
                }

                a.amshopby-filter-parent {
                    font-size: $filter-options-a__font-size;
                    color: $black;
                    padding: 12px 10px 14px;
                    background: $white;
                    transition: background-color .25s ease-out;

                    &:after {
                        display: block;
                        font-family: $filter-options-a-after__font-family;
                        content: '\32';
                        color: rgba(0, 0, 0, 0.7);
                        font-size: $filter-options-a-after__font-size;
                        line-height: $filter-options-a-after__line-height;
                        width: $filter-options-a-after__width;
                        height: $filter-options-a-after__height;
                        background: none;
                        position: absolute;
                        top: 15px;
                        left: 95%;
                    }

                    &:before {
                        display: none;
                    }
                }

                ul {
                    position: relative;
                    left: 35px !important; // override inline styles
                    top: 0 !important; // override inline style
                    width: $filter-options-items-children__width;
                }
            }
        }
    }

    .category-header-mobile.show-for-small-only {
        .filter-options-content.has-labels {
            ul.items {
                position: relative;

                li.item {
                    a.amshopby-filter-parent {
                        .label {
                            position: relative;
                            z-index: 1;
                        }
                    }
                }
            }
        }
    }

    .column.main {
        #amasty-shopby-product-list {
            .toolbar-products {
                padding-top: 30px;
                &:first-child {
                    .modes,
                    .pages,
                    .limiter {
                        display: none;
                    }
                }
                &:nth-of-type(3) {
                    max-width: $toolbar-products-bottom__max-width;
                    .toolbar-amount,
                    .toolbar-sorter {
                        display: none;
                    }
                    .modes {
                        padding-bottom: 15px;
                    }
                    .pages {
                        float: left;
                        .items {
                            display: flex;

                            .label {
                                display: none;
                            }

                            li {
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                    .limiter {
                        float: right;
                        width: $toolbar-products-bottom__width;
                        position: relative;
                        bottom: 35px;

                        .label {
                            padding-right: 10px;
                        }
                        .limiter-text {
                            padding-left: 10px;
                            padding-top: 8px;
                        }
                    }
                }
            }
        }
    }
}

.rotate-arrow:after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}


//
// Mobile
// _____________________________________________

@include breakpoint(medium down) {
    .catalog-category-view {
        .column.main {
            #amasty-shopby-product-list {
                .toolbar-products {
                    &:nth-of-type(3) {
                        .limiter {
                            padding-top: 20px;
                            display: block;
                            width: $toolbar-products-bottom-mobile__width;
                        }
                    }
                }
            }
        }
    }
}
