@if $ts-product-size-guide-control-appearance {

	.product-info-wrapper {
		.open-size-guide-modal {

			@include breakpoint(xlarge) {
				margin-right: 1.5rem;
			}
			@include breakpoint(xxlarge) {
				margin-right: 2.5rem;
			}
		}
	}// .product-info-wrapper

	.modal-popup {
		&.size-guide {
			.modal-header {
				padding-top: 2rem;
			}
			.modal-content {
				@include breakpoint(large) {
					padding-bottom: 1rem;
				}
				@include breakpoint(xlarge) {
					padding-bottom: 3rem;
				}
			}
		}
	}// .modal-popup.size-guide

	.product-size-guide-modal {
		text-align: center;
		.ttl {
			text-transform: none;
			font-weight: $header-font-weight;
			margin-bottom: 2rem;

			@each $size, $headers in $header-styles {
				@include breakpoint($size) {
					font-size: rem-calc(map-deep-get($headers, 'h1', font-size));
				}
			}
			@include breakpoint(xxlarge) {
				margin-bottom: 1.5rem;
			}
		}
		.size-line {
			@include grid-row;
			@include grid-row-nest;

			.size-block {
				padding-top: .5rem;
				padding-bottom: .5rem;

				@include breakpoint(large) {
					padding-top: 1.25rem;
					padding-bottom: 1.25rem;
				}

				@include breakpoint(xlarge) {
					@include grid-col;
					@include grid-col-size(6);

					&:first-child {
						float: right;
					}
				}
			}
		}
	}// .product-size-guide-modal

}