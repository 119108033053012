// /**
//  * Copyright © Convert, Inc. All rights reserved.
//  *
//  */

//
//  Default appearance styles
//  _____________________________________________

.pagebuilder-column {
    box-sizing: border-box;
}

//
//  Responsive Classes
//  _____________________________________________

@include breakpoint ($pb-responsive-stacking) {
    .pagebuilder-column {
        background-attachment: scroll !important;
        flex-basis: 100%;
    }
}