// /**
//  * Copyright © Convert, Inc. All rights reserved.
//  *
//  */



//
//  Slick slider styles
//  _____________________________________________

.slick-slider {
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-touch-action: pan-y;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    box-sizing: border-box;
    display: block;
    position: relative;
    touch-action: pan-y;
    user-select: none;
}

.slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-track {
    display: block;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0;

    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;

    [dir="rtl"] & {
        float: right;
    }

    img {
        display: block;
    }

    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        border: 1px solid transparent;
        display: block;
        height: auto;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-prev {
    left: $pb-slick-button__position;
    &:before {
    	@if $pb-icons__use {
        	content: $pb-icons-arrow--prev__icon;
        }
        padding-right: $pb-slick-button-icon__offset;
    }
}

.slick-next {
    right: $pb-slick-button__position;
    &:before {
    	@if $pb-icons__use {
        content: $pb-icons-arrow--next__icon;
    	}
        padding-left: $pb-slick-button-icon__offset;
    }
}

.slick-prev,
.slick-next {
    background: $pb-slick-button__background-color;
    border: none;
    border-radius: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: $pb-slick-button__size;
    line-height: 0;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: $pb-slick-button__size;
    z-index: 101;

    &.slick-disabled {
        opacity: $pb-slick-button--disabled__opacity;
    }

    &:hover {
        background: $pb-slick-button__background-color--hover;
        border: none;

        &.slick-disabled {
            opacity: $pb-slick-button--disabled__opacity;
        }

        &:before {
            color: $pb-slick-button__color--hover;
        }
    }

    &:before,
    &:after {
    	@if $pb-icons__use {
        	@extend .pagebuilder-icon;
        	font-size: $pb-slick-button-icon__font-size;
    	}
        color: $pb-slick-button__color;
        opacity: 1;
    }

    &:active,
    &:focus,
    &:not(.primary) {
        background: $pb-slick-button__background-color--active;
        border: none;
        box-shadow: none;

        &:active {
            box-shadow: none;
        }
    }
}

.slick-dots {
    bottom: $pb-slick-dots__offset;
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    width: 100%;

    li {
        cursor: pointer;
        display: inline-block;
        height: $pb-slick-dot__size;
        width: $pb-slick-dot__size;
        margin: $pb-slick-dot__margin;
        padding: 0;
        position: relative;
        vertical-align: middle;

        &:after {
        	content: "";
        	display: none;
        }

        button {
            background: $pb-slick-dot__background-color;
            @if $pb-slick-dot__border {
            	border: $pb-slick-dot__border;
        	}
        	@if $pb-slick-dot__border-radius {
            	border-radius: $pb-slick-dot__border-radius;
        	}
            box-shadow: none;
            cursor: pointer;
            display: block;
            height: $pb-slick-dot__size;
            width: $pb-slick-dot__size;
            padding: 0;
            text-indent: -99999px;
            transition: .3s;

            &:hover {
            	@if $pb-slick-dot__border {
                	border: $pb-slick-dot__border--hover;
                }
                @if $pb-slick-dot__background-color--hover {
                	background: $pb-slick-dot__background-color--hover;
                }
            }

            &:active,
            &:focus,
            &:not(.primary) {
                box-shadow: none;
            }
        }

        &.slick-active {
            button {
                background: $pb-slick-dot--active__background-color;
            }
        }
    }
}